import React, { useRef } from "react";
import { Header, JoinIddera, Footer } from "../Common";
import Reviews from "../Common/components/Reviews";
import Hero from "./components/Hero";
import Programs from "./components/Programs";
import "./styles/main.css";
import "./styles/responsive.css";

const ProgramsPage = () => {
  const programRef = useRef();

  const scrollToProgram = () => {
    programRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest"
    });
  };

  return (
    <div className="webp prgp">
      <div className="top-fog top-fog--prgp" />
      <Header scrollToProgram={scrollToProgram} />
      <Hero scrollToProgram={scrollToProgram} />
      <Programs programRef={programRef} scrollToProgram={scrollToProgram} />
      <Reviews />
      <JoinIddera />
      <Footer />
    </div>
  );
};

export default ProgramsPage;
