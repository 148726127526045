import React from "react";

const PhilosophyCard = ({ philosophy }) => {
  return (
    <li className="ap__psy-card">
      <div className="ap__psy-card-img-wrapper">
        <img
          src={philosophy.image}
          alt={philosophy.title}
          className="ap__psy-card-img"
        />
      </div>
      <h4 className="ap__heading ap__psy-card-title"> {philosophy.title}</h4>
      <p className="ap__psy-card-desc"> {philosophy.description}</p>
    </li>
  );
};

export default PhilosophyCard;
