import React from "react";
import Tag from "components/Tag";
import CareImg from "assets/images/ccc-care.png";
import ConvenienceImg from "assets/images/ccc-convenience.png";
import GroupHeading from "components/GroupHeading";
import { useHistory } from "react-router";
import Fade from "react-reveal/Fade";
import Button from "components/Button";

const ThreeCs = () => {
  const history = useHistory();
  return (
    <div className="ccc max-page-width">
      <GroupHeading headings={["Care", "Convenience", "Cost"]} />
      <Fade bottom>
        <div className="ccc__section">
          <div className="ccc__section-text">
            <Tag> Care </Tag>
            <h3 className="ccc__section-heading">Your personal physician</h3>
            <p className="ccc__section-desc">
              Understand your health and co-create a personalised health plan to
              achieve specific outcomes.
            </p>
          </div>
          <div className="ccc__section-img-wrapper">
            <img src={CareImg} alt="" className="ccc__section-img" />
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className="ccc__section ccc__section--cv">
          <div className="ccc__section-img-wrapper">
            <img src={ConvenienceImg} alt="" className="ccc__section-img" />
          </div>
          <div className="ccc__section-text">
            <Tag> CONVENIENCE </Tag>
            <h3 className="ccc__section-heading">Anytime, anywhere </h3>
            <p className="ccc__section-desc">
              The quality time with your doctor 24/7 is just a start. You also
              get answers and peace of mind; even at 3am.
            </p>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className="ccc__cost">
          <div className="ccc__cost-back">
            <div>
              <Tag> COST </Tag>
              <h3 className="ccc__section-heading ccc__cost-heading">
                One flat <br /> fee
              </h3>
            </div>
            <p className="ccc__cost-desc">
              Iddera membership fee covers everything we do. Your healthcare
              costs are predictable and preventable. No surprises!
            </p>
            <Button
              label="Learn more"
              className="ccc__cost-btn"
              onClick={() => history.push("/membership")}
              variant="cosmos"
            />
          </div>
          <div className="ccc__cost-front">
            <span> All-inclusive care for </span>
            <span className="ccc__cost-price">
              <span className="ccc__cost-currency"> ₦ </span>
              <span className="ccc__cost-amount"> 16,500 </span>
              <span className="ccc__cost-duration"> per month </span>
            </span>
            <span className="ccc__cost-tag"> BILLED ANNUALLY </span>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default ThreeCs;
