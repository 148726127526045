import Button from "components/Button";
import Icon from "components/Icon";
import WrappedIcon from "components/WrappedIcon";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import logoSecondary from "assets/logos/full-logo-secondary.svg";
import programs from "data/programs.json";

const navLinks = [
  {
    name: "About",
    route: "/about"
  },
  {
    name: "Membership",
    route: "/membership"
  },
  {
    name: "Programs",
    isDropdown: true,
    dropdownTitle: "DOCTOR LED PROGRAMS",
    dropdownLinks: programs
  },
  {
    name: "Learn",
    route: "/learn"
  },
  {
    name: "FAQs",
    route: "/faqs"
  }
];

const MobileNav = ({ isVisible, closeNav }) => {
  const [activeDropdown, setActiveDropdown] = useState(undefined);

  const handleDropdownClick = (index) => {
    if (index === activeDropdown) setActiveDropdown(undefined);
    else setActiveDropdown(index);
  };

  return (
    <header className={`mobile-nav ${isVisible ? "mobile-nav--show" : ""}`}>
      <div className="mobile-nav__top">
        <Link to="/">
          <img
            src={logoSecondary}
            alt="Iddera"
            className="header__logo mobile-nav__logo"
          />
        </Link>
        <WrappedIcon
          id="close"
          className="mobile-nav__close"
          role="button"
          aria-label="Close menu"
          onClick={closeNav}
        />
      </div>
      <nav className="mobile-nav__links">
        {navLinks.map((link, index) =>
          link.isDropdown ? (
            <span
              key={index}
              className={`mobile-nav__link mobile-nav__link--dropdown  ${
                activeDropdown === index
                  ? "mobile-nav__link--dropdown-active"
                  : ""
              }`}
              role="button"
              onClick={() => handleDropdownClick(index)}
            >
              <span>
                {link.name}
                <Icon
                  id={activeDropdown === index ? "arrow-down" : "arrow-right"}
                  width={12}
                  height={12}
                  fill="#ffffff"
                />
              </span>
              <div className="mobile-nav__dlink-body">
                <h2 className="mobile-nav__dlink-title">
                  {link.dropdownTitle}
                </h2>
                <div className="mobile-nav__dlinks">
                  {link.dropdownLinks.map((dlink, index) => (
                    <Link
                      to={dlink.route}
                      key={index}
                      className="mobile-nav__dlink"
                    >
                      {dlink.name}
                    </Link>
                  ))}
                </div>
              </div>
            </span>
          ) : (
            <Link key={index} className="mobile-nav__link" to={link.route}>
              {link.name}
            </Link>
          )
        )}
      </nav>
      <div className="mobile-nav__actions">
        {/* <button className="btn mobile-nav__action-btn">Sign In</button>
        <Button
          variant="cornflower-blue"
          className="mobile-nav__action-btn"
          label="Join Iddera"
        /> */}
        <Button
          variant="cornflower-blue"
          className="mobile-nav__action-btn"
          label="Download Iddera"
        />
      </div>
    </header>
  );
};

export default MobileNav;
