import React from "react";
import { Header, JoinIddera, Footer } from "../Common";
import Faqs from "./components/Faqs";
import Quote from "./components/Quote";
import "./styles/main.css";
import "./styles/responsive.css";

const FaqsPage = () => {
  return (
    <div className="webp faqp">
      <div className="top-fog top-fog--faqp" />
      <Header />
      <Faqs />
      <Quote />
      <JoinIddera />
      <Footer />
    </div>
  );
};

export default FaqsPage;
