import React from "react";
import Particles from "react-particles-js";
import bubble1 from "assets/images/bubble-1.png";
import bubble2 from "assets/images/bubble-2.png";
import bubble3 from "assets/images/bubble-3.png";
import bubble4 from "assets/images/bubble-4.png";
import bubble5 from "assets/images/bubble-5.png";
import bubble6 from "assets/images/bubble-6.png";
import bubble7 from "assets/images/bubble-7.png";
import bubble8 from "assets/images/bubble-8.png";
import bubble9 from "assets/images/bubble-9.png";
import bubble10 from "assets/images/bubble-10.png";

const bubbles = [
  bubble1,
  bubble2,
  bubble3,
  bubble4,
  bubble5,
  bubble6,
  bubble7,
  bubble8,
  bubble9,
  bubble10
];

const bubbleImages = bubbles.map((bubble) => ({
  src: bubble,
  width: 400,
  height: 400
}));

const BubblesBg = () => {
  return (
    <Particles
      className="bubbles-bg"
      params={{
        particles: {
          number: {
            value: 6,
            density: {
              enable: true,
              value_area: 1000
            }
          },
          shape: {
            type: "image",
            image: bubbleImages
          },
          opacity: {
            value: 1,
            random: false,
            anim: {
              enable: false
            }
          },
          size: {
            value: 20,
            random: false,
            anim: {
              enable: false,
              speed: 3,
              size_min: 16
            }
          },
          line_linked: {
            enable: false
          },
          move: {
            speed: 1,
            direction: "top",
            out_mode: "out",
            random: false,
            straight: true
          }
        }
      }}
    />
  );
};

export default BubblesBg;
