import React from "react";
import "./styles.css";

const GroupHeading = ({ headings }) => {
  return (
    <h2 className="g-heading">
      {headings.map((heading) => (
        <span className="g-heading__text" key={heading}>
          {heading}
        </span>
      ))}
    </h2>
  );
};

export default GroupHeading;
