import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import WrappedIcon from "components/WrappedIcon";
import Program from "./Program";
import primaryBg from "assets/images/bg-benefits.png";
import heartBg from "assets/images/programs-heart.png";
import stressBg from "assets/images/programs-stress.png";
import cancerBg from "assets/images/programs-cancer.png";
import weightBg from "assets/images/programs-weight.png";
import reproductiveBg from "assets/images/programs-reproductive.png";
import { useHistory, useLocation } from "react-router-dom";

const programs = [
  {
    slug: "diabetes-mgt",
    name: "Diabetes Management",
    summary: "For people living with Type II diabetes",
    desc: "Our 3-month program to help you manage your diabetes symptoms, tiredness, pain, and emotional issues, by helping you learn skills to better manage your diabetes day to day.",
    bgImage: primaryBg,
    overlayBgColor: "#004D96",
    overlayOpacity: 0.8,
    tagBgColor: "#0B85F9"
  },
  {
    slug: "stress-anxiety-mgt",
    name: "Stress & Anxiety Management",
    summary:
      "Doctor-led mental health support for anxiety, stress and sleep management",
    desc: "Manage your mental health and improve your sleep quality and vitality through ongoing stress reduction and mindfulness therapy usually over an 8-week period.",
    bgImage: stressBg,
    overlayBgColor: "#8D1C1B",
    overlayOpacity: 0.7,
    tagBgColor: "#FF5E5D"
  },
  {
    slug: "weight-mgt",
    name: "Weight Management Program",
    summary:
      "Doctor-led weight management to look and feel your best, every day",
    desc: "Our doctors work with you to achieve your weight goals through healthy habits, monitoring and tests to understand your fat metabolism and obesity risks and to identify any imbalances.",
    bgImage: weightBg,
    overlayBgColor: "#1C22B3",
    overlayOpacity: 0.8,
    tagBgColor: "#7E5FF2"
  },
  {
    slug: "reproductive-health",
    name: "Reproductive & Sexual Health Program",
    summary:
      "Doctor-led care for the overall wellbeing of sexually active individuals and their reproductive health",
    desc: "Our doctors promote and manage your sexual and reproductive health through education, testing, treatments and therapy.",
    bgImage: reproductiveBg,
    overlayBgColor: "#7F048A",
    overlayOpacity: 0.7,
    tagBgColor: "#B209CD"
  },
  {
    slug: "heart-and-bp",
    name: "Heart Health & Blood Pressure",
    summary: "Doctor-led care for blood pressure and heart health",
    desc: "Our doctors comprehensively evaluate your risk of a heart attack in the long-term and use weekly monitoring of your weight and blood pressure to lower your risks of heart attack or stroke by managing your cholesterol, blood pressure and physical activity.",
    bgImage: heartBg,
    overlayBgColor: "#931358",
    overlayOpacity: 0.8,
    tagBgColor: "#FF33C6"
  },
  {
    slug: "cancer-prevention",
    name: "Cancer Prevention Program",
    summary: "We adopt an early hereditary risk detection and care approach",
    desc: "Our doctors screen for some of the most prevalent (lung, breast and colon) cancers and intervene quickly with lifestyle plans and ongoing care.",
    bgImage: cancerBg,
    overlayBgColor: "#044036",
    overlayOpacity: 0.8,
    tagBgColor: "#15927C"
  }
];

const Programs = ({ programRef, scrollToProgram }) => {
  const location = useLocation();
  const history = useHistory();

  const getProgramFromSearchParams = () => {
    const programSlug = new URLSearchParams(location.search).get("program");
    const programIndex = programs.findIndex(
      (program) => program.slug === programSlug
    );
    return programIndex;
  };

  const initialProgramIndex = getProgramFromSearchParams();
  const [selectedProgram, setSelectedProgram] = useState(
    initialProgramIndex < 0 ? 0 : initialProgramIndex
  );

  useEffect(() => {
    const programIndex = getProgramFromSearchParams();
    setSelectedProgram(programIndex < 0 ? 0 : programIndex);
    location.search && scrollToProgram();
  }, [location.search]);

  const getNewIndex = (mode) => {
    let newIndex = mode === "next" ? selectedProgram + 1 : selectedProgram - 1;
    if (newIndex < 0) newIndex = programs.length - 1;
    else if (newIndex >= programs.length) newIndex = 0;
    return newIndex;
  };

  const handleArrowClick = (mode) => {
    const newIndex = getNewIndex(mode);
    setSelectedProgram(newIndex);
  };
  return (
    <div className="prgp__programs">
      <p className="prgp__programs-names">
        <Carousel
          selectedItem={selectedProgram}
          autoPlay={false}
          showIndicators={false}
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          emulateTouch={true}
          onChange={(index) => setSelectedProgram(index)}
        >
          {programs.map((program, index) => (
            <span
              className="prgp__programs-name"
              key={index}
              role="link"
              onClick={() => setSelectedProgram(index)}
            >
              {program.name}
            </span>
          ))}
        </Carousel>
      </p>
      <p className="prgp__programs-btns">
        <WrappedIcon
          id="arrow-left"
          role="button"
          onClick={() => handleArrowClick("prev")}
          aria-label={`Previous program - ${programs[getNewIndex("prev")]}`}
          className="prgp__programs-btn"
        />

        <WrappedIcon
          id="arrow-right"
          role="button"
          onClick={() => handleArrowClick("next")}
          aria-label={`Next program - ${programs[getNewIndex("next")]}`}
          className="prgp__programs-btn"
        />
      </p>
      <Program program={programs[selectedProgram]} programRef={programRef} />
    </div>
  );
};

export default Programs;
