import React from "react";
import "./styles.css";

const AvatarProgress = ({
  strokeWidth = 3,
  circleRadiusFull = 48,
  progressDuration,
  active,
  avatar,
  alt,
  ...props
}) => {
  let circleRadiusReal = circleRadiusFull - 2 * strokeWidth;
  let circumference = 2 * Math.PI * circleRadiusReal;

  const setProgress = (percent) => {
    const progress = circumference - (percent / 100) * circumference;
    return progress;
  };

  return (
    <div
      className={`avatar-p cursor-pointer ${active ? "avatar-p--active" : ""}`}
      {...props}
    >
      <svg
        className="avatar-p__circle-wrapper"
        width={circleRadiusFull * 2}
        height={circleRadiusFull * 2}
      >
        <circle
          stroke={active ? "rgba(178, 9, 205, 0.2)" : "transparent"}
          strokeWidth={strokeWidth}
          fill="transparent"
          r={circleRadiusReal}
          cx={circleRadiusFull}
          cy={circleRadiusFull}
        />

        <circle
          className={`avatar-p__circle`}
          stroke="#B209CD"
          strokeWidth={strokeWidth}
          style={{
            strokeDasharray: `${circumference} ${circumference}`,
            strokeDashoffset: `${setProgress(active ? 100 : 0)}`,
            transition: active
              ? `stroke-dashoffset ${progressDuration}s linear`
              : ""
          }}
          fill="transparent"
          r={circleRadiusReal}
          cx={circleRadiusFull}
          cy={circleRadiusFull}
        />
      </svg>
      <img src={avatar} className="avatar-p__img" alt={alt} />
    </div>
  );
};

export default AvatarProgress;
