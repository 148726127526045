import DownloadBtn from "components/DownloadButton";
import React from "react";
import footerPhone from "assets/images/footer-phone.png";
import Icon from "components/Icon";
import logo from "assets/logos/full-logo-primary.svg";
import logoFaint from "assets/logos/logo-faint.svg";
import { Link, useHistory } from "react-router-dom";
import WrappedIcon from "components/WrappedIcon";
import Fade from "react-reveal/Fade";

const companyLinks = [
  {
    name: "About",
    route: "/about"
  },
  {
    name: "Programs",
    route: "/programs"
  },
  {
    name: "FAQs",
    route: "/faqs"
  },
  {
    name: "Learn",
    route: "/learn"
  }
];

const privacyLinks = [
  {
    name: "Terms of Service",
    route: "/terms"
  },
  {
    name: "Privacy Policy",
    route: "/privacy"
  },
  {
    name: "Cookie Policy",
    route: "/cookie-policy"
  }
];

const Footer = () => {
  const history = useHistory();

  return (
    <footer className="footer">
      <Fade bottom>
        <div className="footer__cards max-page-width">
          <div className="footer__card">
            <h2 className="footer__card-heading">
              Take control of your health.
            </h2>
            <div className="footer__dwd-btns">
              <DownloadBtn type="google" className="footer__dwd-btn" />
              <DownloadBtn type="apple" className="footer__dwd-btn" />
            </div>
            <div className="footer__card-img-cont">
              <img
                src={footerPhone}
                alt="Iddera mobile app"
                className="footer__card-img"
              />
            </div>
          </div>
          <div className="footer__card">
            <h2 className="footer__card-heading">
              Questions about what is included?
            </h2>
            <p className="footer__card-desc">
              We are not surprised you want to know more about healthcare for
              humans.
            </p>
            <div className="footer__card-btn-cont">
              <button
                className="footer__card-btn"
                onClick={() => history.push("/faqs")}
              >
                Check our FAQs{" "}
                <Icon id="chevron-right" className="footer__card-btn-icon" />
              </button>
            </div>
          </div>
        </div>
      </Fade>
      <div className="footer__infos max-page-width">
        <div className="footer__left">
          <Link className="footer__link" to="/">
            <img src={logo} alt="iddera" className="footer__logo" />
          </Link>
        </div>
        <div className="footer__right">
          <nav className="footer__links">
            <h3 className="footer__links-heading"> COMPANY </h3>
            {companyLinks.map((link, index) => (
              <Link to={link.route} key={index} className="footer__link">
                {link.name}
              </Link>
            ))}
          </nav>
          <nav className="footer__links">
            <h3 className="footer__links-heading"> PRIVACY </h3>
            {privacyLinks.map((link, index) => (
              <Link to={link.route} key={index} className="footer__link">
                {link.name}
              </Link>
            ))}
          </nav>
          <nav className="footer__links">
            <h3 className="footer__links-heading"> CONTACT </h3>
            <div className="footer__contacts">
              <a
                href="mailto:support@iddera.com"
                className="footer__link footer__link--contact"
              >
                <Icon id="mail-open" /> support@iddera.com
              </a>

              <a
                href="tel:+2348033663282"
                className="footer__link footer__link--contact"
              >
                <Icon id="call" />
                <span>
                  +234{"  "}
                  <span className="footer__link--contact-bold">
                    {" "}
                    803 366 3282{" "}
                  </span>
                </span>
              </a>
            </div>
            <div className="footer__sms">
              <a
                href="https://facebook.com"
                aria-label="Facebook"
                className="footer__sm"
                target="_blank"
              >
                <WrappedIcon id="facebook" />
              </a>
              <a
                href="https://twitter.com"
                aria-label="Twitter"
                className="footer__sm"
                target="_blank"
              >
                <WrappedIcon id="twitter" />
              </a>
              <a
                href="https://instagram.com"
                aria-label="Instagram"
                className="footer__sm"
                target="_blank"
              >
                <WrappedIcon id="instagram" />
              </a>
            </div>
          </nav>
        </div>
      </div>{" "}
      <div className="footer__sms footer__sms--mobile max-page-width">
        <a
          href="https://facebook.com/idderalife"
          aria-label="Facebook"
          className="footer__sm"
          target="_blank"
        >
          <WrappedIcon id="facebook" />
        </a>
        <a
          href="https://twitter.com/idderalife"
          aria-label="Twitter"
          className="footer__sm"
          target="_blank"
        >
          <WrappedIcon id="twitter" />
        </a>
        <a
          href="https://instagram.com/idderalife"
          aria-label="Instagram"
          className="footer__sm"
          target="_blank"
        >
          <WrappedIcon id="instagram" />
        </a>
      </div>
      <div className="footer__credits">
        <div className="footer__credits-content max-page-width">
          <p className="footer__credit">
            Our practitioners are members of the appropriate regulatory body
            (for example, doctors are members of the Medical and Dental Council
            of Nigeria). They are committed to clinical best practice and any
            related standards.
          </p>
          <p className="footer__credit">
            Our digital healthcare services are for information only. They are
            not designed to diagnose you or provide you with medical advice or
            treatment. They should never be used as a replacement for a
            qualified medical professional. They are there to give you
            information relating to your health and to let you know about
            general causes, risks, and possible next steps. They are not
            designed to be used instead of a healthcare professional or in an
            emergency.
          </p>
          <p className="footer__credit">
            The content on this website does not constitute a complete
            description of Iddera’s primary healthcare services. By using this
            website, you accept our Terms of Use and Privacy Policy. Google Play
            and the Google Play logo are trademarks of Google LLC. Apple, the
            Apple logo, and iPhone are trademarks of Apple Inc. registered in
            the U.S.
          </p>
          <p className="footer__credit">
            <span className="footer__credit--bold">
              {" "}
              © {new Date().getFullYear()} Iddera Life Solutions Ltd.
            </span>{" "}
            All rights reserved.
          </p>
          <img src={logoFaint} alt="Iddera" className="footer__credit-logo" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
