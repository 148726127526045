import StepCard from "components/StepCard";
import React from "react";
import gsImg1 from "assets/images/get-started-step-1.png";
import gsImg2 from "assets/images/get-started-step-2.png";
import gsImg3 from "assets/images/get-started-step-3.png";
import gsImg4 from "assets/images/get-started-step-4.png";
import Fade from "react-reveal/Fade";

const steps = [
  {
    no: 1,
    title: "Create an account",
    desc: "Sign up on our website or  download our app.",
    img: gsImg1
  },
  {
    no: 2,
    title: "Complete your \n health assessment",
    img: gsImg2
  },
  {
    no: 3,
    title: "Personalise your \n care",
    img: gsImg3
  },
  {
    no: 4,
    title: "Consult your personal physician 24/7",
    img: gsImg4,
    last: true
  }
];

const GetStarted = () => {
  return (
    <div className="get-started max-page-width">
      <h2 className="get-started__heading">
        Get started on Iddera with 4 easy steps
      </h2>
      <Fade bottom>
        <div className="get-started__steps">
          {steps.map((step, index) => (
            <StepCard key={index} step={step} />
          ))}
        </div>
      </Fade>
    </div>
  );
};

export default GetStarted;
