import React from "react";
import galleryImg1 from "assets/images/gallery-1.png";
import galleryImg2 from "assets/images/gallery-2.png";
import galleryImg3 from "assets/images/gallery-3.png";
import galleryImg4 from "assets/images/gallery-4.png";
import galleryImg5 from "assets/images/gallery-5.png";
import galleryImg6 from "assets/images/gallery-6.png";
import galleryImg8 from "assets/images/gallery-8.png";
import galleryImg9 from "assets/images/gallery-9.png";
import galleryImg10 from "assets/images/gallery-10.png";
import galleryImg11 from "assets/images/gallery-11.png";

const images = [
  galleryImg3,
  galleryImg1,
  galleryImg5,
  galleryImg9,
  galleryImg2,
  galleryImg10,
  galleryImg4,
  galleryImg6,
  galleryImg8,
  galleryImg11
];

const Gallery = () => {
  return (
    <div className="gallery">
      {[...images, ...images, ...images].map((image, index) => (
        <img src={image} className="gallery__img" alt="" key={index} />
      ))}
    </div>
  );
};

export default Gallery;
