import React, { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import strikethrough from "assets/images/strikethrough.svg";
import DownloadButton from "components/DownloadButton";

const WhoWeAre = () => {
  const animRef = useRef();
  const { ref, entry } = useInView({
    root: null,
    rootMargin: "0px",
    threshold: 0.5
  });

  useEffect(() => {
    if (entry?.isIntersecting) {
      animRef.current.classList.add("wwa__anim-wrapper--active");
    }
  }, [entry]);

  return (
    <div className="wwa max-page-width" ref={ref}>
      <div>
        <p className="wwa__anim-wrapper" ref={animRef}>
          Healthcare for{" "}
          <span className="wwa__strike-word">
            industry <img src={strikethrough} alt="" />
          </span>
          <span className="wwa__strike-enter"> humans </span>
        </p>
        <div className="wwa__download-btns">
          <DownloadButton type="google" link="/" />
          <DownloadButton type="apple" link="/" />
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
