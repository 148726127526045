import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import LandingPage from "pages/website/LandingPage";
import AboutPage from "pages/website/AboutPage";
import FaqsPage from "pages/website/FaqsPage";
import PricingPage from "pages/website/PricingPage";
import {
  CookiePolicy,
  PrivacyPolicy,
  TermsConditions
} from "pages/website/Legal";
import { LearnPage, LearnDetailsPage } from "pages/website/Learn";
import ScrollToTop from "./ScrollToTop";
import ProgramsPage from "pages/website/Programs";
import MLP from "pages/mlp";

export default function Routes() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <PublicRoute component={MLP} path="/" />

        {/* Website Routes */}
        <PublicRoute component={ProgramsPage} path="/programs" />
        <PublicRoute component={LearnDetailsPage} path="/learn/:articleId" />
        <PublicRoute component={LearnPage} path="/learn" />
        <PublicRoute component={CookiePolicy} path="/cookie-policy" />
        <PublicRoute component={PrivacyPolicy} path="/privacy" />
        <PublicRoute component={TermsConditions} path="/terms" />
        <PublicRoute component={PricingPage} path="/membership" />
        <PublicRoute component={FaqsPage} path="/faqs" />
        <PublicRoute component={AboutPage} path="/about" />
        <PublicRoute component={LandingPage} path="/" />
      </Switch>
    </Router>
  );
}
