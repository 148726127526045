import React, { useState } from "react";
import Tag from "components/Tag";
import avatar from "assets/images/avatar.png";
import avatar1 from "assets/images/dr-avatar-1.png";
import avatar2 from "assets/images/dr-avatar-2.png";
import Profile from "components/Profile";
import bubble from "assets/images/bubble-8.png";
import Fade from "react-reveal/Fade";
import DoctorProfile from "./DoctorProfile";

const doctors = [
  {
    avatar: avatar,
    name: "Dr. Ogonna Ojiaka",
    qualifications: ["MD"],
    location: "Lagos, Nigeria",
    school: "University of Ibadan",
    languages: ["English", "Igbo"],
    about:
      "Ogonna is passionate about helping members establish their healthcare needs and goals. She enjoys creating relationships and supporting members on their journey to health and wellness.",
    hobbies: [
      "Traveling ✈️",
      "Music 🎵",
      "Watching massage videos on YouTube ▶️"
    ]
  },
  {
    avatar: avatar1,
    name: "Dr. Olabode Ifabiyi",
    qualifications: ["MB", "BS", "MRCGP"],
    location: "Lagos, Nigeria",
    school: "University of Ilorin",
    languages: ["English"],
    about:
      "A General Practitioner who appreciates a responsive primary health care system. Embraces new, dynamic ways of delivering health care and empowering patients. Has a keen interest in patient satisfaction and experience.",
    hobbies: ["Movies 🎥", "Bowling 🎳", "Traveling ✈️"]
  },
  {
    avatar: avatar2,
    name: "Dr Kate Horton",
    qualifications: ["MBBS"],
    location: "Corona Clinic, Nevada, USA",
    school: "Stanford University School of Medicine",
    languages: ["English"],
    about:
      "Ali has a special interest in helping members establish personal goals to live better and identifying strategies for them to achieve their health goals",
    hobbies: ["Football 🏈", "Music 🎵", "chess ♟"]
  }
];

const Doctors = () => {
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [showDoctorProfile, setShowDoctorProfile] = useState(false);

  const handleProfileClick = (index) => {
    setSelectedDoctor(doctors[index]);
    setShowDoctorProfile(true);
  };

  return (
    <div className="ap__drs max-page-width">
      <Fade bottom>
        <div className="ap__drs-top">
          <img src={bubble} className="ap__drs-bubble" alt="" />
          <Tag> Doctors </Tag>
          <h3 className="ap__heading ap__drs-heading">
            You are in the best hands{" "}
          </h3>
          <p className="ap__drs-desc">
            Deep medical expertise and degrees from top-rated universities are
            standard. We have prioritised your "humanness" and helping you make
            the right choices to live your best life.
          </p>
        </div>
        <ul className="ap__drs-list max-page-width">
          {doctors.map((doctor, index) => (
            <Profile
              key={index}
              avatar={doctor.avatar}
              altText={doctor.name}
              primaryText={
                <span>
                  {doctor.name}{" "}
                  <span className="txt-highlight">
                    {" "}
                    , {doctor.qualifications?.join(" ")}
                  </span>
                </span>
              }
              secondaryText={doctor.location}
            >
              <button
                className="ap__drs-btn"
                onClick={() => handleProfileClick(index)}
                aria-label={`View profile of ${doctor.name}`}
              >
                Profile +
              </button>
            </Profile>
          ))}
        </ul>
      </Fade>
      <DoctorProfile
        isOpen={showDoctorProfile}
        closeModal={() => setShowDoctorProfile(false)}
        doctor={selectedDoctor}
      />
    </div>
  );
};

export default Doctors;
