import React, { useState } from "react";
import LearnSection from "./LearnSection";
import washHandsImg from "assets/images/learn-hand-wash.png";
import fog from "assets/images/fog-learn.png";
import LearnRadioList from "components/LearnRadioList";
import Tag from "components/Tag";
import PostSummary from "./PostSummary";
import Fade from "react-reveal/Fade";
import { postGroups, postList, tagMapping } from "../content";
import { Link } from "react-router-dom";

const sections = [
  {
    name: "General care",
    tag: "general",
    posts: postGroups.general || []
  },
  {
    name: "Tips",
    tag: "tips",
    posts: postGroups.tips || []
  },
  {
    name: "Diabetes",
    tag: "diabetes",
    posts: postGroups.diabetes || []
  },
  {
    name: "Hypertension and Heart Disease",
    tag: "heart",
    posts: postGroups.heart || []
  },
  {
    name: "Stress and Anxiety Management",
    tag: "stress",
    posts: postGroups.stress || []
  },
  {
    name: "Weight management",
    tag: "weight",
    posts: postGroups.weight || []
  },
  {
    name: "Product updates",
    tag: "product",
    posts: postGroups.product || []
  }
];

const highlightedPost = postList[0];

const postOptions = [
  { label: "All", value: "all" },
  ...sections.map((section) => ({ label: section.name, value: section.name }))
];

const LearnContent = () => {
  const [postsType, setPostsType] = useState("all");

  return (
    <div className="lrnp__content">
      <div className="lrnp__hero max-page-width">
        <div className="lrnp__hero-content">
          <img src={fog} className="lrnp__hero-fog" />
          <Fade bottom>
            <h1 className="lrnp__hero-heading">
              Tips, guides and <br />
              resources <br />
              for living healthy
            </h1>
            <p className="lrnp__hero-subheading">
              Still got doubts, let’s clear them!
            </p>
            <LearnRadioList
              options={postOptions}
              name="learn-sections"
              onChange={(val) => setPostsType(val)}
              value={postsType}
            />
          </Fade>
        </div>
      </div>
      <Fade bottom>
        <Link
          to={`/learn/${highlightedPost.id}`}
          className="lrnp__post max-page-width"
        >
          <div className="lrnp__post-img-wrapper">
            <img alt="doctor" src={washHandsImg} className="lrnp__post-img" />
          </div>
          <div className="lrnp__post-right">
            <Tag className="lrnp__post-tag">
              {tagMapping[highlightedPost.tag]}
            </Tag>
            <h2 className="lrnp__post-heading">{highlightedPost.title}</h2>
            <p className="lrnp__post-intro">{highlightedPost.intro}</p>
            <PostSummary
              image={highlightedPost.author.avatar}
              name={highlightedPost.author.name}
              date={highlightedPost?.postDate.format("MMMM DD, YYYY")}
            />
          </div>
        </Link>
      </Fade>
      {(postsType === "all"
        ? sections
        : sections.filter((section) => section.name === postsType)
      ).map((section, index) => (
        <LearnSection section={section} key={index} />
      ))}
    </div>
  );
};

export default LearnContent;
