import React from "react";
import hero from "assets/images/hero-programs.png";
import Fade from "react-reveal/Fade";
import Icon from "components/Icon";

const Hero = ({ scrollToProgram }) => {
  return (
    <div className="prgp-hero">
      <div className="prgp-hero__content max-page-width">
        <div className="prgp-hero__left">
          <Fade bottom>
            <h1 className="prgp-hero__heading">Our doctor-led programs </h1>
            <p className="prgp-hero__subheading">
              Life won't wait so you shouldn't wait to see a doctor. From
              primary care to prevention, your doctor is always in touch and
              proactive about your care.
            </p>
            <p
              className="prgp-hero__scroll cursor-pointer"
              onClick={scrollToProgram}
              role="button"
            >
              SCROLL{" "}
              <Icon id="mouse-scroll" className="prgp-hero__scroll-icon" />
            </p>
          </Fade>
        </div>
        <div className="prgp-hero__right">
          <img
            src={hero}
            className="prgp-hero__img"
            alt="Lady checking heart rate and setting weekly health plan."
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
