import React from "react";
import Tag from "components/Tag";
import img1 from "assets/images/membership-1.png";
import img2 from "assets/images/membership-2.png";
import img3 from "assets/images/membership-3.png";
import Fade from "react-reveal/Fade";

const Quote = () => {
  return (
    <div className="prp__insurance">
      <div className="max-page-width">
        <div className="prp__insurance-top">
          <Fade bottom>
            <Tag> Insurance </Tag>
            <div className="prp__insurance-texts">
              <h2 className="prp__insurance-heading"> Iddera & Insurance </h2>
              <p className="prp__insurance-desc">
                Insurance is not required to become an Iddera member. It is
                important to understand that Iddera membership is not health
                insurance and is not a substitute for comprehensive health
                insurance.
              </p>
            </div>
          </Fade>
        </div>
        <Fade bottom>
          <div className="prp__insurance-imgs">
            <div className="prp__insurance-img-wrapper">
              <img
                src={img1}
                alt=""
                className="prp__insurance-img prp__insurance-img--1"
              />
            </div>
            <div className="prp__insurance-img-wrapper">
              <img
                src={img2}
                alt=""
                className="prp__insurance-img prp__insurance-img--1"
              />
            </div>
            <div className="prp__insurance-img-wrapper">
              <img
                src={img3}
                alt=""
                className="prp__insurance-img prp__insurance-img--1"
              />
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Quote;
