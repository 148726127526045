import Icon from "components/Icon";
import React from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import downloadGoogle from "assets/images/download-google.png"
import downloadApple from "assets/images/download-apple.png"

const stores = {
  google: {
    image: downloadGoogle,
    alt: "Get Iddera on Google Play Store",
    link: "/"
  },
  apple: {
    image: downloadApple,
    alt: "Get Iddera on App Store",
    link: "/"
  }
};
const DownloadBtn = ({ type, className, ...props }) => {
  const store = stores[type];

  if (!store) return null;

  const { icon, storeName } = store;

  return (
    <Link to={store.link} className={`download-btn ${className || ""}`} {...props}>
     <img src={store.image} alt={store.alt} className="download-btn__img"/>
    </Link>
  );
};

export default DownloadBtn;
