import Icon from "components/Icon";
import WrappedIcon from "components/WrappedIcon";
import React from "react";
import Fade from "react-reveal/Fade";
import Plan from "./Plan";

const benefits = [
  {
    icon: "app",
    heading: "Comprehensive App",
    desc: "For appointment scheduling, lab results, medical record consolidation and messaging.",
    free: {
      text: "",
      checked: true
    }
  },
  {
    icon: "stethoscope",
    heading: "Unlimited Doctor Visits",
    desc: "Unlimited in-person and virtual visits.",
    free: {
      text: "In app chats with your GP"
    }
  },

  {
    icon: "chat",
    heading: "24/7 Messaging",
    desc: "Questions answered by Iddera doctors and a dedicated care team anytime anywhere.",
    free: {
      text: "Pay-as-you-go"
    }
  },

  {
    icon: "male",
    heading: "Men’s Health",
    desc: "Erectile dysfunction, urinary symptoms/prostate health, STI testing and more.",
    free: {
      text: "Pay-as-you-go"
    }
  },

  {
    icon: "female",
    heading: "Women’s Health",
    desc: "Pap smears, pelvic exams, birth control counsel, STI testing and more.",
    free: {
      text: "Pay-as-you-go"
    }
  },

  {
    icon: "mental-health",
    heading: "Mental Health & Support",
    desc: "Mental health check-ins, consults with your doctor and prescription guidance.",
    free: {
      text: "Pay-as-you-go"
    }
  },

  {
    icon: "syringe",
    heading: "Blood Testing (Home Service)",
    desc: "Cholesterol, heart health, kidney and liver function, hemoglobin, and more.",
    free: {
      text: "Pay-as-you-go"
    }
  },
  {
    icon: "prescription",
    heading: "Prescription Delivery",
    desc: "Medications delivered to your home (including free first-fill generic prescriptions).",
    free: {
      text: "Pay-as-you-go"
    }
  },
  {
    icon: "heartbeat",
    heading: "Biometrics Monitoring",
    desc: "Tools for vitals monitoring including blood pressure, temperature, and pulse oximetry from home.",
    free: {
      text: "Pay-as-you-go"
    }
  },
  {
    icon: "microscope",
    heading: "Genetic Analysis",
    desc: "Genetic testing and counseling for health risks including cancer, heart disease, diabetes.",
    free: {
      text: "Pay-as-you-go"
    }
  },
  {
    icon: "vial",
    heading: "Vaccines",
    desc: "Travel, seasonal and standard vaccines are included.",
    free: {
      text: "Pay-as-you-go"
    }
  }
];

const Pricing = () => {
  return (
    <div className="prp__content max-page-width">
      <div className="prp__top">
        <Fade bottom>
          <h1 className="prp__heading"> One flat fee, no surprises </h1>
          <p className="prp__subheading">
            Iddera membership fee includes everything we do. Your costs are
            predictable. No surprises.
          </p>
        </Fade>
      </div>

      <div className="prp__mbenefits">
        <div className="prp__plans">
          <Plan plan="premium" isRecommended={true} />
          <Plan plan="free" />
        </div>
        <Fade bottom>
          <h2 className="prp__mbenefits-heading">
            {" "}
            Compare plans and benefits{" "}
          </h2>
          <table className="prp__mbenefits-table">
            <thead className="prp__mbenefits-thead">
              <tr>
                <th className="prp__mbenefits-tcell prp__mbenefits-tcell--heading">
                  Membership Benefits
                </th>
                <th className="prp__mbenefits-tcell prp__mbenefits-tcell--heading">
                  Premium Plan
                </th>
                <th className="prp__mbenefits-tcell prp__mbenefits-tcell--heading">
                  Free Plan
                </th>
              </tr>
            </thead>

            <tbody className="prp__mbenefits-tbody">
              {benefits.map((benefit, index) => (
                <tr key={index}>
                  <td className="prp__mbenefits-tcell prp__mbenefits-tcell--mb">
                    <li className="prp__mbenefit" key={index}>
                      <WrappedIcon
                        id={benefit.icon}
                        className="prp__mbenefit-icon"
                      />
                      <div className="prp__mbenefit-texts">
                        <span className="prp__mbenefit-text prp__mbenefit-text--bold">
                          {benefit.heading}
                        </span>
                        <span className="prp__mbenefit-text">
                          {benefit.desc}
                        </span>
                      </div>
                    </li>
                  </td>
                  <td className="prp__mbenefits-tcell prp__mbenefits-tcell--pr">
                    <Icon
                      id="checked-circle"
                      aria-label="available"
                      className="prp__mbenefit-check"
                      fill="#7E5FF2"
                    />
                  </td>
                  <td className="prp__mbenefits-tcell prp__mbenefits-tcell--fr">
                    {benefit.free?.checked ? (
                      <Icon
                        id="checked-circle"
                        aria-label="available"
                        className="prp__mbenefit-check"
                        fill="#7E5FF2"
                      />
                    ) : (
                      benefit.free?.text
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Fade>
      </div>
    </div>
  );
};

export default Pricing;
