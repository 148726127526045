import React from "react";
import locationIcon from "assets/icons/location-gradient.svg";
import Fade from "react-reveal/Fade";

const WithLove = () => {
  return (
    <div className="ap__wl max-page-width">
      <Fade bottom>
        <img src={locationIcon} alt="Location icon" className="ap__wl-icon" />
        <p className="ap__wl-text">
          With love from
          <span className="ap__wl-highlight"> Lagos, Nigeria </span>
        </p>
      </Fade>
    </div>
  );
};

export default WithLove;
