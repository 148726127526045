import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

import LegalLayout from "./LegalLayout";
import scroll from "assets/images/legal-scroll.png";
import Icon from "components/Icon";

const sections = [
  {
    heading: "Background",
    content: (
      <>
        {" "}
        <p>
          {" "}
          These Terms of Use (this “Agreement”) is a legal agreement between you
          (“you”) and Iddera Life Solutions Ltd (“Iddera”), a Nigerian Limited
          Liability Company (“Company,” “we” or “us”) for use of the Iddera Ⓡ
          mobile application, including any content, functionality, or other
          Output (defined below){" "}
          <span className="legal__doc-bold">(“Digital Health Tools”)</span>, as
          well as virtual clinical services offered on or through the Iddera
          App, such as virtual consultations and other services{" "}
          <span className="legal__doc-bold">(“Clinical Services”)</span> offered
          by the Company’s clinical providers{" "}
          <span className="legal__doc-bold">(“Providers”)</span>, the website
          (iddera.com), the Courses website (
          <Link to="/learn">iddera.com/learn</Link>){" "}
          <span className="legal__doc-bold">("Courses")</span>, the servers used
          by the application, the computer files stored on such servers, and all
          related services, and all related services, features and content
          offered by the Company (collectively, the “App”). This Agreement is
          not concluded with Apple, Inc., any of its subsidiaries, Google, Inc.,
          any of its subsidiaries, or any other entity as may apply.
        </p>{" "}
      </>
    )
  },
  {
    heading: "Acceptance of terms & Consent Form [extract for mobile app]",
    content: (
      <>
        <>
          <p>
            Please read this Agreement carefully. By creating an account or
            accessing or using the App, you acknowledge that you accept and
            agree to be bound by the terms of this Agreement.{" "}
            <span className="legal__doc-bold">
              IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT ACCESS OR USE THE
              APP
            </span>
            .
          </p>
          <p>
            We may modify this Agreement from time to time. We will notify you
            by email, through the App, or by presenting you with a new version
            of the Agreement for you to accept if we make modifications that
            materially change your rights. Your continued use of the App after
            the effective date of an updated version of the Agreement will
            indicate your acceptance of the Agreement as modified.
          </p>
          <p>
            By using the service, I (referring to “Users”, “Patients”,
            “Healthcare Professionals”, “Healthcare Practitioners”), understand
            that I have certain rights to privacy and data protection regarding
            my personal data. These rights are given to me under the Nigeria
            Data Protection Regulation (NDPR), National Health Act (NHA), and
            other applicable laws. I understand that by signing this consent
            form, I give my informed and explicit consent to process my personal
            data to carry out the purpose specified in this terms of use.
          </p>{" "}
          <p>
            {" "}
            By signing this consent form, I give my informed and explicit
            consent to use my personal data to provide services to me, for
            research consistent with efforts to improve my healthcare
            experience, to improve the quality of service delivered to me, and
            the overall advancement of healthcare. In order to achieve the
            foregoing, my personal data may be shared with the following
            recipients:
          </p>
          <ul>
            <li>
              Service Providers and Data Processors that we use to improve
              healthcare experience as listed in our Privacy Policy;
            </li>
            <li>
              {" "}
              Government and other regulatory authorities, for those situations
              where we receive a lawful order to do so;
            </li>
            <li>
              {" "}
              Any other entity, which I instruct you to disclose or share my
              personal data with.
            </li>
          </ul>
        </>
      </>
    )
  },
  {
    heading: "What we mean by our services?",
    content: (
      <>
        {" "}
        <p> Our services fall into two (2) categories:</p>
        <h3> (a) Clinical services</h3>
        <p>
          Our physical and virtual (video and audio) consultations, where you
          can talk with one of our medical professionals. This includes people
          like doctors, advanced nurse practitioners, dermatologists,
          phlebotomists (people qualified to draw blood), physiotherapists,
          pharmacists, mental health therapists, etc. Throughout these terms, we
          have called them practitioners.{" "}
        </p>
        <p>
          These practitioners might give you a diagnosis, recommendation, or
          treatment. They may also prescribe to you or refer you to someone
          else.
        </p>
        <h3> (b) Digital healthcare services</h3>
        <p>
          Services that give you health and lifestyle information through our
          apps or websites. Our digital healthcare services are for information
          only. They are not designed to diagnose you or provide you with
          medical advice or treatment. They should never be used as a
          replacement for a qualified medical professional.
        </p>
        <h4> Symptom Checker</h4>
        <p>
          {" "}
          A tool where you can enter symptoms and get general information on
          possible next steps. For some symptoms, you will also be provided with
          a list of possible matching conditions which are often associated with
          the symptoms you entered.
        </p>
        <h4>Healthcheck</h4>
        <p>
          A health questionnaire where you get a healthy living and lifestyle
          report based on your answers. The report lets you know about general
          risks for certain conditions. It might include general lifestyle
          advice too.
        </p>
        <h4> Monitor</h4>
        <p>
          A mood and wellness tracker where you can input information on your
          daily routines. Your wearable device (for example, a Fitbit) can also
          provide this information. Monitor allows you to enter and review
          information on some health conditions.
        </p>
        <h4> Health Assistant</h4>
        <p>
          A way for you to ask questions when you need help and support, or live
          chat with a member of our non-clinical support team.
        </p>{" "}
        <p>
          We might also offer other health information tools from time to time.
        </p>
        <h4> Using our services</h4>
        <p> You can access our services in different ways.</p>
        <ul>
          <li>
            Our Iddera premium service, which is available if you're an annual
            subscriber.{" "}
          </li>
          <li>
            Our free trail plan that also includes your options for
            pay-as-you-go (PAYG).{" "}
          </li>
        </ul>
        <h4>What our services can and can't do</h4>
        <p>
          Our digital healthcare services are for information only. They are not
          designed to diagnose you or provide you with medical advice or
          treatment. They should never be used as a replacement for a qualified
          medical professional.
        </p>
        <p>
          They are there to give you information relating to your health and to
          let you know about general causes, risks and possible next steps.
          They're not designed to be used instead of a healthcare professional.
        </p>
        <p>
          Don't use any of these digital healthcare services in an emergency.
          Instead, call the emergency services immediately.
        </p>
        <p>
          You should always talk to a qualified healthcare professional if you
          have questions about your health or a medical condition. And before
          you make any decisions about your health. There are some medical
          conditions that we won't be able to help you with in a video or phone
          consultation. Depending on how you access them, you might not be able
          to get our full list of services. Our services might be subject to
          other rules or restrictions. We'll let you know about these on our app
          or websites.
        </p>{" "}
        <h4>Our clinical services</h4>
        <p>
          Our practitioners are members of the appropriate regulatory body (for
          example, doctors are members of the Medical and Dental Council of
          Nigeria). They are committed to clinical best practice and any related
          standards.
        </p>
        <p>
          Our practitioners might have different opinions on some medical
          conditions or symptoms. This doesn't mean that our clinical services
          are at fault, it's normal for experts to have different views from
          time to time.
        </p>
        <h4>Your Consultation</h4>
        <p>
          We will arrange your consultation as soon as possible. We cannot
          promise to offer consultations in a specific timeframe, or that a
          specific practitioner will be available at a particular time.
        </p>
        <p>
          We record all consultations to make sure we are giving you the best
          service we can. To find out more about how we use this information and
          how we protect your privacy, see our{" "}
          <Link to="/privacy">Privacy Policy</Link>.
        </p>
        <p>
          If you do not think you have been able to clearly share your medical
          needs during a consultation, you should speak to a practitioner in
          person. If you are worried about any advice you have received, then
          you should book another appointment to discuss this.
        </p>
        <h4> Your prescriptions and medicines</h4>
        <p>
          We are able to prescribe medicines without face-to-face meetings. Our
          practitioners follow national evidence-based guidance and meet care
          quality standards.
        </p>
        <p>
          We cannot prescribe controlled drugs and have strict limits on
          high-risk medicines. We may refuse to prescribe to you if we think it
          is not safe or legal to do so. We partner with pharmacies that provide
          your medicine. These aren't part of Iddera and we cannot take
          responsibility for their acts or mistakes.
        </p>
        <h4>Prescription charges</h4>
        <p>
          If you are on a free-trial subscription plan, you need to pay for your
          prescriptions. The price is set by the pharmacy and is not part of
          your Iddera subscription.
        </p>
        <h4>Laboratory testing services</h4>
        <p>
          Most cytology laboratory tests (not imaging) are part of the
          subscription plan for our premium members. We offer medical tests with
          the help of our test kit provider and partner laboratory and
          free-trial users can order these through the app. The tests come at an
          extra cost to your subscription and are available to adults over the
          age of 18.
        </p>
        <p>
          By taking a test and returning your samples, you give us permission to
          make your results available to you in the app.
        </p>
        <p>
          Our lab is responsible for checking results and making sure they are
          correct. We cannot offer refunds or returns for any test you have
          ordered.
        </p>
        <h4> What we need if you use our clinical services</h4>
        <ul>
          {" "}
          <li>Follow any instructions our practitioners give to you</li>
          <li>
            Follow any instructions we give to you about how to use medicines or
            healthcare products we recommend or prescribe. This includes use-by
            dates.
          </li>
          <li>
            Keep any medicines we prescribe to you securely and don't allow
            others (especially children) to use them.
          </li>
          <li>
            Only use our services for you, unless you're helping a child that
            you're responsible for.
          </li>
          <li>
            Tell a practitioner if you have any bad or unexpected effects from
            anything we've recommended to you. Don't report these in the app.
          </li>
          <li>Or contact emergency services if you think it's necessary.</li>
        </ul>
        <p>
          We are here to help, but if we think that you are using our clinical
          services too much, we will speak to you about it.
        </p>
        <p>
          We don't tolerate any abuse or offensive behaviour towards any Iddera
          employees orpractitioners. If we think that this is happening, then we
          can end your consultation or cancel your subscription.
        </p>
        <div className="legal__banner legal__banner--heading">
          <div className="legal__banner-header">
            <Icon id="info" />
            <h5> EMERGENCY SERVICES DISCLAIMER </h5>
          </div>
          <p>
            No emergency services. You cannot contact emergency Services through
            the app. In the event of an emergency, or if You have a condition
            that you know will require urgentPhysical examination, or if you
            think you have a condition thatRequires urgent intervention, please
            dial the national Emergency service on your telephone or seek
            immediate help From your local emergency room, urgent care or other
            first Responders.
          </p>
        </div>
        <h4>
          Our Digital Health Tools Do Not Provide Medical Advice; Other
          Important Disclaimers.
        </h4>
        <p>
          Our Digital Health Tools are intended for informational purpose only.
          The outputs from our Digital Health Tools may include content, text,
          data, graphics, images, photographs, audio, video, information,
          suggestions, guidance, and other materials provided made available or
          otherwise found through the App, including, without limitation,
          outputs provided in direct response to your inputs{" "}
          <span className="legal__doc-bold">(“Outputs”)</span>. Outputs are
          based on general health information for the general population and do
          not constitute and are not intended for use as or as a substitute for
          medical advice, diagnosis, or treatment. The outputs are responsive to
          information entered but are not personalised to you and do not
          diagnose any condition you may or may not have.
        </p>
        <p>
          You should always talk to qualified medical provider about any
          questions you may have about a medical condition, symptoms, or your
          health. Always seek the advice of a physician or other qualified
          healthcare provider concerning questions you have regarding your
          symptoms, health, or a medical condition, and or before starting,
          stopping, or modifying any treatment, medication, care plan, or making
          any other medical decisions. Any decisions you make affecting your
          health should always be made in consultation with a medical provider
          and not on the basis of our Digital Health Tools or their outputs.
        </p>
        <p>
          WE MAKE NO WARRANTIES IN RELATION TO OUR DIGITAL HEALTH TOOLS, WHICH
          ARE OFFERED ON AN AS-IS BASIS.
        </p>
        <div className="legal__banner legal__banner--no-heading">
          <Icon id="info" />
          <p>
            We disclaim liability for any errors or omissions, or for unintended
            technical inaccuracies, or typographical errors in The provided
            materials, as well as violation of any ethical or Moral standards
            applicable in your community to sexual education and related
            materials.
          </p>
        </div>
      </>
    )
  },
  {
    heading: "Registration and eligibility",
    content: (
      <>
        <p>
          To use the App, you may be required to create or update an account
          (“Account”) and will be asked to provide certain personal information,
          which may include your name, gender, birth date, and e-mail address.
          This information will be held and used in accordance with our privacy
          policy, which can be found at{" "}
          <Link to="/privacy">iddera.com/privacy</Link> (“Privacy Policy”). You
          agree that you will supply accurate and complete information to the
          Company, and that you will update that information promptly after it
          changes.
        </p>
        <p>
          To create an Account and access the App, you must be at least 18 years
          old and not barred from using the App under applicable law.
        </p>
        <p>
          If you are under 18, your parent or guardian must review and accept
          the terms of this Agreement, and by using the App, you confirm that
          your parent or guardian has so reviewed and accepted this Agreement.
          We reserve the right to limit the availability to users under the age
          of 18 of certain content in the App, in our sole discretion.
        </p>{" "}
      </>
    )
  },
  {
    heading: "Your use of the App",
    content: (
      <>
        <p>
          Any content you submit through the App is governed by the Company’s
          Privacy Policy <Link to="/privacy">iddera.com/privacy</Link>. To the
          extent there is an inconsistency between this Agreement and the
          Company’s Privacy Policy, these Terms shall govern. If you submit a
          question or response, you are solely responsible for your own
          communications, the consequences of posting those communications, and
          your reliance on any communications found in the public areas.
        </p>
        <p>
          The Company and its licensors are not responsible for the consequences
          of any communications in the public areas. In cases where you feel
          threatened or you believe someone else is in danger, you should
          contact your local law enforcement agency immediately. If you think
          you may have a medical emergency, call state emergency services
          immediately. As a condition of using the App, you agree not to use the
          App for any purpose that is prohibited by this Agreement. You are
          responsible for all of your activity in connection with the App and
          you shall abide by all local, state, national, and international laws
          and regulations and any applicable regulatory codes. You agree that if
          you take any of the following actions, you will be materially
          breaching this Agreement, and you agree that you SHALL NOT:
        </p>{" "}
        <ol type="a">
          {" "}
          <li>
            Resell, rent, lease, loan, sublicense, distribute, or otherwise
            transfer rights to the App
          </li>
          <li>Modify, reverse engineer, decompile or disassemble the App</li>
          <li>
            copy, adapt, alter, modify, translate, or create derivative works of
            the App without the written authorization of the Company
          </li>
          <li>
            Permit other individuals to use the App, including but not limited
            to shared use via a network connection, except under the terms of
            this Agreement
          </li>
          <li>
            Circumvent or disable any technological features or measures in the
            App for protection of intellectual property rights
          </li>
          <li>
            Use the App in an attempt to, or in conjunction with, any device,
            program, or service designed to circumvent technological measures
            employed to control access to, or the rights in, a content file or
            other work protected by the copyright laws of any jurisdiction
          </li>
          <li>
            Use or access the App to compile data in a manner that is used or
            usable by a competitive product or service
          </li>
          <li>
            Use your Account to advertise, solicit, or transmit any commercial
            advertisements, including chain letters, junk e-mail or repetitive
            messages to anyone
          </li>
          <li>Use your Account to engage in any illegal conduct</li>
          <li>
            Upload to transmit any communications that infringe or violate the
            rights of any party
          </li>
          <li>
            Upload media of any kind that contain expressions of hate, abuse,
            offensive images or conduct, obscenity, pornography, sexually
            explicit or any material that could give rise to any civil or
            criminal liability under applicable law or regulations or that
            otherwise may be in conflict with this Agreement and the Company’s
            Privacy Policy
          </li>
          <li>
            or Upload any material that contains software viruses or any other
            computer code, files or programs designed to interrupt, destroy or
            limit the functionality of any computer software or this website.
          </li>
        </ol>{" "}
        <p>
          Any such forbidden use shall immediately terminate your license to use
          the App.
        </p>
      </>
    )
  },
  {
    heading: "Children’s privacy and age restrictions",
    content: (
      <>
        {" "}
        <p>We are committed to protecting the privacy of children.</p>
        <p>
          You should be aware that this App is not intended or designed to
          attract children under the age of 13. We do not collect personal data
          from any person we actually know is a child under the age of 13.
        </p>
        <p>
          You must be at least 18 years old to use some features of the App
          (e.g., some courses, content, or discussion topics on our blog).
        </p>
        <p>
          If you are aware of anyone that does not comply with these
          limitations, please contact us at{" "}
          <a href="mailto:support@iddera.com">support@iddera.com</a>, and we
          will take steps to delete or terminate their account.
        </p>{" "}
      </>
    )
  },
  {
    heading: "Export and economic sanctions control",
    content: (
      <>
        {" "}
        <p>
          The software that supports the App may be subject to U.S. export and
          reexport control laws and regulations, including the Export
          Administration Regulations (“EAR”) maintained by the U.S. Department
          of Commerce, trade and economic sanctions maintained by the Treasury
          Department’s Office of Foreign Assets Control (“OFAC”), and the
          International Traffic in Arms Regulations (“ITAR”) maintained by the
          Department of State. You represent and warrant that you are (1) not
          located in any country or region that is subject to a U.S. government
          embargo, and (2) are not a denied party as specified in the
          regulations listed above.
        </p>
        <p>
          You agree to comply with all U.S. and foreign export laws and
          regulations to ensure that neither the App nor any technical data
          related thereto, nor any direct product thereof is exported or
          re-exported directly or indirectly in violation of, or used for any
          purposes prohibited by, such laws and regulations.
        </p>{" "}
      </>
    )
  },
  {
    heading: "Limited License to the App",
    content: (
      <>
        {" "}
        <p>
          We grant you a personal, worldwide, revocable, non-transferable, and
          non-exclusive license to access and use the App for personal and
          non-commercial purposes in accordance with the terms of this
          Agreement.
        </p>
        <p>
          All rights, title, and interest in and to the App not expressly
          granted in this Agreement are reserved by the Company. If you wish to
          use the Company’s software, title, trade name, trademark, service
          mark, logo, domain name and/or any other identification with notable
          brand features or other content owned by the Company, you must obtain
          written permission from the Company. Permission requests may be sent
          to <a href="mailto:support@iddera.com">support@iddera.com</a>.
        </p>
        <p>
          To avoid any doubt, the Company owns all the text, images, photos,
          audio, video, location data, and all other forms of data or
          communication that the Company creates and makes available in
          connection with the App, including but not limited to visual
          interfaces, interactive features, graphics, design, compilation of
          User Content, and the compilation of aggregate user review ratings and
          all other elements and components of the App, excluding User Content
          (as defined below). Except as expressly and unambiguously provided
          herein, we do not grant you any express or implied rights, and all
          rights in and to the App and the Company’s Content are retained by us.
        </p>
      </>
    )
  },
  {
    heading: "License to User Content",
    content: (
      <>
        {" "}
        <p>
          The App enables you to input personal notes, share your experience,
          post or upload content, submit content and log certain information
          into the App (“User Content”). You retain all rights to such User
          Content that you post, share, or log in the App.
        </p>
        <p>
          By providing your User Content to the App, you (a) grant the Company a
          non-exclusive, transferable, sublicensable, worldwide, royalty-free
          license to use, copy, exploit, modify, publicly display, publicly
          perform, create derivative works from, incorporate it into other
          works, change, reformat, and distribute your User Content in
          connection with providing and operating the App and related services
          and/or for the Company’s promotional purposes (for example, by
          displaying on our website, within the App, in social media, on any
          website or platform in the internet as we may deem appropriate),
          subject to the Privacy Policy; and (b) you agree to indemnify the
          Company and its affiliates, directors, officers, and employees and
          hold them harmless from any and all claims and expenses, including
          attorneys’ fees, arising from the media and/or your failure to company
          with the terms described in this Agreement.
        </p>
        <p>
          The Company reserves the right to review all User Content prior to
          submission to the App and to remove any media for any reason, at any
          time, without prior notice, at our sole discretion.
        </p>
      </>
    )
  },
  {
    heading: "Use at your own risk",
    content: (
      <>
        {" "}
        <p>
          Our goal is to help make certain health-related information more
          readily available and useful to you. However, the App cannot and does
          not guarantee health-related improvements or outcomes. Your use of the
          App and any information, predictions, or suggestions provided in the
          App are at your sole risk. We make no representation or warranty of
          any kind as to the accuracy of data, information, estimates, and
          predictions that we may provide to you through the App and you agree
          and understand that the App is not intended to match or serve the same
          purpose as a medical or scientific device.
        </p>
      </>
    )
  },
  {
    heading: "Use by minors’ disclaimer",
    content: (
      <>
        {" "}
        <p className="legal__doc-bold">
          THE INFORMATION WITHIN THE APP DOES NOT INCITE, INDUCE OR OTHERWISE
          PROMOTE ANY UNETHICAL BEHAVIOR OR ACTIVITY AMONG MINORS AND DOES NOT
          DIRECT THE CONTENT OF COMMUNICATION TO ANY PARTICULAR PERSON. ALL
          INFORMATION PROVIDED WITHIN THE APP IS FOR GENERAL PRIMARY HEALTHCARE
          PURPOSES ONLY.
        </p>
        <p>
          We carefully examine the materials that we make available via the App
          to people between 13 and 17 in order to avoid any inappropriate or
          harmful content. We fully understand that moral and ethical rules
          might differ from country to country in defining what information
          related to sexuality is admissible to minors.
        </p>
        <p>
          We neither intend nor publish sexually explicit content, or content
          that otherwise might qualify as harmful to minors or juveniles under
          applicable law. We make reasonable efforts to ensure that all
          materials provided by us on the App are based on facts only and are
          scientifically accurate.
        </p>
        <p>
          Please be aware that individual ethical views on what is offensive or
          harmful to minors may vary from the requirements set for content that
          may be made available to minors under applicable law.
        </p>{" "}
      </>
    )
  },
  {
    heading: "Subscriptions",
    content: (
      <>
        {" "}
        <h3>Iddera Premium Subscription</h3>
        <p>
          The mobile application offers the Premium subscription that grants you
          access to additional features like health symptom predictions enhanced
          by data science algorithms, personalized insights based on your
          symptoms and health history, tools for detecting physical and
          emotional patterns, full health report that you can provide to your
          doctor. Some of our subscriptions include a free trial period, where
          you can experience the mobile application at no cost. Subscription
          with the free trial period will automatically renew to a paid
          subscription once your free trial expires. If you decide to
          unsubscribe from a paid subscription before we start charging your
          payment method, cancel the subscription before the free trial ends.
        </p>
        <p>
          We offer an annual subscription service. Payment will be charged to
          your credit/debit card through Paystack, a payment platform by
          Paystack, Inc. after you choose one of our subscriptions and confirm
          your purchase. Paid subscriptions automatically renew unless
          auto-renew is turned off, until cancelled in the Manage Subscriptions
          section of your account settings. We will notify you if the price of
          subscription increases and, if required, seek your consent to
          continue. You will be charged no more than 24 hours prior to the start
          of the latest paid subscription period.
        </p>
        <h3>Services</h3>
        <p>
          We offer pay-as-you-go (PAYG) services for free-trial subscriptions.
          Payment will be charged to your credit/debit card through Paystack, a
          payment platform by Paystack, Inc. after you choose your service
          options and confirm your purchase. You expressly understand and agree
          that all payments and monetary transactions are handled by Paystack.
          You must not process stolen credit cards, or unauthorized credit cards
          through Paystack and/or your Account. Paid services automatically
          renew, unless auto-renew is turned off, until cancelled by contacting
          our support team{" "}
          <a href="mailto:support@iddera.com">support@iddera.com</a>. We will
          notify you if the price of our services increases and, if required,
          seek your consent to continue. To issue a refund or on any other
          issues with payments in connection with services, please contact our
          support team{" "}
          <a href="mailto:support@iddera.com">support@iddera.com</a>.
        </p>
        <p>You need to pay all charges in advance.</p>
        <h3>Iddera Premium</h3>
        <p>
          The mobile application offers the Premium subscription for its Lagos,
          Nigeria users only. The Premium subscription is subject to separate
          Terms and conditions. Please read it carefully before purchasing it.
        </p>
      </>
    )
  },
  {
    heading: "Member Networks",
    content: (
      <>
        <p>
          Iddera Member Networks (Iddera Community) is a special feature of the
          App that allows users to communicate with each other on a set of
          different topics related to their health and wellbeing. All user
          comments in Iddera Community are posted anonymously. If you delete
          your account, your comments will remain visible to other users of
          Iddera Community.
        </p>
        <p>As a user of Iddera Community, you shall not:</p>
        <ul>
          <li>
            Leave rude, harassing, insulting, provocative, discriminating,
            non-tolerant, religious, racist, political, homophobic, or offensive
            comments and statements
          </li>
          <li>
            Post abusive, offensive, obscene, pornographic, infringing, sexually
            explicit images or any other materials (including links to such
            materials) prohibited under applicable law or regulations or that
            otherwise may be in conflict with this Agreement
          </li>
          <li>
            Provide any medical advice or claim to be a healthcare professional
          </li>
          <li>Advertise any product or service</li>
          <li>
            Perform other forbidden actions as defined in Iddera Community Rules
          </li>
        </ul>
        <p>At our sole discretion, we reserve our right to:</p>
        <ul>
          <li>Delete any inappropriate or irrelevant comments or materials</li>
          <li>
            Delete or modify comments containing personal data, such as name,
            address, or email
          </li>
          <li>
            Restrict or ban your access to Iddera Community at any time and
            without notice if we determine that the content or use of Iddera
            Community is in violation of this Agreement
          </li>
          <li>
            Use, copy, modify, reshuffle, move, change, publicly display,
            publicly conduct and distribute discussions, comments, and materials
          </li>
          <li>
            Block your comments for any reasons or moderate them as we deem
            appropriate
          </li>
          <li>
            Disable Iddera Community at any time without prior notification
          </li>
        </ul>
        <p>
          Any information posted in Iddera Community shall not be considered as
          advice, drug prescription, or treatment suggestion. If in doubt about
          your health, consult a licensed healthcare professional. Please bear
          in mind that our users are not healthcare professionals, and their
          recommendations may not only be inaccurate, but also harmful to your
          health and well-being.
        </p>
        <p>
          Read more about the rules applicable to communication in Iddera
          Community in our Iddera Community Rules. Iddera Community Rules are an
          integral part of the Agreement. By accepting the Agreement, you also
          accept the Iddera Community Rules
        </p>
        <div className="legal__banner legal__banner--no-heading">
          <Icon id="info" />
          <p>
            To the maximum extent permitted under applicable law (including, but
            not limited to, communications decency act of 1996), the company
            disclaims liability for any direct or indirect Consequences arising
            from the information, links, and Materials posted by users, any
            actions or inactions of users, Violations of any applicable laws and
            regulations.
          </p>
        </div>
      </>
    )
  },
  {
    heading: "Passwords",
    content: (
      <>
        {" "}
        <p>
          You are responsible for taking all reasonable steps to ensure that no
          unauthorized person shall have access to your App passwords or
          account. It is your sole responsibility to{" "}
        </p>{" "}
        <ol>
          {" "}
          <li>
            Control the dissemination and use of sign-in name, screen name and
            passwords
          </li>
          <li>
            Authorize, monitor, and control access to and use of your App
            account and password
          </li>
          <li>
            Promptly inform the Company if you believe your account or password
            has been compromised or if there is any other reason you need to
            deactivate a password. Send us an email at{" "}
            <a href="mailto:support@iddera.com">support@iddera.com</a>. You
            grant the Company and all other persons or entities involved in the
            operation of the App the right to transmit, monitor, retrieve,
            store, and use your information in connection with the operation of
            the App.
          </li>
        </ol>
        <p>
          The Company cannot and does not assume any responsibility or liability
          for any information you submit, or your or third parties’ use or
          misuse of information transmitted or received using the App.
        </p>
      </>
    )
  },
  {
    heading: "Warranty disclaimer",
    content: (
      <>
        {" "}
        <p>
          The Company controls and operates the App from various locations and
          makes no representation that the App is appropriate or available for
          use in all locations. The App or certain features of it may not be
          available in your location or may vary across locations.
        </p>
        <p>
          The app is provided “as is”, “as available” and is provided Without
          any representations or warranties of any kind, Express or implied,
          including, but not limited to, the implied Warranties of title,
          non-infringement,merchantability, and Fitness for a particular
          purpose, and any warranties implied By any course of performance or
          usage of trade, all of which Are expressly disclaimed, save to the
          extent required by law. The company, and its directors, employees,
          agents, Representatives, suppliers, partners and content providers do
          not warrant that:
        </p>{" "}
        <ol type="a">
          <li>
            The app will be secure or available at Any particular time or
            location
          </li>
          <li> Any defects or errors will Be corrected</li>
          <li>
            Any content or software available at or Through the app is free of
            viruses or other harmful Components
          </li>
          <li>
            The results of using the app will meet your Requirements. Your use
            of the app is solely at your own risk
          </li>
          <li>
            Or, The accuracy, reliability, or completeness of the Content, text,
            images, software, graphics, or communications Provided by third
            parties on or through the app, including in Iddera community. Some
            states / countries do not allow Limitations on implied warranties,
            so the above limitations may not apply to you.
          </li>
        </ol>
      </>
    )
  },
  {
    heading: "Limitation of liability",
    content: (
      <>
        {" "}
        <p>
          {" "}
          In no event shall the company, its officers, directors, agents,
          Affiliates, employees, advertisers, or data providers be liable For
          any indirect, special, incidental, consequential or punitive Damages
          (including but not limited to loss of use, loss of Profits, or loss of
          data) whether in an action in contract, tort(including but not limited
          to negligence), equity or otherwise, Arising out of or in any way
          connected with the use of this app. In no event will the company’s
          total liability arising out of or In connection with these terms or
          from the use of or inability To use the app exceed the amounts you
          have paid to the Company for use of the app or one hundred dollars
          ($100) if you Have not had any payment obligations to the company, as
          Applicable. Some jurisdictions do not allow the exclusion or
          Limitation of liability, so the above limitations may not apply to
          you. The company, or any third parties mentioned on the app are Not
          liable for any personal injury, including death, caused by your use or
          misuse of the app.
        </p>
      </>
    )
  },
  {
    heading: "Use of mobile devices",
    content: (
      <>
        <p>
          {" "}
          Please note that your carrier’s normal rates and fees, such as text
          messaging and data charges, will still apply if you are using the App
          on a mobile device.
        </p>{" "}
      </>
    )
  },
  {
    heading: "Third-Party Services",
    content: (
      <>
        {" "}
        <p>
          The App may give you access to links to third-party websites, apps, or
          other products or services (“Third Party Services”). The Company does
          not control Third Party Services in any manner and, accordingly, does
          not assume any liability associated with such Third-Party Services.
          You need to take appropriate steps to determine whether accessing a
          Third-Party Service is appropriate, including protecting your personal
          information and privacy in using any such Third-Party Services and
          complying with relevant agreements.
        </p>
      </>
    )
  },
  {
    heading: "Your feedback",
    content: (
      <>
        {" "}
        <p>
          We welcome your feedback about the App. Unless otherwise expressly
          declared, any communications you send to us or publish in app stores
          are deemed to be submitted on a non-confidential basis. You agree that
          we may decide to publicize such contents at our own discretion. You
          agree to authorize us to make use of such contents for free, and
          revise, modify, adjust, and change contextually, or make any other
          changes as we deem appropriate.
        </p>{" "}
      </>
    )
  },
  {
    heading: "Enforcement rights",
    content: (
      <>
        {" "}
        <p>
          We are not obligated to monitor access or use of the App. However, we
          reserve the right to do so for purposes of operating and maintaining
          the App, ensuring your compliance with this Agreement, and complying
          with applicable legal requirements.
        </p>
        <p>
          We may disclose unlawful conduct to law enforcement authorities, and
          pursuant to valid legal process, we may cooperate with law enforcement
          authorities to prosecute users who violate the law. We reserve the
          right (but are not required) to remove or disable any content posted
          to the App or access to App at any time and without notice, and at our
          sole discretion if we determine in our sole discretion that your
          content or use of the App is objectionable or in violation this
          Agreement.
        </p>
        <p>
          The Company has no liability or responsibility to users of the App or
          any other person or entity for performance or nonperformance of the
          aforementioned activities.
        </p>
      </>
    )
  },
  {
    heading: "Changes to the App",
    content: (
      <>
        <p>
          From time to time and without prior notice to you, we may change,
          expand, and improve the App. We may also, at any time, cease to
          continue operating part or all of the App or selectively disable
          certain features of the App. Your use of the App does not entitle you
          to the continued provision or availability of the App. Any
          modification or elimination of the App or any particular features will
          be done in our sole and absolute discretion and without an ongoing
          obligation or liability to you.
        </p>{" "}
      </>
    )
  },
  {
    heading: "Indemnity",
    content: (
      <>
        {" "}
        <p>
          You agree to defend, indemnify, and hold the Company, its officers,
          directors, employees, agents, licensors, and suppliers, harmless from
          and against any claims, actions or demands, liabilities and
          settlements including without limitation, reasonable legal and
          accounting fees, resulting from, or alleged to result from, your
          violation of this Agreement.
        </p>
      </>
    )
  },
  {
    heading: "Miscellaneous",
    content: (
      <>
        {" "}
        <p>
          Any dispute arising from this Agreement shall be governed by the laws
          of Nigeria without regard to its conflict of law provisions.
        </p>
        <div className="legal__banner legal__banner--no-heading">
          <Icon id="info" />
          <p>
            Sole and exclusive Jurisdiction for any action or proceeding arising
            out of or Related to this agreement shall be in an appropriate state
            or Federal court located in nigeria, lagos state, and the parties
            Unconditionally waive their respective rights to a jury trial.
          </p>
        </div>
        <p>
          Any cause of action you may have with respect to your use of the App
          must be commenced within one (1) year after the claim or cause of
          action arises.
        </p>
        <p>
          If for any reason a court of competent jurisdiction finds any
          provision of this Agreement, or a portion thereof, to be
          unenforceable, that provision shall be enforced to the maximum extent
          permissible so as to affect the intent of this Agreement, and the
          remainder of this Agreement shall continue in full force and effect. A
          printed version of this Agreement shall be admissible in judicial or
          administrative proceedings.
        </p>
        <p>
          No waiver of by the Company of any term or condition set forth in this
          Agreement shall be deemed a further or continuing waiver of such term
          or condition or a waiver of any other term or condition, and any
          failure of the Company to assert a right or provision under this
          Agreement shall not constitute a waiver of such right or provision. If
          any provision of this Agreement is held by a court or other tribunal
          of competent jurisdiction to be invalid, illegal or unenforceable for
          any reason, such provision shall be eliminated or limited to the
          minimum extent such that the remaining provisions of this Agreement
          will continue in full force and effect.
        </p>
        <p>
          Upon termination, all provisions of this Agreement, which, by their
          nature, should survive termination, shall survive termination,
          including, without limitation, ownership provisions, warranty
          disclaimers, and limitations of liability. All claims between the
          parties related to this Agreement will be litigated individually, and
          the parties will not consolidate or seek class treatment for any claim
          unless previously agreed to in writing by the parties.
        </p>
        <p>
          We may refuse service, close Accounts, and change eligibility
          requirements at any time.
        </p>
      </>
    )
  },
  {
    heading: "Notice and takedown procedures",
    content: (
      <>
        <p>
          If you believe any materials accessible on or from the App infringe
          your copyright, you may request removal of those materials (or access
          thereto) from this App by contacting the Company and providing the
          following information:
        </p>{" "}
        <ol type="a">
          {" "}
          <li>
            Identification of the copyrighted work that you believe to be
            infringed. Please describe the work, and, where possible, include a
            copy or the location (e.g., App page) of an authorized version of
            the work.
          </li>
          <li>
            Identification of the material that you believe to be infringing and
            its location. Please describe the material and provide us with its
            URL or any other pertinent information that will allow us to locate
            the material.
          </li>
          <li>
            Your name, address, telephone number and (if available) e-mail
            address.
          </li>
          <li>
            A statement that you have a good faith belief that the complained of
            use of the materials is not authorized by the copyright owner, its
            agent, or the law.
          </li>
          <li>
            A statement that the information that you have supplied is accurate,
            and indicatingthat “under penalty of perjury,” you are the copyright
            owner or are authorized to act on the copyright owner’s behalf.
          </li>
          <li>
            A signature or the electronic equivalent from the copyright holder
            or authorized representative.In an effort to protect the rights of
            copyright owners, the Company maintains a policy for the
            termination, in appropriate circumstances, of subscribers and
            account holders of the App who are repeat infringers.
          </li>
        </ol>
      </>
    )
  },
  {
    heading: "Questions and comments",
    content: (
      <>
        {" "}
        If you have any comments or questions on any part of the App or any part
        of these Terms of Use, require support, or have any claims, please
        contact us using the information in the following section.
      </>
    )
  }
];

const TermsConditions = () => {
  const location = useLocation();
  const sectionRefs = useRef([]);

  useEffect(() => {
    const section = new URLSearchParams(location.search).get("section");

    const validSection =
      section && section >= 0 && section <= sections.length + 1;

    if (validSection) {
      window.scroll({
        top: sectionRefs.current[section - 1].offsetTop,
        behavior: "smooth"
      });
    }
  }, [location.search]);

  return (
    <LegalLayout
      heading={<>It’s all in bold print. We really want you to know.</>}
      topImg={scroll}
      topFog={<div className="top-fog top-fog--legal-tc" />}
      tableOfContent={sections.map((section) => section.heading)}
      setContactSectionRef={(el) => (sectionRefs.current[sections.length] = el)}
    >
      <>
        {sections.map((section, index) => (
          <section
            className="legal__doc-section"
            ref={(el) => (sectionRefs.current[index] = el)}
          >
            <h2>
              {index + 1}. {section.heading}
            </h2>
            {section.content}
          </section>
        ))}
      </>
    </LegalLayout>
  );
};

export default TermsConditions;
