import React from "react";
import LearnContent from "./components/LearnContent";
import LearnDetailsContent from "./components/LearnDetailsContent";
import LearnLayout from "./components/LearnLayout";
import "./styles/main.css";
import "./styles/responsive.css";

const LearnPage = () => {
  return (
    <LearnLayout>
      <LearnContent />
    </LearnLayout>
  );
};

const LearnDetailsPage = () => {
  return (
    <LearnLayout isDetailsPage={true}>
      <LearnDetailsContent />
    </LearnLayout>
  );
};

export { LearnPage, LearnDetailsPage };
