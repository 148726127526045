import React from "react";
import { Header, JoinIddera, Footer } from "../Common";
import Pricing from "./components/Pricing";
import Insurance from "./components/Insurance";
import "./styles/main.css";
import "./styles/responsive.css";

const PricingPage = () => {
  return (
    <div className="webp prp">
      <div className="top-fog top-fog--prp" />
      <Header />
      <Pricing />
      <Insurance />
      <JoinIddera />
      <Footer />
    </div>
  );
};

export default PricingPage;
