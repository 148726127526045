import Icon from "components/Icon";
import React from "react";
import tagIcon1 from "assets/icons/mlp-stethoscope.svg";
import tagIcon2 from "assets/icons/mlp-notion.png";
import illustration1 from "assets/images/mlp-card-1.png";
import illustration2 from "assets/images/mlp-card-2.png";
import { useHistory } from "react-router";

const cards = {
  consultation: {
    tagIcon: tagIcon1,
    tagText: "Consultation",
    heading: "Talk to a Doctor Now",
    btnAction: () => {
      window.zE("webWidget", "show");
    },
    illustration: illustration1
  },
  learn: {
    tagIcon: tagIcon2,
    tagText: "Iddera Wiki",
    heading: "Learn more about Iddera",
    btnAction: () => {
      const wikiUrl =
        "https://iddera.notion.site/iddera/Your-Personal-Physician-Anytime-Anywhere-8b9bf4357ee4416d900eca14ea90dbe0";
      window.open(wikiUrl, "_blank").focus();
    },
    illustration: illustration2
  }
};

const MlpCard = ({ type }) => {
  return (
    <div className="mlp__card">
      <div className="mlp__card-content">
        <p className="mlp__card-tag">
          <img
            src={cards[type].tagIcon}
            alt=""
            className="mlp__card-tag-icon"
          />
          {cards[type].tagText}
        </p>
        <h3 className="mlp__card-heading">{cards[type].heading}</h3>

        {type === "learn" ? (
          <button className="btn mlp__btn" onClick={cards[type].btnAction}>
            Continue <Icon id="arrow-topright" />
          </button>
        ) : (
          <p className="mlp__chat-prompt">
            {" "}
            Click the chat button on the bottom right corner of your screen.
          </p>
        )}
      </div>
      <img
        src={cards[type].illustration}
        alt=""
        className="mlp__illustration"
      />
    </div>
  );
};

export default MlpCard;
