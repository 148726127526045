import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LegalLayout from "./LegalLayout";
import lock from "assets/images/legal-lock.png";
import Icon from "components/Icon";
import WrappedIcon from "components/WrappedIcon";

const initialTableData = {
  "section-4": {
    hidden: false,
    canHide: false,
    data: [
      {
        col1: "To analyze, operate, maintain and improve the App, to add new features and services to the App, to support the existing functions of the app",
        col2: "Consent",
        col3: "We may use your health data to predict your future health"
      },
      {
        col1: "To customize content and materials you see when you use the app",
        col2: "Consent",
        col3: "Based on your preferences and logged-in information we may provide you certain suggested articles or materials (e.g stories) to read"
      },
      {
        col1: "To customize product and service offerings and recommendations to you, including third-party products and offerings (excluding data from Apple HealthKit, Fitbit and Google Fit)",
        col2: "Consent",
        col3: (
          <>
            We may send you a discount offer for Iddera Premium to your email.
            You can opt-out anytime by contacting us at{" "}
            <a href="mailto:support@iddera.com">support@iddera.com</a>{" "}
          </>
        )
      },
      {
        col1: "To provide and deliver the products and services you request, process transactions and send you related information, including confirmations and reminders ",
        col2: "Contract",
        col3: "Using your device data we may send you a reminder to log your data and symptoms to make predictions more accurate You can disable this anytime in your phone settings"
      },
      {
        col1: "For billing (invoicing), account management and other administrative purposes, if applicable",
        col2: "Contract",
        col3: "We may send you an email containing your invoice, if applicable"
      },
      {
        col1: "To respond to your comments, questions and requests and to provide customer service",
        col2: "Contract",
        col3: "We may process your name and email to reply to your support request"
      },
      {
        col1: "To send you technical notices, updates, security alerts and support and administrative messages",
        col2: "Legitimate Interest",
        col3: (
          <>
            We may send you an email notification that contains a customer
            satisfaction survey Youcan opt-out anytime by contacting us at{" "}
            <a href="mailto:support@iddera.com">support@iddera.com</a>
          </>
        )
      },
      {
        col1: "To monitor and analyze trends, usage and activities in connection with our App",
        col2: "Consent",
        col3: "We may analyze your browsing activity in the App to understand what you like or dislike about it in order to improve your future experience"
      },
      {
        col1: "Solely with respect to information that you agree  to share, for Company promotional purposes (except data from Apple HealthKit, Fitbit and Google Fit)",
        col2: "Consent",
        col3: "If you give your consent, we can post your review or comment on our website"
      },
      {
        col1: "To verify your identity",
        col2: "Legal Obligation",
        col3: "We may ask for age verification (e.g. an ID) if we have reasonable doubts regarding your age"
      }
    ]
  },
  "section-6": {
    hidden: true,
    canHide: true,
    data: [
      {
        col1: <>Infrastructure Security</>,
        col2: <>AWS(Amazon Web Services, Inc)</>,
        col3: (
          <>
            <a href="https://aws.amazon.com/privacy/">AWS privacy policy </a>
          </>
        ),
        col4: (
          <>
            <ul>All Personal Data</ul>
          </>
        ),
        col5: (
          <>
            <ul>
              <li>Storage of all Personal Data when you use the App</li>
            </ul>{" "}
          </>
        )
      },
      {
        col1: <>Infrastructure Security</>,
        col2: <>Clouflare (Cloudflare, Inc.)</>,
        col3: (
          <>
            <a href="https://www.cloudflare.com/privacypolicy/">
              Cloudflare privacy policy
            </a>
          </>
        ),
        col4: (
          <>
            <ul>
              <li>All Personal Data</li>
            </ul>
          </>
        ),
        col5: (
          <>
            <ul>
              <li>Security of the App, content delivery</li>
            </ul>{" "}
          </>
        )
      },
      {
        col1: <>Infrastructure Security</>,
        col2: <>Auth0 (Auth0, Inc.)</>,
        col3: (
          <>
            <a href="https://auth0.com/privacy/">
              Auth0 privacy and cookie policy{" "}
            </a>
          </>
        ),
        col4: (
          <>
            <ul>
              <li>Email Address</li>
              <li>IP address</li>
              <li>Name</li>
            </ul>
          </>
        ),
        col5: (
          <>
            <ul>
              <li>Authentication and authorization services</li>
            </ul>{" "}
          </>
        )
      },
      {
        col1: <>Email Communications</>,
        col2: <>SendGrid(SendGrid, Inc USA)</>,
        col3: (
          <>
            <a href="https://www.twilio.com/legal/privacy">
              SendGrid privacy policy
            </a>
          </>
        ),
        col4: (
          <>
            <ul>
              <li>Email Address</li>
              <li>Personalized Texts</li>
            </ul>
          </>
        ),
        col5: (
          <>
            <ul>
              <li>
                To reach you with our newsletters, surveys and notifications
              </li>
            </ul>{" "}
          </>
        )
      },
      {
        col1: <>Email and in-App Communications</>,
        col2: <>SurveyMonkey(SurveyMonkey Inc,. USA)</>,
        col3: (
          <>
            <a href="https://www.surveymonkey.com/mp/legal/privacy-policy/">
              SurveyMonkey privacy policy
            </a>
          </>
        ),
        col4: (
          <>
            <ul>
              <li>IP Address</li>
              <li>User ID</li>
              <li>Results of surveys</li>
            </ul>
          </>
        ),
        col5: (
          <>
            <ul>
              <li>To deliver different service related surveys</li>
            </ul>{" "}
          </>
        )
      },
      {
        col1: <>Analytical tools</>,
        col2: <> Loooker (Looker Data Sciences, In. USA)</>,
        col3: (
          <>
            <a href="https://looker.com/trust-center/privacy/policy">
              Looker privacy policy
            </a>
          </>
        ),
        col4: (
          <>
            <ul>
              <li>App Usage Data</li>
            </ul>
          </>
        ),
        col5: (
          <>
            <ul>
              <li>
                to understand how you use the App, engage with particular
                features and what you like or dislike the most
              </li>{" "}
              <li>To generate statistical reports</li>
            </ul>{" "}
          </>
        )
      },
      {
        col1: <>Analytical tools</>,
        col2: <>Amplitude (Amplitude, Inc.) </>,
        col3: (
          <>
            <a href="https://amplitude.com/privacy">Amplitude privacy policy</a>
          </>
        ),
        col4: (
          <>
            <ul>
              <li>App Usage Data</li>
            </ul>
          </>
        ),
        col5: (
          <>
            <ul>
              <li>
                to understand how you use the Web services, engage with
                particular features and what you like or dislike the most to
                engineer product experiences
              </li>
            </ul>{" "}
          </>
        )
      },

      {
        col1: <>Internal Functions</>,
        col2: <>Algolia (Algolia, Inc.)</>,
        col3: (
          <>
            <a href="https://www.algolia.com/policies/privacy/">
              Algolia privacy policy
            </a>
          </>
        ),
        col4: (
          <>
            <ul>
              <li>IP address and user ID</li>
              <li>Content of the search request</li>
              <li>Age</li>
              <li>Aim and usage purpose</li>
            </ul>
          </>
        ),
        col5: (
          <>
            <ul>
              <li>
                to provide you search functions inside the App, including search
                suggestions for all users
              </li>{" "}
              <li>
                Some Personal Data is needed to increase the accuracy of the
                search
              </li>
            </ul>{" "}
          </>
        )
      },
      {
        col1: <>Customer Support</>,
        col2: <>Zendesk (Zendesk Inc,.) USA</>,
        col3: (
          <>
            <a href="https://www.zendesk.com/company/customers-partners/privacy-policy/">
              Zendesk privacy policy
            </a>
          </>
        ),
        col4: (
          <>
            <ul>
              <li>Email address</li>
              <li>Content of the emails</li>
            </ul>
          </>
        ),
        col5: (
          <>
            <ul>
              <li>to process and sort all emails received from you</li>
            </ul>{" "}
          </>
        )
      },
      {
        col1: <>Payments</>,
        col2: <>Paystack</>,
        col3: (
          <>
            <a href="https://paystack.com/terms">Paystack privacy policy</a>
          </>
        ),
        col4: (
          <>
            <ul>
              <li>Payment and banking information</li>
              <li>Personal Identifiers</li>
            </ul>
          </>
        ),
        col5: (
          <>
            <ul>
              <li>
                {" "}
                To collect and process payments for subscription to the App{" "}
              </li>
            </ul>{" "}
          </>
        )
      },
      {
        col1: <>Website, Web Services</>,
        col2: <>Amplitude (Amplitude, Inc.)</>,
        col3: (
          <>
            <a href="https://amplitude.com/privacy">Amplitude privacy policy</a>
          </>
        ),
        col4: (
          <>
            When you use the Website and the Web services some third parties may
            collect information about your visit and activities via cookies and
            other tracking technologies (e.g. special pixels) for various
            purposes like analytics or improvement of performance. See more
            about cookies and how you can opt-out from them in our{" "}
            <Link to="/cookie-policy">Cookie Policy</Link>
          </>
        ),
        col5: <></>
      }
    ]
  },
  "section-7": {
    hidden: false,
    canHide: false,
    data: [
      {
        col1: "GP records: This includes medical records, consultations with GPs and symptom checker interactions",
        col2: (
          <>
            {" "}
            <p>
              We keep your GP records for 10 years after your death or after
              you've permanently stopped using our service.
            </p>
            <p>
              We may keep your records longer if there are genetic implications
              for your family. We work on the advice from clinicians in this
              situation.
            </p>
            <p>
              Electronic patient records can't be destroyed or deleted for the
              foreseeable future.
            </p>{" "}
          </>
        )
      },
      {
        col1: "Video Consultations",
        col2: "If we keep your video consultations, they are kept in the same way as your GP records (although that period of time could change if our product changes)."
      },
      {
        col1: "Voice (or audio) consultations",
        col2: (
          <>
            We keep your voice consultations in the same way as your GP records
            (although that period of time could change if our product changes).
          </>
        )
      },
      {
        col1: "Symptom Checker",
        col2: (
          <>
            {" "}
            <p>
              We keep your interactions with our Symptom Checker in the same way
              as your GP records.
            </p>
            <p>
              They are also available in the app for 1 month (although that
              period of time could change if our product changes). After 1 month
              we can provide them if you ask us for them.
            </p>
          </>
        )
      },
      {
        col1: "Healthcheck and Digital Twin records",
        col2: (
          <>
            {" "}
            We keep your records from these services for 2 years after you close
            your account, unless you agree to them being a part of your medical
            record. If you do, we will store them in the same way as your GP
            records
          </>
        )
      },
      {
        col1: "Communications with support teams, including phone calls, emails and live chats",
        col2: <> 1 year after you leave the Iddera service. </>
      },
      {
        col1: "Records on any treatment for a mental disorder (as described in mental health legislation)",
        col2: (
          <>
            {" "}
            We keep your records for 20 years after the date of your last
            consultation. Or 10 years after your death if that is sooner{" "}
          </>
        )
      }
    ]
  }
};

const PrivacyPolicy = () => {
  const location = useLocation();
  const sectionRefs = useRef([]);
  const [tableData, setTableData] = useState(initialTableData);
  const sections = [
    {
      heading: "Background",
      content: (
        <>
          <p>
            When you use Iddera, you are trusting us with intimate personal
            information. We are committed to keeping that trust, which is why
            our policy as a company is to take every step to ensure that
            individual user's data and privacy rights are protected and to
            provide transparency about our data practices. The primary purpose
            of our Privacy Policy is to provide a clear understanding of what
            data we collect, how it is used and shared, and how you can control
            it.
          </p>
          <p className="legal__doc-bold">
            We recommend that you read this Privacy Policy in full along with
            our Terms of Use, but here are a few key takeaways we hope you will
            find useful:
          </p>
          <h4> The Data that serves you</h4>
          <p>
            When you use Iddera, we may collect your Personal Data and use it to
            improve the user experience, such as increasing the accuracy of
            predictions, personalizing the insights you get, etc. For research
            activities we use only de-identified or aggregated data, which
            cannot be associated with you.
          </p>
          <h4> You can contribute to the growth of Iddera community</h4>
          <p>
            Provided we receive your consent, we may use technical information
            about you (yourunique technical identifier, age group, subscription
            status, and the fact of application launch) for promotion purposes
            to reach more people like you. You can always withdraw your consent
            to share this data.
          </p>
          <h4> You are in control</h4>
          <p>
            You may access your Personal Data, modify, correct, erase, and
            update it by writing to us at{" "}
            <a href="mailto:support@iddera.com">support@iddera.com</a>. You may
            also download the information Iddera collected about you by
            contacting us. Please be aware that erasing or modifying some
            Personal Data inserted by you may affect your possibility to use
            Iddera in the future.
          </p>
          <h4>Your data is safe with Iddera</h4>
          <p>
            Your employer, your insurance company, even your relatives - none of
            them will ever know about the symptoms you log or the information
            you get in Iddera until you tell them. We take all reasonable and
            appropriate measures to protect your Personal Data from loss, theft,
            misuse, or unauthorized access.
          </p>
          <h4> We protect the privacy of children</h4>
          <p>
            That is why you should be at least 18 to use Iddera. We do not
            intentionally collect information about children, and we do not
            allow people to use the App if they are younger than 18. Moreover,
            some of the App functions may be limited to users that are younger
            than 18.
          </p>
          <h4> You can freely talk to us</h4>
          <p>
            We believe in transparent and open dialogue, so we strongly
            encourage you to contact our Support Team, our Data Protection
            Officer or send a message via our dedicated email if you have
            questions about this policy, how we collect or process your Personal
            Data, or anything else related to our privacy practices.
          </p>
        </>
      )
    },
    {
      heading: "Introduction",
      content: (
        <>
          <p>
            This Privacy Policy explains how Iddera Life Solutions Ltd..
            (“Company” or “we” or “us”) collects, stores, uses, transfers and
            shares Personal Data from our users (“you”) in connection with:
          </p>
          <ul className="legal__doc-bold">
            <li>the Iddera ® mobile application, </li>
            <li>
              Iddera website including any products and services related to it
              ("Website"), (all collectively, the “App”).
            </li>
          </ul>
          <p>
            We reserve the right to and may change this Privacy Policy from time
            to time. If we make any material changes, we will notify you by
            email (sent to the email address specified when you register),
            through the App, or by presenting you with a new version of this
            Privacy Policy. Your continued use of the App after the effective
            date of an updated version of the Privacy Policy will indicate your
            acceptance of the Privacy Policy as modified. In some cases, you
            will have to accept changes to the Privacy Policy explicitly. Please
            review our Website and the App for the latest updates on our data
            privacy practices. If you do not accept the terms of the Privacy
            Policy, we ask that you do not use the App. Please exit the App
            immediately if you do not agree to the terms of this Privacy Policy.
          </p>
        </>
      )
    },
    {
      heading: "Personal Data we collect from you",
      content: (
        <>
          <h3>Personal Data you provide us directly</h3>
          <h4> General Information</h4>
          <p>
            When you sign up to use the App, we may collect Personal Data about
            you such as:
          </p>
          <ul>
            <li>Name</li> <li>Email Address</li> <li>Gender</li>
            <li>Date of Birth</li> <li>Password or passcode</li>
            <li>Place of residence and associated location information</li>
            <li>ID (for limited purposes)</li>
          </ul>
          <h4> Health and Well-being </h4>
          <p>
            When you use the App, you may choose to provide personal information
            about your health and well-being such as:
          </p>
          <ul>
            <li>Weight;</li> <li>Body temperature;</li> <li>Your lifestyle;</li>{" "}
            <li>Various symptoms related to your condition;</li>{" "}
            <li>
              Other information about your health (including sexual activities),
              well-being, and related activities, including personal life and
              family history (collectively, “Personal Data”).
            </li>{" "}
          </ul>
          <p>
            You also may give us the ability to import into the App Personal
            Data about your health and activities from third-party services such
            as Apple HealthKit, Fitbit, and Google Fit. Such imported Personal
            Data may include sports activities, weight, calories burnt,
            heartbeat rate, number of steps/distance traveled, and other data
            about your health. In order for us to process any Personal Data
            under this category we will explicitly ask your consent on the
            registration screen.
          </p>
          <h4> Personal Data we may collect automatically</h4>
          <p>
            {" "}
            When you access or use the App, we may automatically collect the
            following information:
          </p>
          <h4> 1. Device Information </h4>
          <ul>
            <li>Hardware model; </li>{" "}
            <li>Information about the operating system and its version; </li>{" "}
            <li>Unique device identifiers (e.g. IDFA); </li>{" "}
            <li>Mobile network information; </li>{" "}
            <li>Device storage information. </li>{" "}
          </ul>

          <h4> 2. Location Information </h4>
          <ul>
            <li>IP Address</li>
            <li>Time Zone</li>
            <li>Information about your mobile service Provider</li>
          </ul>
          <h4> 3. App Usage data, Including, among others</h4>
          <ul>
            <li>Frequency of Use</li>
            <li>Areas and features of our App you visit</li>
            <li>Your use patterns generally</li>
            <li>Engagement with particular features</li>
            <li>
              To collect this information, we may also send cookies to your
              mobile device or computer or engage other tracking technologies.
              See more in our <Link to="/cookie-policy">Cookie Policy</Link>.
            </li>
          </ul>
          <h4> 4. Data from External Sources</h4>
          <p>
            {" "}
            We may use third-party tools like AppsFlyer that provide us some of
            your attribution data that we further utilize to customize and
            personalize your App experience. We may also use such data for
            statistical purposes and analytics.
          </p>
        </>
      )
    },
    {
      heading: "How we use your Personal Data",
      content: (
        <>
          <p>
            As any compliant organization we will not collect and use your
            Personal Data without letting you know or having any reasons for
            that whatsoever. So, for any purpose of processing your Personal
            data we need to have some valid ground, or so-called “legal basis”.
          </p>{" "}
          <p>
            Depending on a case, we will process your Personal data based on the
            following legal basis;
          </p>
          <ul>
            <li>
              Your consent. Your permission to process the Personal Data that
              you provide to us on the registration screen or at any other
              appropriate moment if necessary;
            </li>
            <li>
              Your contractual relations with us. We may need to process some
              Personal Data for making the App and its services available for
              access, installation and further use;
            </li>
            <li>
              Legitimate interest. We may process some of your personal data to
              pursue commercial interests and wider societal benefits;
            </li>
            <li>
              Legal obligation. We may be obligated to process some of your
              Personal Data to comply with applicable laws and regulations.
            </li>
          </ul>{" "}
          <p>
            Here is the description of the main type of processing activities we
            conduct with your Personal data and related legal basis for that
            (with some basic examples):{" "}
          </p>
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>Purpose of Processing</th>
                  <th>Legal Basis for processing</th>
                  <th>Example</th>
                </tr>
              </thead>

              <tbody>
                {tableData["section-4"]?.data.map((row, index) => (
                  <tr key={index}>
                    <td>{row.col1}</td>
                    <td>{row.col2}</td>
                    <td>{row.col3}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <h3> Principles of processing </h3>
          <h4> Data minimization and purpose limitation</h4>
          <p>
            {" "}
            We will not process Personal Data in a way that is incompatible with
            the purposes for which it has been collected or subsequently
            authorized by you or collect any Personal Data that is not needed
            for the mentioned purposes. For any new purpose of processing, we
            will ask your seperate consent.
          </p>
          <h4> No sale of Personal Data</h4>
          <p>
            {" "}
            We will never sell, rent, or disclose your Personal Data. We may
            share only some of your Personal Data to our service providers
            strictly limited to cases and purposes stipulated in this Privacy
            Policy. We will also not use the information gained through your use
            of the HealthKit, Fitbit and Google Fit framework for advertising or
            similar services, or sell it to advertising platforms, data brokers,
            or information resellers.
          </p>
        </>
      )
    },
    {
      heading: "Your privacy right",
      content: (
        <>
          <p>
            {" "}
            It does not matter what country or region you come from. We are
            committed to providing you vast privacy rights for your Personal
            Data.
          </p>
          <h3> What rights?</h3>
          <h4> Correction of Personal Data</h4>
          <p>
            {" "}
            If you believe that your Personal Data is inaccurate, you have a
            right to contact us and ask us to correct such Personal Data.
          </p>
          <h4> Restriction of Processing</h4>
          <p>
            You also have a right to request that the processing of your
            Personal Data be restricted if you contest the accuracy of the
            Personal Data, and we need some time to verify its accuracy.
          </p>
          <h4>
            {" "}
            Information rights and access to your Personal Data (including in
            portable form)
          </h4>
          <p>
            {" "}
            The App gives you the ability to access Personal Data within the
            App. You have a right to request information about what Personal
            Data we have about you, to access all your Personal Data and receive
            a copy of it, including in a structured and portable form (.json).
            We also commit to notify you, as required under applicable laws,
            about Personal Data breaches related to your Personal Data.{" "}
          </p>
          <h4> Erasure of your Personal Data</h4>
          <p>
            {" "}
            You may ask us to erase your Personal Data, if you withdraw your
            consent to processing, if you believe such processing is not
            compliant with applicable law and in some other cases. Please be
            aware that erasing some Personal Data may affect your App
            experience.{" "}
          </p>
          <h4> Right to object to the processing of your Personal Data</h4>
          <p>
            {" "}
            In some cases, you can object to the processing of your Personal
            Data, for example, if we process it under legitimate interest basis
            by contacting us at{" "}
            <a href="mailto:support@iddera.com">support@iddera.com</a>.
          </p>
          <h3> How to exercise your privacy rights</h3>
          <p>
            Contact us at{" "}
            <a href="mailto:support@iddera.com">support@iddera.com</a> to
            exercise any of your privacy rights. We will exercise them within 30
            days after receipt. It may take us up to 90 days in some cases, for
            example for full erasure of your Personal Data stored in our backup
            systems. We will let you know if we need more time and explain the
            reasons for the delay.
          </p>

          <h4> What else?</h4>

          <p>
            Please keep in mind that if we receive a vague request, we may
            engage the individual to better understand the motivation and
            content of the request. We may also refuse manifestly unfounded and
            excessive (repetitive) requests.
          </p>
          <p>
            We might also require you to prove your identity in some cases.
            Normally, we make sure to verify that the request is coming from the
            same email as you indicated when registering in the App. In case of
            doubt, we may ask you to undergo some additional verification. This
            is made to ensure that no rights of third parties are violated by
            your request.
          </p>
          <p>
            Subject to applicable laws, you may have a right to lodge a
            complaint with your local data protection authority about any of our
            activities (related to your privacy rights, among others) that you
            think are not compliant with applicable law. However, if you think
            that we do something incorrectly, let us know first at{" "}
            <a href="mailto:privacy@iddera.com">privacy@iddera.com</a>. We care
            about your privacy and want to make sure that we did everything to
            address any of your concerns.
          </p>
          <h4> Third-parties processing your Personal Data</h4>
          <p>
            {" "}
            We will not share your Personal Data with any third parties except
            as specified below.
          </p>
          <h3>
            {" "}
            Processing to find new Iddera users and stay in touch with you
          </h3>
          <p>
            Contact us at{" "}
            <a href="mailto:support@iddera.com">support@iddera.com</a> to
            exercise any of your privacy rights. We will exercise them within 30
            days after receipt. It may take us up to 90 days in some cases, for
            example for full erasure of your Personal Data stored in our backup
            systems. We will let you know if we need more time and explain the
            reasons for the delay.
          </p>
          <h4> What else? </h4>
          <p>
            Please keep in mind that if we receive a vague request, we may
            engage the individual to better understand the motivation and
            content of the request. We may also refuse manifestly unfounded and
            excessive (repetitive) requests.
          </p>
          <p>
            We might also require you to prove your identity in some cases.
            Normally, we make sure to verify that the request is coming from the
            same email as you indicated when registering in the App. In case of
            doubt, we may ask you to undergo some additional verification. This
            is made to ensure that no rights of third parties are violated by
            your request.
          </p>
          <p>
            Subject to applicable laws, you may have a right to lodge a
            complaint with your local data protection authority about any of our
            activities (related to your privacy rights, among others) that you
            think are not compliant with applicable law. However, if you think
            that we do something incorrectly, let us know first at{" "}
            <a href="mailto:privacy@iddera.com">privacy@iddera.com</a>. We care
            about your privacy and want to make sure that we did everything to
            address any of your concerns.
          </p>
        </>
      )
    },
    {
      heading: "Third parties processing your Personal Data",
      content: (
        <>
          <p>
            {" "}
            We will not share your Personal Data with any third parties except
            as specified below.
          </p>{" "}
          <h3>
            {" "}
            Processing to find new Iddera users and stay in touch with you
          </h3>
          <p>
            We may share some of your non-health Personal Data with{" "}
            <a href="https://www.appsflyer.com/legal/privacy-policy/">
              AppsFlyer, a mobile marketing platform{" "}
            </a>
            , that handles your Personal Data in accordance with our
            instructions. By using AppsFlyer and its integrated partners we are
            able to reach youand people like you on various platforms and spread
            the word about the App to help more humans to stay in control of
            their health and well-being. If we need to share your Personal Data
            with other platforms for this purpose, except as defined herein, we
            will ask for your consent. If this is required by law, we will
            secure your consent to share your non-health data with AppsFlyer and
            its integrated partners.
          </p>
          <p>
            Here is a step-by-step illustration of how we work with AppsFlyer
            and its integrated partners:
          </p>
          <h4>
            1. You become a Iddera user and we start sharing Personal Data,
            strictly limited to the following set:
          </h4>
          <ol type="a">
            <li>
              Technical identifiers: IP address (which may also provide general
              location information), User agent, IDFA
            </li>
            <li>
              (Identifier for advertisers), Android ID (in Android devices),
              Google Advertiser ID, Customer-issued user ID and other similar
              unique technical identifiers.
            </li>
            <li> Your age group;</li>
            <li> Your subscription status;</li>
            <li> The fact of application launch.</li>
          </ol>
          <h4>
            {" "}
            2. Iddera App sends your data to AppsFlyer, which analyzes it and
            provides us reports and insights on how to optimize our promotional
            campaigns.
          </h4>
          <h4>
            {" "}
            3. At the same time, AppsFlyer sends your data to some of its
            integrated partners (e.g. Pinterest, Google Ads, Apple Search Ads,
            FB marketing network, and a couple of others) to find you or people
            like you on different platforms, including social media websites
          </h4>
          <p>
            {" "}
            These integrated partners analyze your data and show relevant
            information about the App to people who might be potentially
            interested in it or remind you about revisiting the App if you
            stopped using it a while ago.
          </p>
          <h4> 4. This is how you and new users find out more about Iddera</h4>
          <p>
            {" "}
            Get accurate health predictions, learn about the meaning of their
            bodies’ cues and receive credible information about their health.
            You contribute to the growth of the Iddera community by providing
            your consent to use the Iddera app. Read more about AppsFlyer{" "}
            <a>here</a> and its integrated partners <a>here</a>.
          </p>
          <h4> 5. Opt-out Options</h4>
          <p>
            {" "}
            You can withdraw your consent or opt-out, whatever applies in your
            case, from sharing of your Personal Data in accordance with this
            subsection anytime by using one of the following options:
          </p>
          <ul>
            {" "}
            <li>
              By contacting us at{" "}
              <a href="mailto:support@iddera.com">support@iddera.com</a>;
            </li>
            <li>
              By adjusting your device settings in iOS or Android in order to
              stop sharing your IDFA or Android Advertising ID.
            </li>
          </ul>
          <h3> Processing to make the App run</h3>
          <p>
            We engage processors that perform particular operations with your
            Personal Data for us.
          </p>
          <p>
            Processors are companies that help us run the App, support our
            communication with you or perform other App-related activities. They
            may process certain Personal Data on our behalf to accomplish the
            goals related to the App functions and associated activities.
            Processors act only in accordance with our instructions and process
            only such amount of Personal Data as we instruct them to process. We
            remain fully liable for any acts or omissions of our processors and
            undertake to execute formal data processing agreements with them to
            the extent required by applicable law.
          </p>
          <button
            className={`legal__toggle-tbl-btn ${
              tableData["section-6"].hidden ? "" : "legal__toggle-tbl-btn--open"
            }`}
            onClick={() => toggleTable("section-6")}
          >
            <span>
              Click to {tableData["section-6"].hidden ? "show" : "hide"} our
              main processors
            </span>
            <WrappedIcon id="arrow-down" />
          </button>
          <div className="table-wrapper">
            {!tableData["section-6"].hidden && (
              <table>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Processor</th>
                    <th>Processor’s Privacy Policy</th>
                    <th>Data Collected</th>
                    <th>Purpose</th>
                  </tr>
                </thead>

                <tbody>
                  {tableData["section-6"]?.data.map((row, index) => (
                    <tr key={index}>
                      <td>{row.col1}</td>
                      <td>{row.col2}</td>
                      <td>{row.col3}</td>
                      <td>{row.col4}</td>
                      <td>{row.col5}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <h4> Aggregated Information</h4>
          <p>
            Sometimes we may aggregate, anonymize or de-identify your Personal
            data in such a way so that it cannot reasonably be used to identify
            you. Such data is no longer personal. We may share such data with
            our partners or research institutions. For example, we may share,
            including, without limitation, in articles, blog posts and
            scientific publications, general age demographic information and
            aggregate statistics about certain activities or symptoms from data
            collected to help identify patterns across users. Sharing such data
            contributes to the advancement of scientific research.
          </p>
          <h4>Information posted by you</h4>
          <p>
            The App features several community areas like Iddera Community where
            users with similar interests can share information and support one
            another.
          </p>
          <p>
            Any information (including Personal Data) you share in any online
            community area or online discussion is by design open to the App
            community. You should think carefully before posting any Personal
            Data in any public forum. What you post can be seen, disclosed to,
            or collected by third parties and may be used by others in ways we
            cannot control or predict, including to contact you for unauthorized
            purposes. Moreover, posting your Personal Data in Iddera Community
            may violate the Iddera Community Rules. If you mistakenly post
            Personal Data in our community areas and would like it removed, you
            can send us an email as listed below to request that we remove it.
          </p>
          <h4> Special circumstances</h4>
          <p>
            We may also share some of your Personal Data in the following
            special circumstances:
          </p>
          <ul>
            <li>
              In response to subpoenas, court orders or legal processes, to the
              extent permitted and as restricted by law (including to meet
              national security or law enforcement requirements);
            </li>
            <li>
              When disclosure is required to maintain the security and integrity
              of the App, or to protect any user’s security or the security of
              other persons, consistent with applicable laws. In such cases we
              may also delete some of your Personal Data (e.g., resetting your
              password to avoid unauthorized access);
            </li>
            <li>
              When disclosure is directed or consented to by the user who has
              input the Personal Data;
            </li>
            <li>
              In the event that we go through a business transition, such as a
              merger, divestiture, acquisition, liquidation or sale of all or a
              portion of its assets, your information will, in most instances,
              be part of the assets transferred
            </li>
          </ul>
        </>
      )
    },
    {
      heading: "Retention of your Personal Data",
      content: (
        <>
          <h4> When you use the App</h4>
          <p>
            {" "}
            We will retain your Personal Data as long as your account is active
            or needed to provide you Services, and only for as long as it serves
            purposes of processing identified in Section 2 of this Privacy
            Policy. At any time, you can erase your Personal Data in accordance
            with the Privacy Policy.
          </p>
          <h4> After you stop using the app</h4>
          <p>
            If you choose to delete the App or deactivate your account, or your
            account becomes inactive for a while, we will retain your Personal
            Data for a reasonable period in case you decide to re-activate the
            Services. The App covers different periods of users’ lifecycle;
            therefore, retention of your data is needed in some cases to secure
            your smooth experience with other App functions.
          </p>
          <p>
            You should be aware that we may retain certain Personal Data and
            other information after your account has been terminated or deleted
            as necessary to comply with legal obligations, resolve disputes and
            enforce our agreements.
          </p>
          <p>
            We might also keep some information that doesn't identify you to
            help improve our business and our services.
          </p>
          <p>
            In some circumstances, we might keep data longer if the law says we
            have to.
          </p>
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>Your Information</th>
                  <th>How long we keep it (its 'retention period')</th>
                </tr>
              </thead>

              <tbody>
                {tableData["section-7"]?.data.map((row, index) => (
                  <tr key={index}>
                    <td>{row.col1}</td>
                    <td>{row.col2}</td>
                  </tr>
                ))}
              </tbody>
            </table>{" "}
          </div>
        </>
      )
    },
    {
      heading: "Security of your Personal Data",
      content: (
        <>
          <h3>General security measures</h3>
          <p>
            {" "}
            We take all reasonable and appropriate measures to protect all
            Personal Data collected from loss, theft, misuse and unauthorized
            access, disclosure, alteration and destruction, taking into account
            the nature of the Personal Data that we process, and risks
            associated with special categories of Personal Data we collect
            (information about health). Among others, we utilize the following
            information security measures to protect your Personal Data:
          </p>
          <ul>
            <li>
              Pseudonymization and tokenization of certain categories of your
              Personal Data;
            </li>
            <li>Encryption of your Personal Data in transit and in rest;</li>
            <li>Systematic vulnerability scanning and penetration testing;</li>
            <li>Protection of data integrity;</li>
            <li>
              Organizational and legal measures. For example, our employees have
              different levels of access to your Personal Data and only those in
              charge of data management get access to your Personal Data and
              only for limited purposes required for the operation of the App.
              We impose strict liability on our employees for any disclosures,
              unauthorized accesses, alterations, destructions, misuses of your
              Personal Data.
            </li>
            <li>
              Conducting periodical data protection impact assessments in order
              to ensure that the App fully adheres to the principles of ‘privacy
              by design’, ‘privacy by default’ and others. We also commit to
              undertake a privacy audit in the event of the Company’s merger or
              takeover.
            </li>
          </ul>
          <p>
            Please understand that you can help keep your information secure by
            choosing and protecting your password appropriately, not sharing
            your password and preventing others from using your mobile device.
            Please understand that no security system is perfect and, as such,
            we cannot guarantee the absolute security of the App, or that your
            information will not be intercepted while being transmitted to us.
          </p>{" "}
          <h3> Security Breaches</h3>
          <p>
            If we learn of a security systems breach, we may either post a
            notice, or attempt to notify you by email and will take reasonable
            steps to remedy the breach as specified in applicable law and this
            Privacy Policy. If we learn of a potential Personal Data breach,
            together with other actions referred to in Section 3 of the Privacy
            Policy (such as notifying you in certain cases), we will also
            undertake particular actions to remedy the breach, including, but
            not limited to, logging you out from all the devices, resetting a
            password (sending a temporary password for you to apply) and
            performing other reasonably necessary activities and actions.
          </p>
          <p>
            If you want to report a security incident related to the App please
            contact us at{" "}
            <a href="mailto:security@iddera.com">security@iddera.com</a>.
          </p>
        </>
      )
    },
    {
      heading: "Children’s privacy",
      content: (
        <>
          <p>
            {" "}
            General age limitation. We are committed to protecting the privacy
            of children. The App is not intended for children and we do not
            intentionally collect information about children under 13 years old.
            The App does not collect Personal Data from any person the Company
            actually knows is under the age of 13. If you are aware of anyone
            under 13 using the App, please contact us at{" "}
            <a href="mailto:support@iddera.com">support@iddera.com</a> and we
            will take the required steps to delete such information and (or)
            delete their account.
          </p>{" "}
        </>
      )
    },
    {
      heading: "Communication with you",
      content: (
        <>
          {" "}
          <p>
            {" "}
            We may contact you from time to time via email or through other
            means (like popups or push notifications) to communicate with you
            about products, services, offers, promotions, rewards, and events
            offered by us and others, and provide news and information that we
            think will be of interest to you.
          </p>{" "}
          <h3>Opt-out options</h3>
          <p>
            You can always opt out of receiving emails by unsubscribing via the
            “Unsubscribe” link contained in the email. Opting-out of these
            emails or notifications will not end the transmission of important
            service-related emails that are necessary to your use of the App. If
            applicable laws prescribe so, we may ask some users to provide their
            consent for such communications.
          </p>
          <p>
            Please note that we may contact you with our information about
            products, services, offers, promotions, rewards, and events offered
            by us and others via third-party platforms (like social media). See
            more in section Processing to find new Iddera users and stay in
            touch with you. Please note that you can always opt-out from such
            communication and usage of your Personal Data by contacting us at{" "}
            <a href="mailto:support@iddera.com">support@iddera.com</a>.
          </p>
        </>
      )
    },
    {
      heading: "Storage and international Personal Data transfers",
      content: (
        <>
          <p>
            The Company is based in Nigeria, and Personal Data we collect is
            governed by Nigeria law. Please be advised that Nigeria law and laws
            of other countries may not offer the same protections as the law of
            your jurisdiction.
          </p>
          <p>
            In addition, you agree that Personal Data collected may be stored
            and processed in Canada and the United States, where the Company
            rents servers, or in any other country in which the Company or its
            affiliates, subsidiaries or agents maintain facilities, and by using
            the App, you consent to any such transfer of Personal Data outside
            of your country.
          </p>{" "}
        </>
      )
    },
    {
      heading: "Data Protection Officer (DPO)",
      content: (
        <>
          To communicate with our Data Protection Officer, please email at{" "}
          <a href="mailto:dpo@iddera.com">dpo@iddera.com</a> or use the contact
          details below.
        </>
      )
    }
  ];

  const toggleTable = (section) => {
    if (tableData[section].canHide) {
      const tableDataCopy = { ...tableData };
      const sectionDataCopy = {
        ...tableData[section],
        hidden: !tableData[section].hidden
      };
      tableDataCopy[section] = sectionDataCopy;
      setTableData(tableDataCopy);
    }
  };

  useEffect(() => {
    const section = new URLSearchParams(location.search).get("section");
    const validSection =
      section && section >= 0 && section <= sections.length + 1;

    if (validSection) {
      window.scroll({
        top: sectionRefs.current[section - 1].offsetTop,
        behavior: "smooth"
      });
    }
  }, [location.search]);

  return (
    <LegalLayout
      heading={<>You are human. Take control of your privacy.</>}
      topImg={lock}
      topFog={<div className="top-fog top-fog--legal-pp" />}
      tableOfContent={sections.map((section) => section.heading)}
      setContactSectionRef={(el) => (sectionRefs.current[sections.length] = el)}
    >
      <>
        {sections.map((section, index) => (
          <section
            className="legal__doc-section"
            ref={(el) => (sectionRefs.current[index] = el)}
          >
            <h2>
              {index + 1}. {section.heading}
            </h2>
            {section.content}
          </section>
        ))}
      </>
    </LegalLayout>
  );
};

export default PrivacyPolicy;
