import React from "react";
import openQuote from "assets/icons/citation-open.svg";
import closeQuote from "assets/icons/citation-close.svg";
import Fade from "react-reveal/Fade";

const Quote = () => {
  return (
    <div className="faqp__quote max-page-width">
      <div className="faqp__quote-content">
        <Fade bottom>
          <img src={openQuote} alt="" className="faqp__quote-img" />
          <p className="faqp__quote-text">
            Your Health is a relationship between your body, mind and spirit.
          </p>
          <img src={closeQuote} alt="" className="faqp__quote-img" />
        </Fade>
      </div>
    </div>
  );
};

export default Quote;
