import React from "react";
import Fade from "react-reveal/Fade";
import MlpCard from "./MlpCard";

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero__top max-page-width">
        <Fade bottom>
          <h1 className="hero__heading">
            Your personal physician anytime anywhere.
          </h1>
          <p className="hero__subheading">
            Iddera is a digital-first hospital for your healthcare needs.
          </p>
        </Fade>
      </div>
      <main>
        <Fade bottom>
          <h2 className="mlp__cards-heading">
            <span className="mlp__cards-heading-txt">
              What would you like to do today?{" "}
            </span>
            <span className="mlp__cards-heading-emoji">🙂</span>
          </h2>
          <div className="mlp__cards max-page-width">
            <MlpCard type="consultation" />
            <MlpCard type="learn" />
          </div>
        </Fade>
      </main>
    </div>
  );
};

export default Hero;
