import React, { useEffect, useRef, useState } from "react";
import logo from "assets/logos/full-logo-primary.svg";
import logoSecondary from "assets/logos/full-logo-secondary.svg";
import programs from "data/programs.json";
import { Link, NavLink, useLocation } from "react-router-dom";
import WrappedIcon from "components/WrappedIcon";
import MobileNav from "./MobileNav";
import Icon from "components/Icon";
import Button from "components/Button";
import useBlur from "hooks/useBlur";

const navLinks = [
  {
    name: "About",
    route: "/about"
  },
  {
    name: "Membership",
    route: "/membership"
  },
  {
    name: "Programs",
    route: "/programs",
    isDropdown: true,
    dropdownTitle: "DOCTOR LED PROGRAMS",
    dropdownLinks: programs
  },
  {
    name: "Learn",
    route: "/learn"
  },
  {
    name: "FAQs",
    route: "/faqs"
  }
];

let lastScroll = 0;
const body = document.body;
const scrollUp = "scroll-up";
const scrollDown = "scroll-down";

const Header = ({ secondaryLogo }) => {
  const location = useLocation();
  const dropdownRef = useRef(null);

  const [showNav, setShowNav] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(undefined);

  useBlur(dropdownRef, () => setActiveDropdown(undefined));

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setShowNav(false);
    setActiveDropdown(undefined);
  }, [location.pathname + location.search]);

  const handleScroll = () => {
    setActiveDropdown(undefined);

    const currentScroll = window.pageYOffset;
    if (currentScroll <= 0) {
      body.classList.remove(scrollUp, scrollDown);
      return;
    }

    if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
      // handle scroll down
      body.classList.remove(scrollUp);
      body.classList.add(scrollDown);
    } else if (
      currentScroll < lastScroll &&
      body.classList.contains(scrollDown)
    ) {
      // handle scroll up
      body.classList.remove(scrollDown);
      body.classList.add(scrollUp);
    }
    lastScroll = currentScroll;
  };

  const handleDropdownClick = (index) => {
    if (index === activeDropdown) setActiveDropdown(undefined);
    else setActiveDropdown(index);
  };

  return (
    <>
      <header className="header">
        <div className="header__content max-page-width">
          <Link to="/">
            <img
              src={secondaryLogo ? logoSecondary : logo}
              alt="Iddera"
              className="header__logo"
            />
          </Link>
          <nav className="nav">
            {navLinks.map((link, index) =>
              link.isDropdown ? (
                <span
                  ref={dropdownRef}
                  key={index}
                  className={`nav__link nav__link--dropdown ${
                    activeDropdown === index ? "nav__link--dropdown-active" : ""
                  } ${
                    location.pathname.startsWith(link.route)
                      ? "nav__link--active"
                      : ""
                  }`}
                >
                  <span
                    onClick={() => handleDropdownClick(index)}
                    role="button"
                  >
                    {link.name}
                    <Icon id="arrow-down" width={12} height={12} />
                  </span>
                  <div className="nav__dlink-body">
                    <h2 className="nav__dlink-title">{link.dropdownTitle}</h2>
                    <div className="nav__dlinks">
                      {link.dropdownLinks.map((dlink, index) => (
                        <Link
                          to={dlink.route}
                          key={index}
                          className="nav__dlink"
                        >
                          <span className="nav__dlink-text">{dlink.name}</span>
                        </Link>
                      ))}
                    </div>
                  </div>
                </span>
              ) : (
                <NavLink
                  key={index}
                  className="nav__link"
                  to={link.route}
                  activeClassName="nav__link--active"
                >
                  {link.name}
                </NavLink>
              )
            )}
          </nav>
          <div className="header__actions">
            {/* <Link to="/" className="header__action-link">
              Sign In
            </Link>
            <Button
              label="Join Iddera"
              className="header__action-btn"
              variant="cornflower-blue"
            /> */}
            <Button
              label="Download Iddera"
              className="header__action-btn"
              variant="cornflower-blue"
            />
          </div>
          <button className="header__menu-btn" onClick={() => setShowNav(true)}>
            <WrappedIcon id="hamburger" className="header__menu-cont" />
          </button>
        </div>
      </header>
      <MobileNav isVisible={showNav} closeNav={() => setShowNav(false)} />
    </>
  );
};

export default Header;
