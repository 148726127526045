import Tag from "components/Tag";
import React from "react";
import { useHistory } from "react-router";
import Fade from "react-reveal/Fade";

const Program = ({ program, programRef }) => {
  const history = useHistory();

  return (
    <div
      className="prgp__program"
      role="alert"
      ref={programRef}
      style={{
        backgroundColor: program.bgColor,
        backgroundImage: `url(${program.bgImage})`
      }}
    >
      <div
        className="prgp__program-overlay"
        style={{
          backgroundColor: program.overlayBgColor,
          opacity: program.overlayOpacity
        }}
      />
      <div className="prgp__program-content max-page-width">
        <Fade bottom>
          <div>
            <Tag
              className="prgp__program-tag"
              style={{ backgroundColor: program.tagBgColor }}
            >
              {program.name}
            </Tag>
          </div>

          <div className="prgp__program-texts">
            <div className="prgp__program-left">
              <h2 className="prgp__program-heading">{program.summary} </h2>
              <button
                className="prgp__program-btn"
                onClick={() => history.push("/")}
              >
                Get Started
              </button>
            </div>
            <div className="prgp__program-right">
              <p className="prgp__program-desc">{program.desc}</p>
              <button
                className="prgp__program-btn"
                onClick={() => history.push("/")}
              >
                Get Started
              </button>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Program;
