import Icon from "components/Icon";
import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

const Faqs = () => {
  const [faqs, setFaqs] = useState({});
  const faqHeadings = Object.keys(faqs);

  useEffect(() => {
    const faqGroups = {
      Membership: [
        {
          active: false,
          question: "Why is the membership an annual plan?",
          answer: (
            <>
              Meaningful, lasting changes in health and lifestyle take time.
              Because of that, your Iddera membership is annual. We believe this
              gives you, your doctor, and your Care Team the necessary time
              together to build a lasting relationship and provide you with the
              support you need to reach your health goals.
            </>
          )
        },
        {
          active: false,
          question: "How does billing work?",
          answer: (
            <>
              <p>
                You will only be charged on the day you sign up for your
                membership. You will then be charged annually for your
                membership, on a go forward basis, on the same day each
                subsequent year. The annual fee includes all the services we
                provide.{" "}
                <span className="txt-highlight">
                  <Link to="/membership">
                    See what isn’t included in my Iddera membership.
                  </Link>
                </span>
              </p>
              <p>
                Free-trial plan users will be charged for premium services they
                consent to consume on a Pay-As-You-Go basis.
              </p>
              <p>
                We will never charge you or send you an unexpected bill for a
                service we perform. If a member needs additional tests or
                procedures that are not included in membership, then we will
                work to help the member try to find other options that fits
                their needs. If the member has insurance, that typically means
                that we coordinate with their insurance provider to facilitate
                specialist referrals, prescriptions, or emergency care within
                their provider network. Our membership model can help bring
                predictability to spending on primary care. It is, however, very
                important to understand that an iddera membership is not health
                insurance and is not a substitute for comprehensive health
                insurance. We encourage any person (including our members) who
                can afford it to maintain a separate comprehensive health
                insurance plan of their choosing.
              </p>
            </>
          )
        },
        {
          active: false,
          question: "What isn't included in Iddera membership?",
          answer: (
            <>
              <p>
                We're a primary care provider with staff physicians trained in
                family or internal medicine, so our offerings are geared toward
                addressing many of your day-to-day needs, while also helping you
                avoid issues, identify problems early, and control chronic
                conditions before they become more expensive to treat.
                Membership also includes all consultations you have with Iddera
                – at our in-person, virtually, and in the app. Services that are
                not part of our stated offerings are not included in your Iddera
                membership. These are things like:
              </p>

              <ul>
                <li>Imaging (MRIs, x-rays, CAT scans)</li>
                <li>
                  Services requiring referral to a specialist (Cardiologist,
                  Allergist)
                </li>
                <li>Elective or planned hospitalisations</li>
                <li>Urgent and emergency care, including hospitalisations</li>
                <li>
                  Prescriptions for ongoing needs and those not included in
                  membership (that is, other than common generics kept
                  in-clinic)
                </li>
                <li>
                  Ongoing treatment for severe depression, anxiety, substance
                  use, and other mental health needs
                </li>
                <li>
                  Lab testing not included in membership (that is, other than
                  common tests performed in-clinic)
                </li>
                <li>
                  The scope of care is specified in your Patient Agreement,
                  found at{" "}
                  <span className="txt-highlight">
                    <Link to={"/terms"}>iddera.com/terms</Link>
                  </span>
                  . Our Care Team is always available to answer your questions
                  about which services are included in iddera membership.{" "}
                </li>
              </ul>
              <p>
                The Care Team will always let you know if they recommend
                services not included in your membership and will work with you
                to anticipate and decrease out-of-pocket costs.
              </p>
            </>
          )
        },
        {
          active: false,
          question: "How about my privacy?",
          answer: (
            <>
              We’ve designed Iddera to give you access to more information so
              you can feel in control of your health. That approach extends to
              include control over your personal health information. Your data
              belongs to you. We do not sell your personal information, and we
              encrypt all health data to safeguard your privacy. We employ
              physical, technical, and administrative controls to protect your
              personal information. You can learn more about Iddera’s use and
              protection of personal information by reading the{" "}
              <span className="txt-highlight">
                <Link to={"/privacy"}>privacy policy</Link>
              </span>{" "}
              and{" "}
              <span className="txt-highlight">
                <Link to={"/terms"}>terms of use</Link>
              </span>
              .
            </>
          )
        }
      ],
      General: [
        {
          active: false,
          question: "Do I always work with the same Iddera doctor?",
          answer: (
            <>
              <p>
                At the beginning of your membership, you will select your
                primary care doctor. Together you and your doctor complete your
                baseline assessment to establish your goals and personalised
                plan. Throughout the membership, you’ll have an opportunity to
                build a relationship with your doctor through shared
                decision-making based on trust and transparency.
              </p>
              <p>
                You are always able to get support from other Iddera physicians,
                whether you are traveling around the country or simply want a
                second opinion. You’re also able to communicate with other
                physicians and medical practitioners through the messaging
                feature available 24/7 in the Iddera app.
              </p>
            </>
          )
        },
        {
          active: false,
          question:
            "Does Iddera have imaging, like scans, x-rays, MRIs and so on?",
          answer: (
            <>
              While we don't currently provide imaging services, your Iddera
              doctor is able to order specialist services like imaging, when
              necessary. Imaging is performed outside of Iddera, and so is not
              included as part of your Membership. In those cases, you are
              responsible for paying the outside specialist. If you have health
              insurance, for instance, the service is billed through that
              insurance.
            </>
          )
        },
        {
          active: false,
          question:
            "What happens if I need a specialist referral or hospitalisation?",
          answer: (
            <>
              <p>
                We are a primary care provider with staff physicians trained in
                family or internal medicine. Services that are not part of our
                stated offerings, such as specialist referrals and
                hospitalisations, are not included in your Iddera membership.
              </p>
              <p>
                In the event of elective or planned hospitalisation or
                specialist visits, we'll work with you to refer you to another
                provider that meets your needs.  If you have insurance, that
                usually means referring you to a provider in your insurance
                network.
              </p>
              <p>
                In these cases, you will be responsible for the cost for the
                services, since they are not included in your Iddera membership.
                These costs will be routed through your insurance or billed to
                you directly at the lowest out-of-pocket cost available.
              </p>
              <p>
                In these cases when you need services that are not included in
                your Iddera membership, we continue to serve you in various
                ways. We take care of administrative tasks such as records
                transfer, referrals, and care coordination on your behalf, and
                we use our doctors’ local medical network to get you the care
                you deserve.
              </p>
              <p>
                Our Care Team will always let you know if they recommend
                services not included in the membership and will work with you
                to anticipate and decrease out-of-pocket costs in case you need
                additional services (e.g., specialists, hospitalisations,
                prescriptions, labs, imaging).
              </p>
            </>
          )
        },
        {
          active: false,
          question: "How does Iddera work with prescriptions?",
          answer: (
            <>
              <p>
                We offer certain generic medications. First fills of medications
                for health conditions such as high blood pressure, high
                cholesterol, diabetes, asthma, allergies, flu, and issues
                requiring antibiotics are included in the Iddera membership.
              </p>
              <p>
                Any prescriptions for medications for recurring needs (beyond
                the first fill) are not included in your Iddera membership. In
                these cases, you will be responsible for their cost.  If you
                have insurance, this usually means having the prescription
                coordinated and billed through your insurance, especially when
                prior authorizations are required.
              </p>
              <p>
                Our Care Team will always let you know if they recommend
                services not included in the membership and will work with you
                to anticipate and decrease out-of-pocket costs in case you need
                additional services (e.g., specialists, hospitalisations,
                prescriptions, labs, imaging).
              </p>
            </>
          )
        },
        {
          active: false,
          question: "Does Iddera offer pap smears?",
          answer: (
            <>
              Regular pap smears are an important preventive screening that we
              provide. Our doctors will review your gynecological health in
              detail with you and make personalised recommendations regarding
              the frequency of pap smear testing that is right for you.
            </>
          )
        }
      ]
    };
    setFaqs(faqGroups);
  }, []);

  const handleFaqClick = (group, index) => {
    const groupCopy = [...faqs[group]];
    groupCopy[index].active = !groupCopy[index].active;
    setFaqs({ ...faqs, [group]: groupCopy });
  };

  return (
    <div className="faqp__content max-page-width">
      <Fade bottom>
        <div className="faqp__top">
          <h1 className="faqp__heading"> Frequently asked questions </h1>
          <p className="faqp__subheading">
            Still got doubts, let’s clear them!
          </p>
        </div>
      </Fade>
      {faqHeadings.map((heading) => (
        <Fade bottom key={heading}>
          <div className="faqp__group">
            <h2 className="faqp__group-heading"> {heading} </h2>
            <ul className="faqp__cards">
              {faqs[heading].map((faq, index) => (
                <li
                  key={index}
                  className={`faqp__card ${
                    faq.active ? "faqp__card--active" : ""
                  }`}
                >
                  <div
                    className="faqp__card-top"
                    onClick={() => handleFaqClick(heading, index)}
                  >
                    <h4 className="faqp__question">{faq.question}</h4>
                    <div className="faqp__icon-wrapper flx-center">
                      <Icon
                        id={faq.active ? "minus" : "plus"}
                        className="faqp__icon"
                        width={14}
                        height={14}
                      />
                    </div>
                  </div>
                  <div className="faqp__card-body">
                    <div className="faqp__answer">{faq.answer}</div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </Fade>
      ))}
    </div>
  );
};

export default Faqs;
