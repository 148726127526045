import React, { useState } from "react";
import LearnCard from "./LearnCard";
import Fade from "react-reveal/Fade";

const LearnSection = ({ section }) => {
  const [truncated, setTruncated] = useState(true);

  if (!section.posts.length)
    return (
      <section className="lrnp__section max-page-width">
        <h2 className="lrnp__section-heading"> {section.name}</h2>
        <p style={{ margin: "2pc 28px 0" }}> Coming soon!</p>
      </section>
    );

  return (
    <section className="lrnp__section max-page-width">
      <Fade bottom>
        <h2 className="lrnp__section-heading"> {section.name}</h2>
        <div className="lrnp__section-cards">
          {(truncated ? section.posts.slice(0, 6) : section.posts).map(
            (post, index) => (
              <LearnCard card={post} key={index} />
            )
          )}
        </div>
      </Fade>
      {truncated && section.posts.length > 6 && (
        <div className="lrnp__section-btn-wrapper">
          <button
            className="lrnp__section-btn"
            onClick={() => setTruncated(false)}
          >
            See more
          </button>
        </div>
      )}
    </section>
  );
};

export default LearnSection;
