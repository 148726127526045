import React from "react";

const PostSummary = ({ image, name, date, duration }) => {
  return (
    <div className="lrnp__post-summary">
      <img src={image} alt="Author" className="lrnp__post-author" />
      <div className="lrnp__post-summary-mid">
        <p className="lrnp__post-author-name"> {name}</p>
        <p className="lrnp__post-date"> {date} </p>
      </div>
      <div className="lnrp__post-duration">{duration} </div>
    </div>
  );
};

export default PostSummary;
