import Icon from "components/Icon";
import Tag from "components/Tag";
import React from "react";
import { useHistory } from "react-router";
import Fade from "react-reveal/Fade";
import Button from "components/Button";

const benefits = [
  {
    icon: "app",
    title: "Comprehensive App"
  },
  {
    icon: "stethoscope",
    title: "Unlimited Doctor Visits"
  },
  {
    icon: "chat",
    title: "24/7 Messaging"
  },
  {
    icon: "male",
    title: "Men’s Health"
  },
  {
    icon: "female",
    title: "Women’s Health"
  },
  {
    icon: "mental-health",
    title: "Mental Health & Support"
  },
  {
    icon: "syringe",
    title: "Blood Testing (Home Service)"
  },
  {
    icon: "prescription",
    title: "Prescription Delivery"
  },
  {
    icon: "heartbeat",
    title: "Biometrics Monitoring"
  },
  {
    icon: "microscope",
    title: "Genetic Analysis"
  },
  {
    icon: "vial",
    title: "Vaccines"
  }
];

const MembershipBenefits = () => {
  const history = useHistory();

  return (
    <div className="mbenefits">
      <div className="mbenefits__content max-page-width">
        <Fade bottom>
          <div className="mbenefits__left">
            <Tag className="mbenefits__tag"> Membership Benefits </Tag>
            <h2 className="mbenefits__heading"> How much we care </h2>
            <Button
              label="Learn more about benefits"
              className="mbenefits__btn mbenefits__btn--left"
              onClick={() => history.push("/membership")}
              variant="dark-purple"
            />
          </div>
          <div className="mbenefits__right">
            <p className="mbenefits__desc">
              Your needs come first. We are reimagining healthcare to focus on
              you and not the industry or your illness. It is healthcare the way
              you want it; designed around your life.
            </p>
            <Button
              label="Learn more about benefits"
              className="mbenefits__btn mbenefits__btn--right"
              onClick={() => history.push("/membership")}
              variant="dark-purple"
            />
          </div>
        </Fade>
      </div>
      <div className="mbenefits__slider">
        {[].concat(benefits, benefits, benefits).map((benefit, index) => {
          const isDuplicate = index + 1 > benefits.length;
          return (
            <div
              key={index}
              className={`mbenefits__slider-unit ${
                isDuplicate ? "mbenefits__slider-unit--hide" : ""
              }`}
              aria-hidden={isDuplicate}
            >
              <Icon
                id={benefit.icon}
                className="mbenefits__slider-icon"
                fill="white"
              />
              <span id="syringe" className="mbenefits__slider-text">
                {benefit.title}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MembershipBenefits;
