import Button from "components/Button";
import ListItemCard from "components/ListItemCard";
import React from "react";
import Fade from "react-reveal/Fade";
import { useHistory } from "react-router-dom";
import programs from "data/programs.json";

const HealthPrograms = () => {
  const history = useHistory();
  return (
    <div className="hprograms">
      <div className="hprograms__content max-page-width">
        <Fade bottom>
          <div className="hprograms__top">
            <h2 className="hprograms__heading">Doctor-led programs</h2>
            <p className="hprograms__subheading">
              A dedicated team of licensed doctors committed to your ongoing
              care and health goals.
            </p>
          </div>
          <ul className="hprograms__list">
            {programs.map((program) => (
              <ListItemCard
                text={program.name}
                key={program.name}
                className="cursor-pointer"
                onClick={() => history.push(program.route)}
                role="link"
              />
            ))}
          </ul>
          <Button
            className="hprograms__btn"
            onClick={() => history.push("/programs")}
            label="Learn more"
            variant="cornflower-blue"
          />
        </Fade>
      </div>
    </div>
  );
};

export default HealthPrograms;
