import React from "react";
import Fade from "react-reveal/Fade";

const Subscribe = () => {
  return (
    <div className="lrnp__subscribe max-page-width">
      <Fade bottom>
        <h2 className="lrnp__subscribe-heading">
          {" "}
          Subscribe to our newsletter{" "}
        </h2>
        <p className="lrnp__subscribe-subheading">
          Get the latest posts delivered to your email inbox.
        </p>

        <form className="lrnp__form">
          <input
            id="get-started"
            className="lrnp__input"
            placeholder="johndoe@iddera.com"
            aria-label="Enter your email to subscribe to our n ewsletter"
          />
          <button
            className="lrnp__subscribe-btn"
            onClick={(e) => e.preventDefault()}
          >
            Subscribe
          </button>
        </form>
      </Fade>
    </div>
  );
};

export default Subscribe;
