import React from "react";
import scribbleArrow from "assets/icons/scribble-arrow.svg";

const MostPopular = () => {
  return (
    <div className="prp__pop-wrapper">
      <img src={scribbleArrow} alt="" className="prp__pop-img" />
      <span className="prp__pop-text prp__multicolor-text"> Recommended </span>
    </div>
  );
};

export default MostPopular;
