import React from "react";
import logo from "assets/logos/full-logo-primary.svg";
import { Link } from "react-router-dom";
import Button from "components/Button";

const Header = () => {
  return (
    <>
      <header className="mlp-header">
        <div className="mlp-header__content max-page-width">
          <Link to="/">
            <img src={logo} alt="Iddera" className="mlp-header__logo" />
          </Link>
          <div className="mlp-header__actions">
            <p className="btn mlp-header__action-btn"> Beta </p>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
