import React from "react";
import Tag from "components/Tag";
import { useHistory } from "react-router";
import { tagMapping } from "../content";
import { Link } from "react-router-dom";

const LearnCard = ({ card }) => {
  const history = useHistory();
  return (
    <Link to={`/learn/${card.id}`} className="lrnp__card">
      <div className="lrnp__card-img-wrapper">
        <img src={card.coverImg} alt={card.title} className="lrnp__card-img" />
      </div>
      <Tag className="lrnp__card-tag"> {tagMapping[card.tag]}</Tag>
      <h3 className="lrnp__card-title"> {card.title}</h3>
      <span className="lrnp__card-date">
        {card.postDate.format("MMMM D, YYYY")}
      </span>
    </Link>
  );
};

export default LearnCard;
