import React from "react";
import heroImg1 from "assets/images/about-hero-1.png";
import heroImg2 from "assets/images/about-hero-2.png";
import heroImg3 from "assets/images/about-hero-3.png";
import logoCard from "assets/logos/logo-card-small.png";
import Fade from "react-reveal/Fade";

const Hero = () => {
  return (
    <div className="ap-hero">
      <div className="ap-hero__content max-page-width">
        <Fade bottom>
          <div className="ap-hero__texts">
            <h1 className="ap-hero__main-heading">About us</h1>
            <h2 className="ap__heading ap-hero__sub-heading">
              We want to afford every human the wellbeing to pursue their
              aspirations.
            </h2>
          </div>
        </Fade>
        <div className="ap-hero__imgs">
          <div className="ap-hero__img-wrapper">
            <img src={heroImg1} className="ap-hero__img" alt="Doctor" />
            <img src={logoCard} className="ap-hero__img-card" alt="" />
          </div>
          <div className="ap-hero__img-wrapper">
            <img src={heroImg2} className="ap-hero__img" alt="Doctor" />
          </div>
          <div className="ap-hero__img-wrapper">
            <img src={heroImg3} className="ap-hero__img" alt="Doctor" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
