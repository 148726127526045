import GroupHeading from "components/GroupHeading";
import React from "react";
import { Header, JoinIddera, Footer } from "../Common";
import Doctors from "./components/Doctors";
import Hero from "./components/Hero";
import Philosophy from "./components/Philosophy";
import Team from "./components/Team";
import WithLove from "./components/WithLove";
import "./styles/main.css";
import "./styles/responsive.css";

const AboutPage = () => {
  return (
    <div className="webp ap">
      <div className="top-fog top-fog--ap" />
      <Header />
      <Hero />
      <div className="max-page-width">
        <GroupHeading headings={["Values", "Doctors", "Team"]} />
      </div>
      <Philosophy />
      <Doctors />
      <Team />
      <WithLove />
      <JoinIddera />
      <Footer />
    </div>
  );
};

export default AboutPage;
