import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

const LearnRadioList = ({
  name,
  onChange,
  value,
  containerClassName,
  options
}) => {
  const handleChange = (e) => {
    onChange && onChange(e.target.value);
  };

  return (
    <div className={`lrnp-radio-list ${containerClassName}`}>
      {options.map((option, index) => (
        <div>
          <input
            type="radio"
            name={name}
            id={`${name}-${index}`}
            onChange={handleChange}
            value={option.value}
            checked={value ? value === option.value : undefined}
            className="lrnp-radio__input"
          />
          <label
            htmlFor={`${name}-${index}`}
            className="lrnp-radio__label"
            key={index}
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default LearnRadioList;

LearnRadioList.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ).isRequired,
  containerClassName: PropTypes.string
};

LearnRadioList.defaultProps = {
  containerClassName: ""
};
