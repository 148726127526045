import React from "react";
import avatar1 from "assets/images/join-avatar-1.png";
import avatar2 from "assets/images/join-avatar-2.png";
import avatar3 from "assets/images/join-avatar-3.png";
import avatar4 from "assets/images/join-avatar-4.png";
import bubble1 from "assets/images/bubble-4.png";
import bubble2 from "assets/images/bubble-5.png";
import bubble3 from "assets/images/bubble-6.png";
import bubble4 from "assets/images/bubble-7.png";
import Button from "components/Button";

const bubbles = [bubble1, bubble2, bubble3, bubble2, bubble4, bubble3, bubble3];

const JoinIddera = () => {
  return (
    <div className="join-iddera">
      <div className="join-iddera__content">
        <>
          {bubbles.map((bubble, index) => (
            <img
              src={bubble}
              alt=""
              className="join-iddera__bubble"
              key={index}
            />
          ))}
        </>
        <h2 className="join-iddera__heading">
          Join humans who want to take charge of their health
        </h2>
        <Button
          label="Join Iddera Now"
          className="join-iddera__btn"
          variant="cornflower-blue"
        />
        <div className="join-iddera__avatar-cont">
          <img src={avatar1} alt="" className="join-iddera__avatar" />
        </div>
        <div className="join-iddera__avatar-cont">
          <img src={avatar2} alt="" className="join-iddera__avatar" />
        </div>
        <div className="join-iddera__avatar-cont">
          <img src={avatar3} alt="" className="join-iddera__avatar" />
        </div>
        <div className="join-iddera__avatar-cont">
          <img src={avatar4} alt="" className="join-iddera__avatar" />
        </div>
      </div>
    </div>
  );
};

export default JoinIddera;
