import React from "react";
import logoFaint from "assets/logos/logo-faint.svg";
import logo from "assets/logos/full-logo-primary.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__logo-cont max-page-width">
        <Link to="/">
          <img src={logo} alt="Iddera" className="footer__logo" />
        </Link>
        <div className="footer__actions">
          <p className="btn footer__action-btn"> Beta </p>
        </div>
      </div>
      <div className="footer__credits">
        <div className="footer__credits-content max-page-width">
          <p className="footer__credit">
            Our practitioners are members of the appropriate regulatory body
            (for example, doctors are members of the Medical and Dental Council
            of Nigeria). They are committed to clinical best practice and any
            related standards.
          </p>
          <p className="footer__credit">
            Our digital healthcare services are for information only. They are
            not designed to diagnose you or provide you with medical advice or
            treatment. They should never be used as a replacement for a
            qualified medical professional. They are there to give you
            information relating to your health and to let you know about
            general causes, risks, and possible next steps. They are not
            designed to be used instead of a healthcare professional or in an
            emergency.
          </p>
          <p className="footer__credit">
            The content on this website does not constitute a complete
            description of Iddera’s primary healthcare services. By using this
            website, you accept our Terms of Use and Privacy Policy. Google Play
            and the Google Play logo are trademarks of Google LLC. Apple, the
            Apple logo, and iPhone are trademarks of Apple Inc. registered in
            the U.S.
          </p>
          <p className="footer__credit">
            <span className="footer__credit--bold">
              {" "}
              © {new Date().getFullYear()} Iddera Life Solutions Ltd.
            </span>{" "}
            All rights reserved.
          </p>
          <img src={logoFaint} alt="Iddera" className="footer__credit-logo" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
