import React from "react";
import dayjs from "dayjs";
import avatar from "assets/images/avatar.png";
import postImg1 from "assets/images/learn-placeholder-1.png";
import postImg2 from "assets/images/learn-placeholder-2.png";

const date = new Date(2021, 7, 1);
const startDate = dayjs(date);

const posts = [
  {
    id: 1,
    tag: "weight",
    title: "We Move! Benefits of Physical Activity",
    coverImg: postImg1,
    intro:
      "Physical exercise is one of the best ways to get a healthy dose of feel-good hormones such as endorphins and other brain chemicals which help us feel happier, less anxious, and relaxed.",
    content: (
      <>
        <blockquote className="lrndp__bq">
          <p>
            Physical exercise is one of the best ways to get a healthy dose of
            feel-good hormones such as endorphins and other brain chemicals
            which help us feel happier, less anxious, and relaxed.
          </p>
        </blockquote>
        <p>
          Do you know the cheapest treatment, which is free, easy to take and
          does not require a doctor’s prescription but has the biggest impact on
          your health? Well, laughter is not the only best medicine, physical
          activity is too. Physical activity refers to any movement that makes
          your muscles work and requires your body to burn calories. It includes
          activities such as doing housework, shopping (not online shopping!),
          walking to work and doing exercise like playing a sport or going to
          the gym.
        </p>
        <p>
          People are considerably less active these days and this could be
          because technology has made life much easier so most of the manual
          activities have been replaced with devices. Also, a lot of activities
          are done while sitting such as watching television, office work,
          listening to music, going on social media and this has cut down the
          number of physical activities we do.
        </p>
        <p>
          Being active has been shown to have many benefits to the body and mind
          as well as reducing the risk of many diseases.
        </p>
        <div className="lrndp__post-img-wrapper">
          <img
            src={postImg1}
            alt="Lorep ipsum dolor sit amet consec adipscing elit"
            className="lrndp__post-img"
          />
        </div>
        <h2>MOOD</h2>
        <p>
          Do you need to destress after a stressful day, or do you feel a bit
          down and want to lift your mood? Exercising is a good way to do this.
          Exercise promotes the release of endorphins and other brain chemicals
          which help us feel happier, less anxious and relaxed. This effect is
          seen immediately and is not dependent on the intensity of the
          exercise. There may also be some improvement in physical appearance
          which boosts self-esteem and confidence.
        </p>
        <h2>REDUCES HEALTH RISKS</h2>
        <p>
          It is also a very important factor in preventing health conditions and
          managing diseases. Being active boosts your good cholesterol and
          reduced unhealthy lipids which allows for blood to flow smoothly and
          protects against hypertension and cardiovascular disease. It also
          reduces the risk stroke, type 2 diabetes and many cancers. It has also
          been found to increase the quality of life of people with these
          diseases.
        </p>
        <h2>WEIGHT MANAGEMENT</h2>
        <p>
          Physical activity plays a vital role in maintaining a health body
          weight, losing excess body weight and keeping the excess weight off.
          Weight gain occurs when there is an excess in the number of calories
          consumed from food and drinks compared to the number of calories the
          body uses up. Regular exercise also increases the body’s metabolic
          rate to burn more calories and facilitate weight loss.
        </p>
        <h2>STRENTHENS BONES AND MUSCLES</h2>
        <p>
          As people age, there is a reduction in bone density which puts them at
          risk of fractures and a reduction in muscle mass which may reduce
          mobility and increase the risk of falls. Exercise is very important in
          building and maintaining span muscles and bones. Different types of
          physical activity include aerobic, muscle strengthening, and weight
          bearing activities. These promote bone growth and strength and help
          reduce the risk of falls and fractures.
        </p>
        <h2>SKIN HEALTH</h2>
        <p>
          Are you looking to get your skin glowing? Regular moderate exercise
          can increase your body’s production of natural antioxidants, which
          help protect cells from damage by free radicals. Exercise also
          increases blood flow and causes skin cell adaptations that may delay
          the appearance of skin aging. This increase in blood flow may also
          occur in the hair follicles and stimulate hair growth.
        </p>
        <h2>BRAIN HEALTH AND COGNITION</h2>
        <p>
          Regular exercise can help improve your brain function, memory and
          thinking skills. It increases the flow of blood and oxygen to the
          brain and boosts the production of chemicals that promote brain
          growth. It improves cognitive function and reduces the risk of
          developing dementia. Physically active adults and older adults are
          likely to report having a better quality of life.
        </p>
        <h2>SLEEP</h2>
        <p>
          Exercise also helps you sleep better and feel more energized during
          the day. Increased levels of moderate to vigorous activity is
          associated with Improved sleep quality, deep sleep, taking less time
          to fall asleep, reduced daytime sleepiness and reduced frequency of
          use of medication to aid sleep. Physical activity tires you out,
          increasing your body’s need for sleep. However, don’t exercise too
          close to bedtime or you may be too energised to go to bed.
        </p>
      </>
    ),
    readTime: "5 mins",
    author: { name: "Claire Ojiaka, MD", avatar: avatar },
    relatedArticles: [4]
  },
  {
    id: 2,
    tag: "stress",
    title: "Coping with stress at work",
    coverImg: postImg1,
    intro:
      "In small doses, stress can be useful as it helps you to stay focused, alert and increases energy but when work stress becomes chronic it can take a toll on your physical and mental wellbeing",
    content: (
      <>
        <blockquote className="lrndp__bq">
          <p>
            In small doses, stress can be useful as it helps you to stay
            focused, alert and increases energy but when work stress becomes
            chronic it can take a toll on your physical and mental wellbeing
          </p>
        </blockquote>
        <p>
          Everyone who has a job would most likely have those moments where an
          email notification, phone call, slack message or looming deadline
          makes your heartbeat faster. Any job can have stressful elements even
          if you enjoy what you do. It is normal to feel some tension especially
          when you have a deadline approaching or are working on something
          challenging. In small doses, stress can be useful as it helps you to
          stay focused, alert and increases energy but when work stress becomes
          chronic it can take a toll on the physical and mental wellbeing. Since
          finding a stress-free job may be near impossible, it would be helpful
          to adopt some strategies to reduce stress at work.
        </p>
        <div className="lrndp__post-img-wrapper">
          <img
            src={postImg1}
            alt="Lorep ipsum dolor sit amet consec adipscing elit"
            className="lrndp__post-img"
          />
        </div>
        <h2>HOW STRESS AFFECTS WELLBEING</h2>
        <p>
          When exposed to stressful situations, the body automatically activates
          a ‘flight or fight’ response which is either to run away from the
          situation or stay back and fight it. This is part of an evolutionary
          mechanism to protect humans from harm through increasing awareness by
          raising the heart rate and increasing muscle tension. Chronic exposure
          to stress at work can however lead to burnout which significantly
          impairs the ability to activate that fight response and deal with
          stress. This increases the risk of anxiety and depression. It could
          also increase susceptibility to heart diseases and other conditions.
        </p>
        <p>
          <ul>
            <li>
              <p>
                <span className="lrndp__txt-bold">Identify stressors: </span>{" "}
                Keep a record of the situations which create the most stress and
                how you respond to them. Each time you feel stressed, keep track
                of it in your journal or use a stress tracker on your phone.
                Keeping a daily log will enable you to see patterns and common
                themes. You might notice subtle but persistent causes of stress,
                such as a long commute or an uncomfortable workspace.
              </p>
            </li>
            <li>
              <p>
                <span className="lrndp__txt-bold">Tackle the stressor: </span>{" "}
                Once you’ve identified the stressors, consider each situation
                and look for ways to change the circumstances that are causing
                it. If persistent back pain due to posture is reducing your
                productivity, using a chair with ergonomic support would be a
                step in resolving the issue.
              </p>
            </li>
            <li>
              <p>
                <span className="lrndp__txt-bold">Healthy response: </span> It
                is also important to develop healthy coping responses to stress
                triggers. Instead of turning to junk food and alcohol when we
                feel under pressure, taking a walk, exercising or yoga would be
                an excellent response.
              </p>
            </li>
            <li>
              <p>
                <span className="lrndp__txt-bold">
                  Develop time management skills:
                </span>{" "}
                Good time management would help reduce the chances of feeling
                under pressure or overwhelmed at work. You could do this by
                setting realistic expectations and deadlines, allotting time
                slots to tasks, ranking your tasks in order of priority and
                getting them done in that order.
              </p>
            </li>
            <li>
              {" "}
              <p>
                <span className="lrndp__txt-bold">
                  Get the perspective of others:
                </span>{" "}
                Talking to trusted colleagues, friends and family members may be
                helpful. They may be able to offer some insight or suggestions
                on how to cope with how you are feeling or any issues at work. A
                problem shared with the right people, is a problem half solved.
              </p>{" "}
            </li>
            <li>
              {" "}
              <p>
                <span className="lrndp__txt-bold">Recharge: </span> Take a few
                minutes of personal time during the day to recharge. You could
                watch a funny video or listen to some music in between meetings.
                It is important to have periods when you disconnect from work to
                recharge and return to pre-stress levels of functioning. Make
                use of your vacation days and take time off to relax and unwind
                so you feel reenergised when you go back to work.
              </p>{" "}
            </li>
            <li>
              <p>
                <span className="lrndp__txt-bold">Relax: </span> Relaxation
                helps to counter the effects of the fight or flight response
                through reducing muscle tension and anxiety. Meditation, deep
                breathing exercises, and mindfulness all work to calm your
                anxiety. Some of the ways to include mindfulness in your daily
                routine are:
                <ul className="ul--bulleted">
                  <li>
                    Pause for a few moments before starting your workday and set
                    your intention.
                  </li>
                  <li>
                    Download a meditation app you can use when feeling excessive
                    pressure at work or during your commute.
                  </li>
                  <li>
                    Schedule in a 5-minute break to try breathing exercises
                  </li>
                </ul>
              </p>
            </li>
            <li>
              <p>
                <span className="lrndp__txt-bold">Set boundaries: </span> You
                might easily burn out if you’re available to work round the
                clock. Establish some boundaries between your work life and your
                home life to avoid potential stress. That might mean making a
                rule not to check email from home in the evening, or not
                answering the phone during dinner.
              </p>
            </li>
          </ul>
        </p>
      </>
    ),
    readTime: "5 mins",
    author: { name: "Claire Ojiaka, MD", avatar: avatar },
    relatedArticles: [8]
  },
  {
    id: 3,
    tag: "general",
    title: "I've got my back",
    coverImg: postImg1,
    intro:
      "Don’t put your back into it. Using your back instead of your legs can lead to back pain. Anxiety is also a leading cause of back pain.",
    content: (
      <>
        <h2>I’VE GOT MY BACK – KEEPING MY SPINE HAPPY</h2>
        <blockquote className="lrndp__bq">
          <p>
            Don’t put your back into it. Using your back instead of your legs
            can lead to back pain. Anxiety is also a leading cause of back pain
            .
          </p>
        </blockquote>
        <p>
          {" "}
          Back pain is a very common problem most people experience as they get
          older that can impact their quality of life. Many occupations such as
          construction work and even office work can place significant demands
          on your back making it difficult to live at your best. Certain daily
          habits can help you maintain a healthy, pain-free back for a long
          time. Most of these steps involve practicing better body mechanics or
          moving and holding your body right when going about your daily
          activities.
        </p>
        <div className="lrndp__post-img-wrapper">
          <img
            src={postImg1}
            alt="Lorep ipsum dolor sit amet consec adipscing elit"
            className="lrndp__post-img"
          />
        </div>
        <h2>WHAT INCREASES MY CHANCES OF HAVING BACK PAIN?</h2>
        <p>
          Most low back pain is caused by mechanical factors meaning that there
          is a disruption in the way the components of the back fit together and
          move.
        </p>
        <p>
          Anyone can develop back pain, but certain factors increase the risk.
          <ul className="ul--bulleted">
            <li>
              <span className="lrndp__txt-bold">Age: </span> Back pain is more
              common as you get older, starting around age 30 or 40 due loss of
              spine strength and flexibility.
            </li>
            <li>
              <span className="lrndp__txt-bold">Lack of exercise: </span> Weak
              muscles in your back and abdomen may not properly support the
              spine.
            </li>
            <li>
              <span className="lrndp__txt-bold">Excess weight: </span> Excess
              body weight puts extra strain on your back and leads to low back
              pain.
            </li>
            <li>
              <span className="lrndp__txt-bold">Diseases: </span> Some types of
              arthritis and cancer can contribute to back pain.
            </li>
            <li>
              <span className="lrndp__txt-bold">Improper lifting: </span> Using
              your back instead of your legs can lead to back pain.
            </li>
            <li>
              <span className="lrndp__txt-bold">Mental health: </span> People
              prone to depression and anxiety appear to have a greater risk of
              back pain.
            </li>
            <li>
              <span className="lrndp__txt-bold">Smoking: </span> Smokers have
              increased rates of back pain. Smoking can restrict blood and
              oxygen flow to the spine causing them to degenerate faster.
            </li>
          </ul>
        </p>
        <h2> WHAT CAN I DO TO PREVENT BACK PAIN?</h2>
        <h3>LIFT RIGHT</h3>
        <p>
          Avoid lifting heavy objects, if possible, but if you must lift
          something heavy, let your legs do the work. Keep your back straight
          without twisting and bend only at the knees. Hold the load close to
          your body. Find a lifting partner if the object is heavy or awkward.
        </p>
        <h3>SIT AND STAND RIGHT</h3>
        <p>
          Good posture can reduce the stress on back muscles. Don't slouch.
          Choose a seat with good lower back support, armrests and a swivel
          base. Placing a pillow or rolled towel in the small of your back can
          provide some lumbar support. Keep your knees and hips level. Change
          your sitting position frequently, at least every half-hour, walk
          around or stretch gently to relieve tension in the back.
        </p>
        <h3>WORK RIGHT</h3>
        <p>
          Using ergonomic office chairs can reduce back or neck pain. Make sure
          your workspace, whether a laptop, phone or computer desk, is set up
          for your height and functionality. Do not cradle the phone between
          your neck and ear, instead put the phone to your ear or use a headset.
          Carrying a bulky laptop bag, suitcase or camera can also cause a
          strain on your back so try to carry less or distribute the weight to
          both sides of your body. Examine your work environment and address
          situations that might exert your back.
        </p>
        <h3>SLEEP RIGHT</h3>
        <p>
          Always sleep on a firm surface. Sleeping on one’s side with the knees
          drawn up can help open up the joints in the spine and relieve pressure
          by reducing the curvature of the spine.
        </p>
        <h3>EXERCISE</h3>
        <p>
          This keeps the muscles strong and flexible. Appropriate back and
          abdominal exercises increase strengthen and endurance in the back. It
          also helps in maintaining a healthy weight which will not put extra
          strain on the back. Doing some simple stretches can improve
          circulation to your back. It can also ease any strains or aches that
          occur due to inactivity.
        </p>
        <p>
          If you develop back pain, please don’t ignore it or self-medicate,
          speak to your doctor to get the correct treatment for the condition.
        </p>
      </>
    ),
    readTime: "5 mins",
    author: { name: "Claire Ojiaka, MD", avatar: avatar },
    relatedArticles: [7, 10, 6]
  },
  {
    id: 4,
    tag: "weight",
    title: "Increasing Physical Activity",
    coverImg: postImg1,
    intro:
      "Emerging evidence shows that sedentary behaviour, such as sitting or lying down for long periods, is bad for your health. ",
    content: (
      <>
        <p>
          Emerging evidence shows that sedentary behaviour, such as sitting or
          lying down for long periods, is bad for your health. Not only should
          you try to raise your activity levels, but you should also reduce the
          amount of time you and your family spend sitting down. Increased
          levels of physical inactivity have negative impacts on health systems,
          the environment, economic development, community well-being and
          quality of life.
        </p>
        <div className="lrndp__post-img-wrapper">
          <img
            src={postImg1}
            alt="Lorep ipsum dolor sit amet consec adipscing elit"
            className="lrndp__post-img"
          />
        </div>
        <p>
          {" "}
          It is recommended that adults should do:
          <ul className="ul--bulleted">
            <li>
              at least 150–300 minutes of moderate-intensity aerobic physical
              activity
            </li>
            <li>
              or at least 75–150 minutes of vigorous-intensity aerobic physical
              activity; or an equivalent combination of moderate- and
              vigorous-intensity activity throughout the week
            </li>
            <li>
              muscle-strengthening activities at moderate or greater intensity
              that involve all major muscle groups on 2 or more days a week, as
              these provide additional health benefits.
            </li>
            <li>
              increase moderate-intensity aerobic physical activity to more than
              300 minutes; or do more than 150 minutes of vigorous-intensity
              aerobic physical activity; or an equivalent combination of
              moderate- and vigorous-intensity activity throughout the week for
              additional health benefits.
            </li>
            <li>
              limit the amount of time spent being sedentary. Replacing
              sedentary time with physical activity of any intensity (including
              light intensity) provides health benefits.
            </li>
          </ul>
        </p>
        <p>
          Some tips to help increase your physical activity levels are:
          <ul className="ul--bulleted">
            <li>
              <span className="lrndp__txt-bold"> Set specific goals: </span> The
              first step is to make a decision to increase physical activity and
              then taking active steps to achieve this. You could set a goal to
              take 10,000 steps in a day and decide on how to do this which may
              be walking to and from the bus stop instead of driving.
            </li>
            <li>
              <span className="lrndp__txt-bold">
                {" "}
                Take breaks from sitting:
              </span>{" "}
              Due to office work, we spend more time sitting. Take regular
              breaks from sitting and walk around the office for a bit. This
              also helps you to get your steps in.
            </li>
            <li>
              <span className="lrndp__txt-bold"> Take the stairs: </span>{" "}
              Instead of using the lifts or elevators, try taking the stairs
              instead. This gets your heart pumping and is a good step in the
              right direction.
            </li>
            <li>
              <span className="lrndp__txt-bold"> Let friends join in: </span>{" "}
              The more the merrier. Exercising with friends or going on a run
              with a friend makes it more tolerable and provides some
              encouragement. You could also join a nearby sports team.
            </li>
            <li>
              <span className="lrndp__txt-bold"> Make it interesting: </span>{" "}
              Physical activity does not have to be a tedious affair. Put on
              some music and burn some calories while you dance. You could also
              try some dance and walking workouts on YouTube which are low
              intensity but a lot of fun and help you get your steps in.
            </li>
            <li>
              <span className="lrndp__txt-bold"> Move more in the home: </span>{" "}
              Try gardening and doing yard work.
            </li>
            <li>
              <span className="lrndp__txt-bold"> Try outdoor activities: </span>{" "}
              Instead of going to the cinema, you could try some outdoor
              activities such as hiking, riding a bike, going to the zoo,
              swimming.
            </li>
          </ul>
        </p>
      </>
    ),
    readTime: "5 mins",
    author: { name: "Claire Ojiaka, MD", avatar: avatar },
    relatedArticles: [1]
  },
  {
    id: 5,
    tag: "diabetes",
    title: "Living your best life with diabetes",
    coverImg: postImg1,
    intro:
      "There are millions of people living with diabetes and thus number keeps increasing every year. If you are newly diagnosed, know that you are not alone. ",
    content: (
      <>
        <p>
          There are millions of people living with diabetes and thus number
          keeps increasing every year. If you are newly diagnosed, know that you
          are not alone. Unlike the large number of people who have but are
          undiagnosed, you are a part of the diagnosed group. This gives you the
          opportunity to manage the situation and prevent complications. You
          deserve some accolades for being able to help your body do what it’s
          unable to do on its own. Keeping your blood sugar within the range
          recommended by your doctor may be challenging and inconveniencing
          sometimes but living a happy, healthy and fulfilling life is
          attainable and these tips would help you achieve that.
        </p>
        <div className="lrndp__post-img-wrapper">
          <img
            src={postImg1}
            alt="Lorep ipsum dolor sit amet consec adipscing elit"
            className="lrndp__post-img"
          />
        </div>
        <ul>
          <li>
            <span className="lrndp__txt-bold">EAT WELL: </span> Eating healthy
            is a central part of living healthy. It is important to know how
            meals can affect your blood sugar levels. This involves the
            quantify, type and combination of food. Measuring cups and scales
            will be instrumentals in ensuring the proper portion sizes. Well
            balanced meals which are low in carbohydrate and high in fiber help
            keep the blood sugar more stable. Talk to your doctor or dietitian
            about appropriate food choices.
          </li>
          <li>
            <span className="lrndp__txt-bold">EXERCISE: </span> This causes the
            muscles to take up sugar from the blood and use it for energy.
            Physical activity helps the body to use insulin efficiently. This
            could range from brisk walks to biking to working out at the gym.
            Tracking your steps and competing against yourself to get in more
            steps every day would also be beneficial.
          </li>
          <li>
            <span className="lrndp__txt-bold">MONITOR BLOOD SUGAR: </span> Check
            your blood sugar levels regularly. The glycated hemoglobin test
            would also give you an idea of your blood sugar control in the
            previous 3 months. Review your results regularly with your doctor.
            Regular health checks are also valuable in preventing any
            complications and maintaining health.
          </li>
          <li>
            <span className="lrndp__txt-bold">MANAGE STRESS: </span> When under
            stress, the body produces some hormones which cause an increase in
            blood sugar. The extra pressure may also make it difficult to follow
            your diabetes care plan. Keep stress at bay by using relaxation
            techniques, yoga, music, laughter or talk therapy.
          </li>
          <li>
            <span className="lrndp__txt-bold">FOOT CARE: </span> Diabetes may
            lead to foot injuries. It may also prevent you from feeling them and
            prevent them from healing properly. Always wear protective footwear
            and avoid tight shoes. Check your foot regularly for any injuries
            and if they aren’t healing well, report them to your doctor.
          </li>
          <li>
            <span className="lrndp__txt-bold">TALK ABOUT IT: </span> Joining a
            support group provides some encouragement and shows that you are not
            alone. You are also able to get some tips that makes living a full
            and healthy life a reality.
          </li>
          <li>
            <span className="lrndp__txt-bold">SET GOALS: </span> Set specific
            goals and find ways to motivate you achieve them. It is also
            important to continue doing things you enjoy such as going swimming,
            shopping with friends, going to the movies.
          </li>
          <li>
            <span className="lrndp__txt-bold">KEEP LEARNING: </span> Seek out
            more information about the condition as improves your ability to
            manage the condition.
          </li>
        </ul>
      </>
    ),
    readTime: "5 mins",
    author: { name: "Claire Ojiaka, MD", avatar: avatar },
    relatedArticles: [6, 9]
  },
  {
    id: 6,
    tag: "weight",
    title: "Myths and facts about healthy eating",
    coverImg: postImg1,
    intro:
      "There is an abundance of information out in circulation when it comes to the topic of nutrition and health. Especially in this age of social media and WhatsApp professors there are lots of beliefs being circulated without facts to back them up. Some of these are false or only partially true. ",
    content: (
      <>
        <p>
          There is an abundance of information out in circulation when it comes
          to the topic of nutrition and health. Especially in this age of social
          media and WhatsApp professors there are lots of beliefs being
          circulated without facts to back them up. Some of these are false or
          only partially true. This sets out to help corrects some popular
          misconceptions about healthy eating.
        </p>
        <div className="lrndp__post-img-wrapper">
          <img
            src={postImg1}
            alt="Lorep ipsum dolor sit amet consec adipscing elit"
            className="lrndp__post-img"
          />
        </div>
        <h2>CARBS ARE BAD</h2>
        <p>
          Carbohydrates do not cause weight gain. You are only likely to gain
          weight when you eat more than you require. Foods with complex carbs
          such as whole grains and beans contain vital nutrients, vitamins and
          fiber which the body needs while simple carbs in foods like sugary
          snacks and drinks do not. It is advisable to cut down on the simple
          carbs and keep the right amount of complex carbs in your meals.
        </p>

        <h2>TEAS AND JUICE CLEANSES DETOXIFY THE BODY</h2>
        <p>
          There are so many teas and products being marketed as detoxifying. The
          organs in the body such as the liver and kidneys do that work already
          at no cost. The body doesn’t require any external help to do this. It
          would be more beneficial if the money could be spent on real fruits
          and vegetables.
        </p>

        <h2>EGGS ARE BAD</h2>
        <p>
          For a long time, eggs have been given such bad reputation as the S.I
          unit of cholesterol. While eggs contain some cholesterol, it mostly
          raises the good cholesterol which reduces the risk of heart disease
          and stroke. Eggs are a rich source of proteins and nutrients keeping
          you satisfied and full for longer. It is good for the eyes and brain
          but should still be consumed in moderation. An egg a day is just
          right.
        </p>

        <h2>CALORIES ARE BAD</h2>
        <p>
          The body needs calories to survive. Although too many calories can
          cause weight gain, the right number of calories gives you the fuel you
          need to power through your day. The quality of the calories is as
          important as the quantity.
        </p>

        <h2>HEALTHY MEANS VEGETARIAN ONLY: </h2>
        <p>
          Although a vegetarian eating plan with a low-fat content may be
          helpful for weight loss as it would contain less calories,
          vegetarians—like nonvegetarians—can make food choices that contribute
          to weight gain, like eating large amounts of high-fat, high-calorie
          foods, or foods with little or no nutritional value. It’s more about
          the food you eat than the meals you don’t.
        </p>

        <h2>LOW SUGAR, LOW FAT AND DIET OPTIONS ARE ALWAYS BETTER</h2>
        <p>
          Low sugar or low fat does not always mean calorie-free. In fact,
          sometimes they may contain more calories due to the added sugar,
          flour, or starch thickeners used to improve flavor and texture after
          fat is removed. Read the nutrition label to compare the calorie and
          fat contents of these products.
        </p>

        <h2>HONEY HAS LESS CALORIES THAN SUGAR</h2>

        <p>
          A tablespoon of honey contains more calories than a tablespoon of
          sugar. However, honey has a lower glycemic index meaning it does not
          raise blood sugar as fast as white sugar. It also contains more
          nutrients so may be a healthier choice compared to sugar but should
          still be consumed in moderation.
        </p>

        <h2>HERBAL AND NATURAL WEIGHT LOSS PRODUCTS ARE THE BEST</h2>

        <p>
          A weight-loss product that claims to be natural or herbal is not
          necessarily safe. These products are not usually scientifically tested
          to prove that they are safe or that they work. Discuss with your
          health-care provider before using any herbal products as some may be
          harmful.
        </p>

        <h2>SKIPPING MEALS IS A GOOD WAY TO LOSE WEIGHT</h2>

        <p>
          There is a greater chance of feeling much hungrier later and eating a
          large meal which replaces and exceeds all the calories you skipped
          earlier. People who fast are also likely to lose more muscle mass than
          fat. Having multiple small-portioned meals throughout the day instead
          of fewer, larger ones is better as it increases your metabolism and
          burns a few more calories.
        </p>
      </>
    ),
    readTime: "5 mins",
    author: { name: "Claire Ojiaka, MD", avatar: avatar },
    relatedArticles: [5, 9, 7, 10, 3]
  },
  {
    id: 7,
    tag: "general",
    title: "Taking charge of your health",
    coverImg: postImg1,
    intro:
      "Are you possibly treating your latest acquisition better than your health? You can function like a well oiled machine.",
    content: (
      <>
        <blockquote className="lrndp__bq">
          <p>
            Are you possibly treating your latest acquisition better than your
            health? You can function like a well oiled machine.
          </p>
        </blockquote>
        <h2>What does it mean?</h2>
        <p>
          Imagine the feeling you get from purchasing a brand-new luxury car
          from the car shop. How careful you would be with the car trying to
          avoid any scratches from unsuspecting commercial buses and getting it
          washed regularly to ensure it stays clean and shiny. You would also
          make sure the vehicle is serviced regularly to identify and fix any
          issues before they are apparent without waiting till the car breaks
          down. Don’t you think your health which sustains your life deserves
          that kind of attention?
        </p>

        <p>
          {" "}
          Taking charge of your health entails recognizing that one’s health is
          precious and taking active steps to preserve it. There is no
          replacement for life, we only get one life, and it is imperative that
          we live it to the fullest.
        </p>
        <div className="lrndp__post-img-wrapper">
          <img
            src={postImg1}
            alt="Lorep ipsum dolor sit amet consec adipscing elit"
            className="lrndp__post-img"
          />
        </div>
        <h2>Why is it important?</h2>
        <p>
          An estimated 4 in 10 deaths are due to preventable causes. Being
          proactive about your health increases your chances of avoiding
          preventable conditions or catching any problems in its early stages.
        </p>
        <p>
          Families also get to save on hospital spending and catastrophic costs
          that can plunge the household into poverty.
        </p>
        <h2>What can I do? </h2>
        <p>
          There are six things that can have the most impact on your health.
        </p>
        <ol type="1" className="ol--bulleted">
          <li>
            <span className="lrndp__txt-bold">Making that decision: </span> The
            first step is understanding why healthy habits are important and
            making a conscious choice to be more proactive about one’s health.
            It is also important to set health goals. This should reflect what
            is important to us and how we want to feel about our body and could
            include weight loss, weight gain, sleeping better, feeling stronger,
            improving our mental health or staying active. That deep conviction
            is important so that even when we have challenges with meeting our
            goals, we keep trying and do not give up.
          </li>
          <li>
            <span className="lrndp__txt-bold"> Knowing my numbers: </span>{" "}
            Having a personal physician and getting regular health checks help
            to ensure that you are at your healthiest. It is very important to
            know your health numbers as this would provide a reference point in
            ensuring any abnormality is identified on time and any potential
            problems are prevented. It also helps you and your doctor to monitor
            your progress towards your health goals. Some of these numbers
            include the height, weight, pulse, blood pressure, blood sugar
            level, cholesterol level and waist measurement.
          </li>
          <li>
            <span className="lrndp__txt-bold"> Knowing my body: </span> No one
            can know your body better than you do. Being acquainted with your
            body means you will be able to identify issues on time if they come
            up. Do a regular self-check of your sleep, diet, hydration, weight
            and energy level.
          </li>
          <li>
            <span className="lrndp__txt-bold"> Healthy eating habits: </span>{" "}
            Regardless of your health goals, a healthy and balanced diet is
            central in maintaining good health and feeling good. It is also
            important to remember that the quality of the meal is as important
            as the quantity. Keeping track of the calorie content and
            nutritional value of the food you consume will keep you on the right
            track. Generally, fruits and vegetables should make up most of your
            meals. Whole grains such as oats, brown rice and whole wheat are
            preferable as they have a milder effect on the blood sugar. Cut down
            on saturated fats, sugar and salt as these increase the risk of
            heart disease.
          </li>
          <li>
            <span className="lrndp__txt-bold"> Physical activity: </span>{" "}
            Non-communicable diseases such as diabetes, hypertension, heart
            disease and cancers can be prevented and managed to a large extent
            with physical activity. This may be leisure or non-leisure activity
            which result in an increased energy output. It is recommended that
            an adult does at least 30 minutes of moderate exercise, 5 days a
            week. Other benefits of regular physical activity include improved
            mood, brain function and sleep quality, maintaining a healthy weight
            and improving your quality of life.
          </li>
          <li>
            <span className="lrndp__txt-bold"> Seeking more information: </span>{" "}
            You are more likely to maintain a healthy lifestyle if you stay
            informed about your wellness and your body. Ask questions and reach
            out to your primary care physician if you have any concerns.
          </li>
        </ol>
      </>
    ),
    readTime: "5 mins",
    author: { name: "Claire Ojiaka, MD", avatar: avatar },
    relatedArticles: [10, 3, 6]
  },
  {
    id: 8,
    tag: "stress",
    title: "How to improve your mood",
    coverImg: postImg1,
    intro:
      "We all have days when we feel unhappy, irritable or in a bit of a funk. Adding one or two of these tips to your daily routine can really help to improve your mood and make life more enjoyable.",
    content: (
      <>
        <h2>TIPS ON HOW TO IMPROVE YOUR MOOD</h2>

        <p>
          We all have days when we feel unhappy, irritable or in a bit of a
          funk. This could be because of a stressful day at work, a disagreement
          with a colleague or because someone scratched your car in traffic.
          Sometimes you’re just having an off day and cannot find a particular
          reason for why you feel that way. A good chunk of how we feel is
          controlled by our daily thoughts and actions which means there are
          certain things we can do to turn a bad mood around. Adding one or two
          of these tips to your daily routine can really help to improve your
          mood and make life more enjoyable.
        </p>

        <h3>MUSIC THERAPY</h3>

        <p>
          Music boosts the production of pleasure-causing chemicals in the
          brain, and this relieves feelings of anxiety and depression. It also
          distracts you from whatever might be causing you distress.
        </p>

        <h3>EXERCISE</h3>

        <p>
          Even moderate exercise is known to release chemicals that lift the
          mood especially when it involves being outdoors. Choose the type of
          exercise that you enjoy over one that might frustrate you. Just taking
          a walk might be enough to make you feel better.
        </p>

        <h3>BREATHING EXERCISE</h3>

        <p>
          As you’re reading this, take a deep breathe then let it out and notice
          the difference in how you feel. Deep breathing stimulates a nerve in
          the body which dampens our nervous system, lowers the heart rate and
          relaxes muscles. There are a number of deep breathing techniques which
          can be used, and you can use any that you prefer.
        </p>

        <h3>HAPPY THOUGHTS</h3>

        <p>
          You can also boost your mood by increasing your positive emotions.
          Write down three positive things that happened during the day that
          made you smile or feel good. Paying attention to those things helps
          bring back the mood and feelings they created.
        </p>

        <h3>GET MORE SLEEP</h3>

        <p>
          Lack of sleep can affect your mood significantly, reduce productivity.
          Improving your sleep is one of the best ways to improve your quality
          of life.
        </p>

        <h3>TALK IT OUT</h3>

        <p>
          In many cases, it is better to discharge negative emotions than to
          keep them bottled up. So, venting to a trusted friend may make you
          feel better. If your bad or low moods seem to be persistent and
          lasting for longer days, do not be afraid to reach out to a
          professional.
        </p>
      </>
    ),
    readTime: "3 mins",
    author: { name: "Claire Ojiaka, MD", avatar: avatar },
    relatedArticles: [2]
  },
  {
    id: 9,
    tag: "heart",
    title: "What do I need to know about hypertension?",
    coverImg: postImg1,
    intro:
      "Hypertension is another name for high blood pressure and it refers to when the blood pressure or the force of blood flowing through the blood vessel is consistently high.",
    content: (
      <>
        <p>
          Hypertension is another name for high blood pressure and it refers to
          when the blood pressure or the force of blood flowing through the
          blood vessel is consistently high.
        </p>
        <p>
          The blood pressure consists of two numbers, the systolic one which is
          the higher one and the diastolic one which is the lower one. A normal
          blood pressure would read 120/80mmHg or lower. Hypertension is the
          main risk factor for heart disease and keeping the blood pressure
          under control is vital for preserving health and reducing the risk of
          dangerous conditions.
        </p>
        <div className="lrndp__post-img-wrapper">
          <img
            src={postImg1}
            alt="Lorep ipsum dolor sit amet consec adipscing elit"
            className="lrndp__post-img"
          />
        </div>
        <h2>WHAT CAUSES IT?</h2>
        <p>
          For most adults, the cause of hypertension is unknown. This type of
          hypertension called primary hypertension tends to develop slowly over
          time. Some of the factors which play a role here are genes – some
          people inherit it from their parents, environmental factors such as
          diet and lack of physical activity. The second type of hypertension
          called the secondary hypertension is caused by underlying medical
          conditions such as kidney disease, thyroid problems etc.
        </p>
        <h2>WHAT PUTS ME AT RISK OF HAVING IT?</h2>
        <p>
          This can be related to the way water flows through a garden hose where
          the force of the water flowing through the hose increases when the
          speed of the tap is increased, or diameter of the hose reduced and
          reduced when hose is made of a more elastic material. In the same way,
          factors which alter the force of the heart pumping blood, restrict
          blood flow, or affect the elasticity of the blood vessels have an
          effect on blood pressure.
        </p>
        <p>
          Risk factors include increasing age as the blood vessels stiffen with
          age; obesity as lipids may form plaques that obstruct blood flow;
          alcohol and tobacco consumption. Males and people of African descent
          also have an increased risk of being hypertensive.
        </p>
        <h2>WHAT ARE THE SYMPTOMS?</h2>
        <p>
          High blood pressure does not usually cause any symptoms so the only
          way to detect it is to get regular blood pressure readings. It may
          take years for the condition to reach levels severe enough to cause
          symptoms and it may be fatal at this point.
        </p>
        <h2>WHAT MAKES IT BAD?</h2>
        <p>
          Long term hypertension may cause complications when not properly
          controlled. These complications include heart failure or heart
          attacks, kidney failure, stroke and vision loss.
        </p>
        <h2>HOW CAN IT BE MANAGED</h2>
        <p>
          With proper treatment and management, blood pressure can be controlled
          to help one live a long and healthy life. Lifestyle change is the
          first and most important step towards managing hypertension.
        </p>
        <ul>
          <li>
            <span className="lrndp__txt-bold"> Diet: </span>A heart healthy diet
            is vital in controlling blood pressure. It involves consuming more
            fruits, vegetables, whole grain, and high fiber meals; cut sugar;
            avoiding trans-fat and animal fat; lowering salt intake to under 5g
            a day; reducing alcohol intake, avoiding smoking.
          </li>
          <li>
            <span className="lrndp__txt-bold"> Exercise: </span>This helps to
            reduce stress and strengthen the cardiovascular system. It is
            recommended that all people, including those with hypertension,
            engage in at least 150 minutes of moderate intensity, aerobic
            exercise every week, or 75 minutes a week of high intensity exercise
          </li>
          <li>
            <span className="lrndp__txt-bold"> Medication: </span>Drugs may be
            added to by the doctor to help lower the blood pressure. This is
            usually started with low doses, and they have minimal side effects.
          </li>
          <li>
            <span className="lrndp__txt-bold"> Monitoring: </span>The best way
            to prevent complications of hypertension is to catch it early. A
            blood pressure monitor could be purchased, and the readings done at
            home. Regular review with the doctor and screening for complications
            and other conditions are vital in living a long health life.
          </li>
        </ul>
      </>
    ),
    readTime: "5 mins",
    author: { name: "Claire Ojiaka, MD", avatar: avatar },
    relatedArticles: [5, 6]
  },
  {
    id: 10,
    tag: "general",
    title: "What is sleep hygiene?",
    coverImg: postImg1,
    intro:
      "We all have personal grooming habits to stay clean and healthy. In the same way, our sleep could also benefit from hygiene. ",
    content: (
      <>
        <div className="lrndp__post-img-wrapper">
          <img
            src={postImg1}
            alt="Lorep ipsum dolor sit amet consec adipscing elit"
            className="lrndp__post-img"
          />
        </div>
        <p>
          We all have personal grooming habits to stay clean and healthy. In the
          same way, our sleep could also benefit from hygiene. The term sleep
          hygiene simply refers to healthy sleep habits. It is a collection of
          practices designed to promote good sleeping and improve our ability to
          fall asleep or stay asleep.
        </p>
        <p>
          If you have had sleep problems, you are not alone. They are quite
          common and about 1 in 4 persons experience them. This may manifest as
          difficulty falling asleep, sleep disruptions or unsatisfying sleep.
        </p>
        <p>
          The main cause of poor sleep are activities carried out close to sleep
          time which may stimulate wakefulness or affect normal sleep. This
          includes behaviours such as alcohol or caffeine consumption, noisy,
          bright or uncomfortable sleeping environments. Not everyone who
          partakes in these activities will have difficulty sleeping. Some
          people biologically are just more sensitive to changes in their sleep
          schedule or little bits of external stimuli and so are at more risk of
          poor sleep with those habits.
        </p>
        <p>
          Getting a refreshing and satisfying sleep can improve your mental and
          physical wellbeing helping you live a wholesome and fulfilling life.
        </p>
        <p>Some of the habits that can improve your sleep are: </p>
        <ul>
          <li>
            <span className="lrndp__txt-bold"> SLEEP SCHEDULE: </span> Go to bed
            and get up at the same time every day. The body has an internal
            clock which controls the sleep and wake cycle. Having a sleep
            schedule will help set this clock and train the body into feeling
            sleepy and waking up at a particular time. Adults require an average
            of 7-8 hours of sleep daily including naps to feel fully rested. It
            is important to have a realistic plan for how much sleep you want to
            get every day.
          </li>
          <li>
            <span className="lrndp__txt-bold"> BEDTIME ROUTINE: </span>{" "}
            Establishing a relaxing bedtime routine helps you power down and
            reminds your body that it is time to sleep. Some examples are taking
            a warm bath, relaxing stretches, listening to soothing music,
            meditation, deep breathing exercises, visualizing a soothing scene,
            siting calmly while drinking a cup of non-caffeinated tea. These
            activities help prepare your body for bed. Avoid exercising or doing
            any activity that will excite you close to bedtime.
          </li>
          <li>
            <span className="lrndp__txt-bold"> AVOID STIMULANTS: </span> It is
            advisable to avoid consuming substances which contain stimulants
            such as caffeine (coffee, cola, drinks, chocolates, tea, some
            medication) and nicotine (cigarettes) at least 4-6 hours before bed.
            These substances block the production of sleep-promoting chemicals
            in the brain and interfere with the body’s sleep wake cycle leading
            to reduced sleep time and satisfaction.
          </li>
          <li>
            <span className="lrndp__txt-bold"> ENVIRONMENT: </span> You are more
            likely to have a good sleep if the environment feels relaxing and
            restful. A good mattress which provides adequate support would be
            valuable. Ensure the room is quiet, dark and cool. A sleep mask and
            ear plugs can be used. Also, the bed should be used for sleep and
            intimacy only so that the mind learns to associate it with sleep.
            Using the bed for eating, watching television, working etc. will
            prevent the mind from making this connection.
          </li>
          <li>
            <span className="lrndp__txt-bold"> DON’T STRESS IT: </span> Worrying
            about not sleeping just makes it more likely that you would not be
            able to sleep. If you have not been able to fall asleep after 30
            minutes or more, get up and do something calming or boring in
            another room until you feel sleepy, then go back to bed and try
            again. Avoid getting on electronics or doing anything stimulating as
            this might wake you up more. Don’t dwell on or bring your problems
            to bed. You can schedule 30 minutes before bedtime for worrying or
            write down your concerns and assure yourself that you will attend to
            them when you wake up.
          </li>
          <li>
            <span className="lrndp__txt-bold"> MEALS: </span> Do not eat a large
            meal before bed and do not go to bed hungry either. Avoid alcohol 4
            hours before bed.
          </li>
          <li>
            <span className="lrndp__txt-bold"> SLEEP DIARY: </span> If you still
            have trouble sleeping, keep a sleep diary. Take note of when you go
            to bed, when you get up, how much time you spend in bed unable to
            sleep, total sleep time and other details about your sleep patterns.
          </li>
        </ul>
        <p>
          You do not have to institute all the changes at once. It is more
          likely to yield better results when you take it a step at a time.
        </p>
        <p>
          You should also speak to your doctor if your sleep problems persist as
          there might be other factors responsible.
        </p>
      </>
    ),
    readTime: "5 mins",
    author: { name: "Claire Ojiaka, MD", avatar: avatar },
    relatedArticles: [7, 3, 6]
  }
];

for (let key in posts) {
  key = Number(key);
  const post = posts[key];
  const daysOffset = key * 7;
  post.postDate = startDate.add(daysOffset, "days");
}

export default posts.reverse();
