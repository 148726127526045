import Tag from "components/Tag";
import React from "react";
import avatar from "assets/images/avatar.png";
import Profile from "components/Profile";
import bubble from "assets/images/bubble-3.png";
import Fade from "react-reveal/Fade";

const team = [
  {
    name: "Karen Okonkwo",
    desc: "Software Engineer",
    avatar: avatar
  },
  {
    name: "Karen Okonkwo",
    desc: "Software Engineer",
    avatar: avatar
  },
  {
    name: "Karen Okonkwo",
    desc: "Software Engineer",
    avatar: avatar
  },
  {
    name: "Karen Okonkwo",
    desc: "Software Engineer",
    avatar: avatar
  }
];

const Team = () => {
  return (
    <div className="ap__team flx-center">
      <div className="ap__team-content max-page-width">
        <Fade bottom>
          <div className="ap__team-top">
            <img src={bubble} className="ap__team-bubble" alt="" />
            <Tag> Team </Tag>
            <h3 className="ap__heading ap__team-heading">
              Service is our superpower.{" "}
            </h3>
            <p className="ap__team-desc">
              Service isn't one person's responsibility. We are all involved:
              investing on behalf of others and finding joy in their success.
            </p>
          </div>
          <ul className="ap__team-list">
            {team.map((member, index) => (
              <Profile
                key={index}
                avatar={member.avatar}
                altText={member.name}
                primaryText={member.name}
                secondaryText={member.desc}
              />
            ))}
          </ul>
        </Fade>
      </div>
    </div>
  );
};

export default Team;
