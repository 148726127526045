import React from "react";
import Icon from "components/Icon";
import "./styles.css";

const ListItemCard = ({ text, className, ...props }) => {
  return (
    <li className={`lic ${className || ""}`} {...props}>
      <Icon id="iddera1" className="lic__icon" />
      <span className="lic__text">{text}</span>
    </li>
  );
};

export default ListItemCard;
