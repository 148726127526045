import React from "react";
import "./styles.css";

const Profile = ({ avatar, altText, primaryText, secondaryText, children }) => {
  return (
    <div className="profile">
      <div className="profile__img-wrapper">
        <img className="profile__img" src={avatar} alt={altText} />
      </div>
      <h3 className="profile__ptext"> {primaryText} </h3>
      <p className="profile__stext"> {secondaryText} </p>
      {children}
    </div>
  );
};

export default Profile;
