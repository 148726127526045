import React from "react";
import { Header, JoinIddera, Footer } from "../Common";
import Cares from "./components/Cares";
import Gallery from "./components/Gallery";
import GetStarted from "./components/GetStarted";
import Hero from "./components/Hero";
import ThreeCs from "./components/ThreeCs";
import WhoWeAre from "./components/WhoWeAre";
import MembershipBenefits from "./components/MembershipBenefits";
import HealthPrograms from "./components/HealthPrograms";
import Reviews from "../Common/components/Reviews";
import BubblesBg from "./components/BubblesBg";
import "./styles/main.css";
import "./styles/responsive.css";

const LandingPage = () => {
  return (
    <div className="webp lp">
      {/* <BubblesBg /> */}
      <div className="top-fog top-fog--lp" />
      <Header />
      <Hero />
      <WhoWeAre />
      <Gallery />
      <ThreeCs />
      <Cares />
      <GetStarted />
      <JoinIddera />
      <MembershipBenefits />
      <HealthPrograms />
      <Reviews />
      <Footer />
    </div>
  );
};

export default LandingPage;
