import React from "react";
import "./styles.css";
import Icon from "components/Icon";

const StepCard = ({ step, ...props }) => {
  return (
    <div className="sc" {...props}>
      <div className="sc__top">
        <div className="sc__left">
          <div className="sc__no">
            {step.no}
            {step.last && <Icon id="checked-circle" className="sc__check" />}
          </div>
        </div>
        <div className="sc__right">
          <h3 className="sc__heading"> {step.title} </h3>
          <p className="sc__desc"> {step.desc} </p>
        </div>
      </div>
      <div className="sc__img-wrapper">
        <img src={step.img} alt={step.title} className="sc__img" />
      </div>
    </div>
  );
};

export default StepCard;
