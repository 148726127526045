import React, { useEffect, useRef, useState } from "react";
import { JoinIddera, Footer } from "pages/website/Common";
import logo from "assets/logos/full-logo-primary.svg";
import Fade from "react-reveal/Fade";
import { Link, NavLink, useLocation } from "react-router-dom";
import Lottie from "react-lottie";
import bubbles from "assets/lotties/bubbles.json";
import Icon from "components/Icon";

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: bubbles
};

const docHeading = {
  "/privacy": {
    heading: "Privacy Policy",
    eDate: "Effective as of December 21, 2021."
  },
  "/cookie-policy": {
    heading: "Cookie Policy",
    eDate: "Effective as of December 21, 2021."
  },
  "/terms": {
    heading: "Terms and Conditions",
    eDate: "Effective as of December 21, 2021."
  }
};

const LegalLayout = ({
  children,
  heading,
  topFog,
  topImg,
  tableOfContent,
  setContactSectionRef
}) => {
  const { pathname } = useLocation();

  const [showHeaderMb, setShowHeaderMb] = useState(false);
  const [showNavMb, setShowNavMb] = useState(false);

  const bCrumbRef = useRef(null);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const show = bCrumbRef.current.getBoundingClientRect().top < 100;
    setShowHeaderMb(show);
  };

  return (
    <div className="legal">
      <div className="legal__top">
        {topFog}
        <div
          className={`legal__sticky-header-mobile ${
            showHeaderMb ? "show" : ""
          } ${showNavMb ? "show--nav" : ""}`}
          aria-hidden="true"
        >
          <header className="legal__header">
            <Link to="/">
              <img src={logo} alt="Iddera" className="header__logo" />
            </Link>
          </header>
          <div className="legal__bcrumbs">
            <p>
              All Policies{" "}
              <span className="legal__bcrumbs-current">
                {`> ${docHeading[pathname].heading}`}
              </span>
            </p>
            <button
              className="legal__bcrumbs-btn"
              onClick={() => setShowNavMb(!showNavMb)}
              aria-label={
                showNavMb ? "Close policy navigation" : "Open policy navigation"
              }
            >
              <Icon id={showNavMb ? "close" : "menu"} />
            </button>
          </div>
        </div>
        <header className="legal__header">
          <Link to="/">
            <img src={logo} alt="Iddera" className="header__logo" />
          </Link>
        </header>
        <div className="legal__top-body max-page-width">
          <Fade bottom>
            <h1 className="legal__heading legal__top-heading">{heading}</h1>
            <div className="legal__top-img-wrapper">
              <div className="legal__bubbles-wrapper">
                <Lottie options={lottieOptions} />
              </div>
              <img
                src={topImg}
                alt="Terms and Conditions"
                className="legal__top-img"
              />
            </div>
          </Fade>
        </div>
      </div>

      <div className="legal__content max-page-width">
        <div className="legal__nav-wrapper">
          <div className="legal__bcrumbs" ref={bCrumbRef}>
            <p>
              All Policies{" "}
              <span className="legal__bcrumbs-current">
                {`> ${docHeading[pathname].heading}`}
              </span>
            </p>
            <button
              className="legal__bcrumbs-btn"
              onClick={() => setShowNavMb(!showNavMb)}
              aria-label={
                showNavMb ? "Close policy navigation" : "Open policy navigation"
              }
            >
              <Icon id={showNavMb ? "close" : "menu"} />
            </button>
          </div>
          <h1 className="legal__heading legal__nav-heading"> All Policies </h1>
          <nav className={`legal__nav ${showNavMb ? "show" : ""}`}>
            <NavLink
              className="legal__nav-link"
              to="/privacy"
              activeClassName="legal__nav-link--active"
            >
              Privacy
            </NavLink>
            <NavLink
              className="legal__nav-link"
              to="/cookie-policy"
              activeClassName="legal__nav-link--active"
            >
              Cookies
            </NavLink>
            <NavLink
              className="legal__nav-link"
              to="/terms"
              activeClassName="legal__nav-link--active"
            >
              Terms & Conditions
            </NavLink>
          </nav>
        </div>
        <div className="legal__doc">
          <div className="legal__doc-top">
            <h1 className="legal__heading legal__doc-heading">
              {docHeading[pathname].heading}
            </h1>
            <p className="legal__doc-edate">{docHeading[pathname].eDate}</p>
          </div>

          <div className="legal_toc">
            <h2 className="legal__toc-heading"> Table of Contents </h2>
            <nav className="legal__toc-nav">
              {tableOfContent && (
                <>
                  {tableOfContent.map((heading, index) => (
                    <Link
                      className="legal__toc-nav-link"
                      key={heading}
                      to={`${pathname}?section=${index + 1}`}
                    >
                      {heading}
                    </Link>
                  ))}
                  <Link
                    className="legal__toc-nav-link"
                    to={`${pathname}?section=${tableOfContent.length + 1}`}
                  >
                    Contact us
                  </Link>
                </>
              )}
            </nav>
          </div>
          <div className="legal__doc-body">
            {children}

            <section
              className="legal__contact-section"
              ref={setContactSectionRef}
            >
              <h2> Contact Us </h2>
              <p>
                If you have any questions or comments about this Policy, or if
                you would like us to update information, we have about you or
                your preferences, you may contact us at:
              </p>
              <div className="legal__contacts">
                <dl className="legal__contact">
                  <dt className="legal__heading">Iddera Life Solutions Ltd </dt>
                  <dd>
                    6, Oduduwa crescent GRA Ikeja, Lagos state, Nigeria. 100232{" "}
                  </dd>
                </dl>
                <dl className="legal__contact">
                  <dt className="legal__heading">Email</dt>
                  <dd>
                    <a href="mailto:support@iddera.com">support@iddera.com</a>{" "}
                    or <a href="mailto:dpo@iddera.com">dpo@iddera.com </a>
                  </dd>
                </dl>
              </div>
            </section>
          </div>
        </div>
      </div>
      <JoinIddera />
      <Footer />
    </div>
  );
};

export default LegalLayout;
