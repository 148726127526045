import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LegalLayout from "./LegalLayout";
import cookie from "assets/images/legal-cookie.png";
import WrappedIcon from "components/WrappedIcon";

const initialTableData = {
  "section-4": {
    hidden: true,
    canHide: true,
    data: [
      {
        col1: <>First Party</>,
        col2: <>AMP_TOKEN</>,
        col3: (
          <>
            <ul>
              <li>Necessary </li>
              <li>
                Ensures visitor browsing-security by preventing cross-site
                request forgery. This cookie is essential for the security of
                the website and visitor.{" "}
              </li>
            </ul>{" "}
          </>
        ),
        col4: <>Persistent 1 day </>
      },
      {
        col1: <>First Party</>,
        col2: <>CookieConsent</>,
        col3: (
          <>
            {" "}
            <ul>
              <li>Necessary </li>
              <li>
                Stores the user's cookie consent state for the current domain
              </li>
            </ul>{" "}
          </>
        ),
        col4: <>Persistent 1 year</>
      },
      {
        col1: <>Third Party</>,
        col2: <>CookieConsent BulkSetting-#</>,
        col3: (
          <>
            {" "}
            <ul>
              <li> Necessary</li>
              <li>Enables cookie consent across multiple websites.</li>
            </ul>{" "}
          </>
        ),
        col4: <>Persistent</>
      },
      {
        col1: <>Third Party</>,
        col2: <>CookieConsent BulkTicket</>,
        col3: (
          <>
            {" "}
            <ul>
              <li> Necessary</li>
              <li>Enables cookie consent across multiple websites.</li>
            </ul>{" "}
          </>
        ),
        col4: <>Persistent 1 year</>
      },
      {
        col1: <>Third Party</>,
        col2: <>_ga</>,
        col3: (
          <>
            {" "}
            <ul>
              <li> Statistics</li>
              <li>
                Google Analytics registers a unique ID that is used to generate
                statistical data on how the visitor uses the website
              </li>
            </ul>{" "}
          </>
        ),
        col4: <>Persistent 2 years</>
      },
      {
        col1: <>Third Party</>,
        col2: <> _gat</>,
        col3: (
          <>
            {" "}
            <ul>
              <li> Statistics</li>
              <li>
                Used by Google Analytics to throttle request rate (Website
                only).
              </li>
            </ul>{" "}
          </>
        ),
        col4: <>Persistent 1 day</>
      },
      {
        col1: <>Third Party</>,
        col2: <>_gid</>,
        col3: (
          <>
            <ul>
              {" "}
              <li>Statistics</li>
              <li>
                Registers a unique ID that is used to generate statistical data
                on how the visitor uses the Website.{" "}
              </li>
            </ul>{" "}
          </>
        ),
        col4: <>Persistent 1 day</>
      },

      {
        col1: <>Third Party</>,
        col2: <>collect</>,
        col3: (
          <>
            {" "}
            <ul>
              <li> Statistics</li>
              <li>
                Used to send data to Google Analytics about the visitor's device
                and behaviour. Tracks the visitor across devices and marketing
                channels (Website only)
              </li>
            </ul>{" "}
          </>
        ),
        col4: <>Session</>
      },
      {
        col1: <>Third Party</>,
        col2: <>r/collect</>,
        col3: (
          <>
            {" "}
            <ul>
              <li> Statistics</li>
              <li>
                This cookie is used to send data to Google Analytics about the
                visitor's device and behavior. It tracks the visitor across
                devices and marketing channels (Website only)
              </li>
            </ul>{" "}
          </>
        ),
        col4: <>Session</>
      },
      {
        col1: <>Third Party</>,
        col2: <>test_cookie</>,
        col3: (
          <>
            <ul>
              {" "}
              <li>Neccessary</li>
              <li>Used to check if the user’s browser supports cookies.</li>
            </ul>{" "}
          </>
        ),
        col4: (
          <>
            <ul>Persistent 1 day </ul>
          </>
        )
      },
      {
        col1: <>Third Party</>,
        col2: <>GPS</>,
        col3: (
          <>
            <ul>
              {" "}
              <li>Neccessary</li>
              <li>
                Registers a unique ID on mobile devices to enable tracking based
                on geographical GPS location{" "}
              </li>
            </ul>{" "}
          </>
        ),
        col4: <>Persistent 1 day </>
      },
      {
        col1: <>Third Party</>,
        col2: <>PREF</>,
        col3: (
          <>
            <ul>
              {" "}
              <li>Preferences</li>
              <li>
                Registers a unique ID that is used by Google to keep statistics
                of how the visitor uses YouTube videos across different websites
              </li>
            </ul>{" "}
          </>
        ),
        col4: <>Persistent 8 months </>
      },
      {
        col1: <>Third Party</>,
        col2: <>VISITOR_INFO1_LIVE</>,
        col3: (
          <>
            <ul>
              {" "}
              <li>Preferences</li>
              <li>
                Tries to estimate the users' bandwidth on pages with integrated
                YouTube videos.{" "}
              </li>
            </ul>{" "}
          </>
        ),
        col4: <>Persistent 179 days</>
      },
      {
        col1: <>Third Party</>,
        col2: <>YSC</>,
        col3: (
          <>
            <ul>
              {" "}
              <li>Statistics</li>
              <li>
                Registers a unique ID to keep statistics of what videos from
                YouTube the user has seen (Website only).
              </li>
            </ul>{" "}
          </>
        ),
        col4: <>Session</>
      },
      {
        col1: <>Third Party</>,
        col2: (
          <>
            <ul>
              <li>yt-remote-cast installed</li>
              <li>yt-remote-connected-devices</li>
              <li>yt-remote-device-id</li>
              <li>yt-remote-fast-check-period</li>
              <li>yt-remote-session-app</li>
              <li>yt-remote-session-name</li>
            </ul>
          </>
        ),
        col3: (
          <>
            <ul>
              {" "}
              <li>Preferences</li>
              <li>
                Stores the user's video player preferences using embedded
                YouTube video
              </li>
            </ul>{" "}
          </>
        ),
        col4: <>Some are session,some are persistent</>
      },
      {
        col1: <>Third Party</>,
        col2: <>fbq</>,
        col3: (
          <>
            <ul>
              {" "}
              <li>Marketing</li>
              <li>
                It is a cookie-like code in the web browsers of visitors.
                Facebook, may use the Facebook pixel to collect or receive
                information from the publisher webstore and use that information
                to provide measurement services, ads personalization and as
                described in the Facebook Data Policy.
              </li>
            </ul>{" "}
          </>
        ),
        col4: <>Some are session, some are persistent</>
      },
      {
        col1: <>Third Party</>,
        col2: <>_gaexp</>,
        col3: (
          <>
            <ul>
              {" "}
              <li>Marketing</li>
              <li>
                Used by Google Analytics to determine a user's inclusion in an
                experiment and the expiry of experiments a user has been
                included in{" "}
              </li>
            </ul>{" "}
          </>
        ),
        col4: <>Some are session, some are persistent</>
      },
      {
        col1: <>Third Party</>,
        col2: <>_snaptr</>,
        col3: (
          <>
            <ul>
              {" "}
              <li>Marketing</li>
              <li>
                Analytics related to measuring the success of ad campaigns,
                evaluating campaign performance, and retargeting.
              </li>
            </ul>{" "}
          </>
        ),
        col4: (
          <>
            Persistent for unmatched data, 30 days; for matched data 13 months
          </>
        )
      },
      {
        col1: <>Third Party</>,
        col2: <>amp_d5a597</>,
        col3: (
          <>
            <ul>
              {" "}
              <li>Necessary</li>
              <li>
                The cookie is used by Amplitude service to keep track of user
                journey on the Web Application.{" "}
              </li>
            </ul>{" "}
          </>
        ),
        col4: <>Persistent</>
      },
      {
        col1: <>Third Party</>,
        col2: (
          <>
            <ul>
              <li>NID</li>
              <li>SID</li>
            </ul>
          </>
        ),
        col3: (
          <>
            <ul>
              {" "}
              <li>Marketing</li>
              <li>
                Google uses cookies like NID and SID to help customize ads on
                Google properties, like Google Search. For example, Google uses
                such cookies to remember your most recent searches, your
                previous interactions with an advertiser’s ads or search
                results, and your visits to an advertiser’s website. This helps
                to show you customized ads on Google.
              </li>
            </ul>{" "}
          </>
        ),
        col4: <>Persistent</>
      },
      {
        col1: <>Third Party</>,
        col2: (
          <>
            <ul>
              <li>IDE</li>
              <li>ANID</li>
              <li>DSID</li>
              <li>FLC AID</li>
              <li>TAID</li>
              <li>exchange_uid</li>
            </ul>
          </>
        ),
        col3: (
          <>
            <ul>
              {" "}
              <li>Marketing</li>
              <li>
                Used by Google and Google DoubleClick to register and report the
                website user's actions after viewing or clicking one of the
                advertiser's ads with the purpose of measuring the efficacy of
                an ad and to present targeted ads to the user, to recognize you
                across devices
              </li>
            </ul>{" "}
          </>
        ),
        col4: <>Persistent</>
      },
      {
        col1: <>Third Party</>,
        col2: <>_gcl</>,
        col3: (
          <>
            <ul>
              {" "}
              <li>Marketing</li>
              <li>
                Google uses conversion cookies to help advertisers determine how
                many times people who click on their ads end up taking an action
                on their site (e.g., making a purchase). These cookies allow
                Google and the advertiser to determine that you clicked the ad
                and later visited the advertiser’s site. Conversion cookies are
                not used by Google for personalized ad targeting and persist for
                a limited time only
              </li>
            </ul>{" "}
          </>
        ),
        col4: <>Persistent</>
      },
      {
        col1: <>Third Party</>,
        col2: <>_cfduid</>,
        col3: (
          <>
            <ul>
              {" "}
              <li>Neccessary</li>
              <li>
                Cloudflare uses this cookie to detect malicious visitors to the
                Website and minimize blocking legitimate visitors by identifying
                individual clients behind shared IP addresses and applying
                security settings on a per-client basis. You may read more at{" "}
                <a href="https://www.cloudflare.com/cookie-policy/">
                  Cloudflare Cookies Policy{" "}
                </a>
              </li>
            </ul>{" "}
          </>
        ),
        col4: <>Persistent 30 days</>
      },
      {
        col1: <>Third Party</>,
        col2: <>_hscc</>,
        col3: (
          <>
            <ul>
              {" "}
              <li>Statistics</li>
              <li>
                Helps keep track of user sessions and used by HubSpot service to
                determine whether to increment the session number and timestamps
                in the __hstc cookie
              </li>
            </ul>{" "}
          </>
        ),
        col4: <>Session 30 minutes</>
      },
      {
        col1: <>Third Party</>,
        col2: <>_hssrc</>,
        col3: (
          <>
            <ul>
              {" "}
              <li>Statistics</li>
              <li>
                Helps to determine if the visitor of the Website has restarted
                their browser. Used by HubSpot service when a user changes the
                session cookie, thiscookie is also set to determine if the
                visitor has restarted their browser.
              </li>
            </ul>{" "}
          </>
        ),
        col4: <>Session</>
      },
      {
        col1: <>Third Party</>,
        col2: <>_hstc </>,
        col3: (
          <>
            <ul>
              {" "}
              <li>Statistics</li>
              <li>
                Helps to track the Website visitor and determines the domain,
                utk, first, last and current visit, the session number. Used by
                HubSpot services.{" "}
              </li>
            </ul>{" "}
          </>
        ),
        col4: <>Persistent 13 months</>
      },
      {
        col1: <>Third Party</>,
        col2: <>hubspotutk</>,
        col3: (
          <>
            <ul>
              {" "}
              <li>Statistics</li>
              <li>
                Used by HubSpot services to help keep track of the Website user
                identity and deduplicate contacts during form submission. This
                cookie contains an opaque GUID to represent the current visitor
              </li>
            </ul>{" "}
          </>
        ),
        col4: <>Persistent 13 months</>
      },
      {
        col1: <>Third Party</>,
        col2: <>Help Center cookies </>,
        col3: (
          <>
            The Website Help Center is powered by Zendesk acting as our
            processor. Please note that <a>Zendesk in-product cookie policy</a>{" "}
            applies to your use of the Help Center{" "}
          </>
        ),
        col4: <></>
      },
      {
        col1: <>Third Party</>,
        col2: <>Linkedin </>,
        col3: (
          <>
            <ul>
              {" "}
              <li>Marketing</li>
              <li>
                LinkedIn Insight Tag is a piece of lightweight JavaScript code
                we place on our website. The LinkedIn insight tag allows us to
                track conversions, retarget website visitors, and unlock
                additional insights about members interacting with our ads.
              </li>
              <li>
                LinkedIn Insight Tag enables the collection of data regarding
                members’ visits to our website, including the URL, referrer, IP
                address, device and browser characteristics (User Agent), and
                timestamp. This data is encrypted
              </li>
              <li>
                LinkedIn does not share the personal data with the website
                owner, it only provides reports and alerts (which do not
                identify the visitor) about the website audience and ad
                performance. LinkedIn also provides retargeting for website
                visitors, enabling the website owner to show personalized ads
                off its website by using this data, but without identifying the
                member. LinkedIn members can control the use of their personal
                data for advertising purposes{" "}
                <a>through their account settings</a>. You can learn more about
                the technology by following <a>this link</a>.
              </li>
            </ul>
          </>
        ),
        col4: <>Persistent 6 months</>
      }
    ]
  }
};

const CookiePolicy = () => {
  const location = useLocation();
  const sectionRefs = useRef([]);
  const [tableData, setTableData] = useState(initialTableData);
  const sections = [
    {
      heading: "Background",
      content: (
        <>
          <p>
            The websites iddera.com, iddera.com/registration, iddera.com/learn
            and other related pages (the "Website") are operated by Iddera Life
            Solutions Limited. Web application and other related pages (the "Web
            Application") are also operated by Iddera Life Solutions Limited.
          </p>
          <p>
            At Iddera Life Solutions Limited, your digital safety matters a lot
            to us, as well as your health and well-being. Transparency is
            fundamental in building trustworthy relationships with you. In the
            spirit of transparency, this policy provides detailed information
            about how and when we use cookies. This cookie policy applies to any
            product or service by Iddera Life Solutions Limited that links to
            this policy or incorporates it by reference.
          </p>
        </>
      )
    },
    {
      heading: "What are Cookies?",
      content: (
        <>
          <p>
            Cookies are small text files that are stored in your web browser
            that allows us or a third party to recognize you. Cookies can be
            used to collect, store, and share bits of information about your
            activities across websites, including on Iddera Life Solutions
            Ltd.'s Website and Web Application.
          </p>
          <p>
            It enables the website to remember your actions and preferences
            (such as login, language, font size and other display preferences)
            over a period of time, so you don'thave to keep re-entering them
            whenever you come back to the site or browse from one page to
            another.
          </p>
          <p>Cookies might be used for the following purposes:</p>
          <ul>
            <li>To enable certain functions</li>
            <li>To provide statistics</li>
            <li>To store your preferences</li>
            <li>To enable ad delivery and behavioral advertising</li>
          </ul>
        </>
      )
    },
    {
      heading: "What types of Cookies do we use?",
      content: (
        <>
          <p>We use both session cookies and persistent cookies.</p>
          <p>
            A <span className="legal__doc-bold">session</span> cookie is used to
            identify a particular visit to our Website or Web Application. These
            cookies expire after a short period of time, or when you close your
            web browser after using our Website. We use these cookies to
            identify you during a single browsing session, such as when you log
            into our Website.
          </p>
          <p>
            A <span className="legal__doc-bold">persistent</span> cookie will
            remain on your devices for a set period of time specified in the
            cookie. We use these cookies where we need to identify you over a
            longer period of time. For example, we would use a persistent cookie
            if you asked that we keep you signed in.
          </p>{" "}
          <h4>Categories of Use and Description</h4>
          <p>
            <span className="legal__doc-bold">Marketing</span> – cookies that
            help us reach you and more people like you to spread the word about
            the app as well as analyze whether we do that effectively.
          </p>
          <p>
            <span className="legal__doc-bold">Neccessary</span> cookies that
            <ul>
              <li>
                Enable and support our security features and help us detect
                malicious activity.
              </li>
              <li>
                Help us learn how well our Website and plugins perform in
                different locations
              </li>
            </ul>
          </p>
          <p>
            <span className="legal__doc-bold">Preferences</span> cookies that
            help us show you the right information and personalize your
            experience.
          </p>
          <p>
            <span className="legal__doc-bold">Statistics</span> cookies that
            help us understand, improve, and research products, features, and
            services (Website only).
          </p>
        </>
      )
    },
    {
      heading: "What Cookies do we use?",
      content: (
        <>
          {" "}
          <p>
            {" "}
            Our cookie table lists third party cookies on our Website and Web
            Application. Please note that the names of cookies, pixels and other
            technologies may change over time. We also may use certain cookies
            on only a limited number of pages that you visit on our Website and
            Web Application to avoid unnecessary collection of your personal
            data. Please note that our cookies do not process any personal
            health data. Here is the full list of cookies
          </p>
          <button
            className={`legal__toggle-tbl-btn ${
              tableData["section-4"].hidden ? "" : "legal__toggle-tbl-btn--open"
            }`}
            onClick={() => toggleTable("section-4")}
          >
            <span>
              Click to {tableData["section-4"].hidden ? "show" : "hide"} full
              list of our cookies
            </span>
            <WrappedIcon id="arrow-down" />
          </button>
          <div className="table-wrapper">
            {!tableData["section-4"].hidden && (
              <table>
                <thead>
                  <tr>
                    <th>Domain</th>
                    <th>Cookie Name</th>
                    <th>Categories of Use and Description</th>
                    <th>Expiration</th>
                  </tr>
                </thead>

                <tbody>
                  {tableData["section-4"]?.data.map((row, index) => (
                    <tr key={index}>
                      <td>{row.col1}</td>
                      <td>{row.col2}</td>
                      <td>{row.col3}</td>
                      <td>{row.col4}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <h4> What is Do Not Track (DNT)?</h4>
          <p>
            {" "}
            DNT is a concept that has been promoted by regulatory agencies such
            as the U.S. Federal Trade Commission (FTC), for the Internet
            industry to develop and implement a mechanism for allowing Internet
            users to control the tracking of their online activities across
            websites by using browser settings. The World Wide Web Consortium
            (W3C) has been working with industry groups, Internet browsers,
            technology companies, and regulators to develop a DNT technology
            standard. While some progress has been made, it has been slow. No
            standard has been adopted to this date.
          </p>
        </>
      )
    },
    {
      heading:
        "What to do if you don't want Cookies to be set or want them to be removed?",
      content: (
        <>
          <p>
            If you don't like the idea of cookies or certain types of cookies,
            you can change your browser's settings to delete cookies that have
            already been set and to not accept new cookies. Browser
            manufacturers provide help pages relating to cookie management in
            their products. Please see below for more information.
          </p>
          <ul>
            <li>Google Chrome</li>
            <li>Internet Explorer</li>
            <li>Mozilla Firefox</li>
            <li>Safari (Desktop)</li>
            <li>Safari (Mobile)</li>
            <li>Opera</li>
            <li>Opera Mobile</li>
          </ul>
          <p>
            You can opt out of a third-party vendor's use of cookies by visiting
            multi-cookie opt-out mechanism the Network Advertising Initiative
            opt-out page or control the use of device identifiers by using their
            device’s settings.
          </p>
          <p>
            To opt out using the Facebook site, log in and go to Settings {">"}{" "}
            Ads
            {">"} Ad Settings. On the app, go to Setting & Privacy {">"}{" "}
            Settings {">"} Ad {">"}
            Ad Preferences {">"} Ad Settings. Then choose the Not Allowed
            options under each category to stop Facebook from using data from
            partners and tracking your activity on Facebook and their partner’s
            sites to target you with ads.
          </p>
          <p>
            For other browsers, please consult the documentation that your
            browser manufacturer provides.
          </p>
          <p>
            Please note, however, that if you delete cookies or do not accept
            them, you might not be able to use all of the features we offer, you
            may not be able to store your preferences, and some of our pages
            might not display properly.
          </p>{" "}
        </>
      )
    },
    {
      heading: "Where can I find more information about Cookies?",
      content: (
        <>
          {" "}
          <p>
            You can learn more about cookies by visiting the following
            third-party websites:
          </p>
          <ul>
            <li>
              <a href="https://wikipedia.org">wikipedia.org</a>
            </li>
            <li>
              <a href="https://allaboutcookies.org">allaboutcookies.org</a>
            </li>
            <li>
              <a href="https://aboutcookies.org">aboutcookies.org</a>
            </li>
          </ul>
        </>
      )
    }
  ];

  useEffect(() => {
    const section = new URLSearchParams(location.search).get("section");
    const validSection =
      section && section >= 0 && section <= sections.length + 1;

    if (validSection) {
      window.scroll({
        top: sectionRefs.current[section - 1].offsetTop,
        behavior: "smooth"
      });
    }
  }, [location.search]);

  const toggleTable = (section) => {
    if (tableData[section].canHide) {
      const tableDataCopy = { ...tableData };
      const sectionDataCopy = {
        ...tableData[section],
        hidden: !tableData[section].hidden
      };
      tableDataCopy[section] = sectionDataCopy;
      setTableData(tableDataCopy);
    }
  };

  return (
    <LegalLayout
      heading={<>All for the cookie! </>}
      topImg={cookie}
      topFog={<div className="top-fog top-fog--legal-cp" />}
      tableOfContent={sections.map((section) => section.heading)}
      setContactSectionRef={(el) => (sectionRefs.current[sections.length] = el)}
    >
      <>
        {sections.map((section, index) => (
          <section
            className="legal__doc-section"
            ref={(el) => (sectionRefs.current[index] = el)}
          >
            <h2>
              {index + 1}. {section.heading}
            </h2>
            {section.content}
          </section>
        ))}
      </>
    </LegalLayout>
  );
};

export default CookiePolicy;
