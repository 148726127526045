import React, { useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import LearnCard from "./LearnCard";
import fogImg from "assets/images/fog-hero.png";
import doctorImg from "assets/images/gallery-7.png";
import WrappedIcon from "components/WrappedIcon";
import Icon from "components/Icon";
import Tag from "components/Tag";
import PostSummary from "./PostSummary";
import Fade from "react-reveal/Fade";
import { postList, tagMapping } from "../content";

const LearnDetailsContent = () => {
  const { params } = useRouteMatch();

  useEffect(() => {}, []);
  const articleId = params.articleId;
  const post = postList.filter((post) => post.id === Number(articleId))[0];
  const relatedArticles = postList.filter((post) =>
    post.relatedArticles.includes(Number(articleId))
  );

  if (!post)
    return (
      <h1 style={{ textAlign: "center", marginTop: "4pc" }}>
        {" "}
        No post found.{" "}
      </h1>
    );

  return (
    <div className="lrndp__content max-page-width">
      <img src={fogImg} className="lrndp__fog" alt="" />
      <nav className="lrndp__nav">
        <Link to="/learn" className="lrndp__nav-link lrndp__nav-link--back">
          <Icon id="arrow-left" className="lrndp__back-icon" /> Back
        </Link>
        <div className="lrndp__nav-sticky">
          <a
            href="#!"
            aria-label="Facebook"
            className="lrndp__nav-link"
            href="https://facebook.com/idderalife"
            target="_blank"
          >
            <WrappedIcon id="facebook" />
          </a>
          <a
            href="#!"
            aria-label="Twitter"
            className="lrndp__nav-link"
            href="https://twitter.com/idderalife"
            target="_blank"
          >
            <WrappedIcon id="twitter" />
          </a>
          <a
            href="#!"
            aria-label="Instagram"
            className="lrndp__nav-link"
            href="https://instagram.com/idderalife"
            target="_blank"
          >
            <WrappedIcon id="instagram" />
          </a>
        </div>
      </nav>
      <section className="lrndp__post">
        <Link
          to="/learn"
          className="lrndp__nav-link lrndp__nav-link--back lrndp__nav-link--back-mobile"
        >
          <Icon id="arrow-left" className="lrndp__back-icon" /> Back
        </Link>
        <Fade bottom>
          <Tag> {tagMapping[post.tag]}</Tag>
          <h1 className="lrndp__post-heading lrndp__main-heading">
            {post.title}
          </h1>
          <div className="lrndp__nav-sticky lrndp__nav-sticky--mobile">
            <a href="#!" aria-label="Facebook" className="lrndp__nav-link">
              <WrappedIcon id="facebook" />
            </a>
            <a href="#!" aria-label="Twitter" className="lrndp__nav-link">
              <WrappedIcon id="twitter" />
            </a>
            <a href="#!" aria-label="Instagram" className="lrndp__nav-link">
              <WrappedIcon id="instagram" />
            </a>
          </div>
          <div className="lrndp__post-img-wrapper">
            <img
              src={doctorImg}
              alt="Lorep ipsum dolor sit amet consec adipscing elit"
              className="lrndp__post-img lrndp__post-img--main"
            />
          </div>
          <PostSummary
            image={post.author.avatar}
            name={post.author.name}
            date={post?.postDate.format("MMMM DD, YYYY")}
            duration={post.readTime + " read"}
          />
        </Fade>
        <article className="lrndp__post-body">{post.content}</article>
        <div className="lrndp__post-footer">
          <p className="lrndp__post-cclose"> With ❤️ from </p>
          <PostSummary image={post.author.avatar} name={post.author.name} />
        </div>
      </section>
      <div className="lrndp__more">
        <Fade bottom>
          <h2 className="lrndp__more-heading"> More like this</h2>

          <div className="lrnp__section-cards">
            {relatedArticles.map((post, index) => (
              <LearnCard card={post} key={index} />
            ))}
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default LearnDetailsContent;
