import postList from "./posts";

const postGroups = {};

postList.forEach((post) => {
  postGroups[post.tag] = postGroups[post.tag]
    ? [...postGroups[post.tag], post]
    : [post];
});

const tagMapping = {
  general: "General Care",
  product: "Product Updates",
  tips: "Tips",
  diabetes: "Diabetes",
  heart: "Hypertension and Heart Disease",
  stress: "Stress and Anxiety Management",
  weight: "Weight management"
};

export { postGroups, postList, tagMapping };
