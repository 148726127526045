import React from "react";
import hero from "assets/images/hero-lp.png";
import Fade from "react-reveal/Fade";
import Button from "components/Button";

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero__content max-page-width">
        <div className="hero__left">
          <Fade bottom>
            <h1 className="hero__heading">
              Your personal physician because you only live once
            </h1>
            <p className="hero__subheading">
              Iddera is a full-service{" "}
              <span className="txt-highlight">digital-first </span>
              primary care clinic designed around your life.
            </p>
            <form className="hero__form">
              <label htmlFor="get-started" className="hero__input-label">
                Early access
              </label>
              <div className="hero__inputs-wrapper">
                <input
                  id="get-started"
                  className="hero__input"
                  placeholder="johndoe@iddera.com"
                />
                <Button
                  // label="Sign Up"
                  label="Submit"
                  className="hero__btn"
                  onClick={(e) => e.preventDefault()}
                  variant="cosmos"
                />
              </div>
            </form>
          </Fade>
        </div>
        <div className="hero__right">
          <img
            src={hero}
            className="hero__img"
            alt="Lady checking heart rate and setting weekly health plan."
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
