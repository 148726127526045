import Tag from "components/Tag";
import React from "react";
import Fade from "react-reveal/Fade";
import bubble from "assets/images/bubble-3.png";
import PhilosophyCard from "./PhilosophyCard";
import philosophyImg1 from "assets/images/philosophy-1.png";
import philosophyImg2 from "assets/images/philosophy-2.png";
import philosophyImg3 from "assets/images/philosophy-3.png";
import philosophyImg4 from "assets/images/philosophy-4.png";
import philosophyImg5 from "assets/images/philosophy-5.png";

const philosophies = [
  {
    image: philosophyImg1,
    title: "Do amazing work",
    description: (
      <>
        Commit to excellence in your work and demand excellence from others.
        Always raise the standard and seek continuous improvement. Your{" "}
        <span
          className="cursor-pointer"
          title="any beneficiary of your work is a customer: members, colleagues, partners, etc."
        >
          customers*{" "}
        </span>
        ultimately hold the verdict on the quality of your work.{" "}
      </>
    )
  },
  {
    image: philosophyImg2,
    title: "Bend the curve",
    description:
      "We fundamentally believe that resourcefulness trumps resources. Constraints and the seemingly impossible will present your biggest opportunities to create value."
  },
  {
    image: philosophyImg3,
    title: "Always be clear",
    description:
      "Great communication is a superpower. Always listen to understand and be radically open-minded to diversity of thought. Take ownership for passing your ideas on, simply!"
  },
  {
    image: philosophyImg4,
    title: "Keep your integrity",
    description:
      "Promptness with keeping commitments increase your odds of doing things that will change the world. Be bold and seek help when you come up short. Share your thoughts and keep a clear mind."
  },
  {
    image: philosophyImg5,
    title: "1 for 99",
    description: `Focus on the most important thing and go big on it. Always find the exponential factor to what you do. Think big; Create for one and scale for many. Leave no one behind.`
  }
];
const Philosophy = () => {
  return (
    <div className="ap__psy flx-center">
      <div className="ap__psy-content max-page-width">
        <Fade bottom>
          <div className="ap__psy-top">
            <Tag> Values </Tag>
            <h3 className="ap__heading ap__psy-heading">
              Our guiding philosophy
            </h3>
            <p className="ap__psy-desc">
              <img src={bubble} className="ap__psy-bubble" alt="" />
              We are on a mission that commits and challenges us to enhance
              human potential by improving the wellbeing of people everywhere.
            </p>
          </div>
          <ul className="ap__psy-list">
            {philosophies.map((psy, index) => (
              <PhilosophyCard key={index} philosophy={psy} />
            ))}
          </ul>
        </Fade>
      </div>
    </div>
  );
};

export default Philosophy;
