import React from "react";
import { Header, JoinIddera, Footer } from "../../Common";
import Subscribe from "./Subscribe";

const LearnLayout = ({ children, isDetailsPage }) => {
  return (
    <div className="webp lrnp">
      {!isDetailsPage && <div className="top-fog top-fog--lrnp" />}
      <Header />
      {children}
      <Subscribe />
      <JoinIddera />
      <Footer />
    </div>
  );
};

export default LearnLayout;
