import Icon from "components/Icon";
import WrappedIcon from "components/WrappedIcon";
import React from "react";
import Modal from "react-modal";

const DoctorProfile = ({ isOpen, closeModal, doctor }) => {
  const { avatar, name } = doctor || {};
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Menu"
      overlayClassName={`ap__dp-overlay`}
      className="ap__dp-content"
      appElement={document.querySelector("#root")}
    >
      <button
        className="ap__dp-close-btn"
        aria-label={`Close profile of ${doctor?.name}`}
        onClick={closeModal}
      >
        <WrappedIcon id="close" width={35} height={35} />
      </button>
      <section className="ap__dp-top">
        <div className="ap__dp-img-wrapper">
          <img className="ap__dp-img" src={avatar} alt={name} />
        </div>
        <div className="ap__dp-top-right">
          <h1 className="ap__heading ap__dp-name"> {doctor?.name} </h1>
          <p className="ap__dp-location">{doctor?.location}</p>
        </div>
      </section>
      <ul className="ap__dp-props">
        <li className="ap__dp-prop">
          <Icon id="grad-cap" /> {doctor?.school}
        </li>
        <li className="ap__dp-prop">
          <Icon id="language" />
          Speaks {doctor?.languages.join(", ")}
        </li>
      </ul>
      <section className="ap__dp-about">
        <h2 className="ap__dp-section-heading">
          {" "}
          ABOUT {doctor?.name.split(" ")[0]}{" "}
        </h2>
        <p className="ap__dp-section-para">{doctor?.about}</p>
      </section>
      <section className="ap__dp-hobbies">
        <h2 className="ap__dp-section-heading"> HOBBIES</h2>
        <p className="ap__dp-section-para"> {doctor?.hobbies.join(", ")}</p>
      </section>
    </Modal>
  );
};

export default DoctorProfile;
