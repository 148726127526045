import React, { useEffect, useState } from "react";
import avatar1 from "assets/images/review-avatar-1.png";
import avatar2 from "assets/images/review-avatar-2.png";
import avatar3 from "assets/images/review-avatar-3.png";
import avatar4 from "assets/images/review-avatar-4.png";
import quotes from "assets/icons/citation-open.svg";
import AvatarProgress from "components/AvatarProgress";
import { useInView } from "react-intersection-observer";

import { Carousel } from "react-responsive-carousel";

const REVIEW_DURATION = 9000;
let timeout;
const reviews = [
  {
    author: "Karen",
    avatar: avatar1,
    message: `"You can't buy peace of mind but you can join Iddera. There's so much less to worry about when you can get advise before you need it and answers when you need them."`
  },
  {
    author: "Brian",
    avatar: avatar2,
    message: `"Iddera has completely changed my relationship with my health. I am definitely a lot more productive."`
  },
  {
    author: "Brian",
    avatar: avatar3,
    message: `"I joined iddera because I wanted a doctor. Now I have the full complement of a hospital on-demand and all in one place; on my phone."`
  },
  {
    author: "John",
    avatar: avatar4,
    message: `"Having a personal physician makes all the difference. I can't even compare information on Google about my health with a medical degree."`
  }
];

const Reviews = () => {
  const [active, setActive] = useState(null);
  const { ref, entry } = useInView({
    root: null,
    rootMargin: "0px",
    threshold: 0.3
  });

  useEffect(() => {
    if (entry?.isIntersecting) {
      if (active === null) setActive(0);
    }
  }, [entry]);

  useEffect(() => {
    if (active !== null && active < reviews.length) {
      const nextActive = active < reviews.length - 1 ? active + 1 : 0;
      clearTimeout(timeout);
      timeout = setTimeout(() => setActive(nextActive), REVIEW_DURATION);
    }
  }, [active]);

  return (
    <div className="reviews" ref={ref}>
      <div className="reviews__top max-page-width">
        <img src={quotes} alt="" className="reviews__quotes" />
        <div className="reviews__avrs">
          {reviews.map((review, index) => (
            <AvatarProgress
              key={index}
              active={active === index}
              avatar={review?.avatar}
              onClick={() => setActive(index)}
              alt={review?.author}
              progressDuration={REVIEW_DURATION / 1000}
            />
          ))}
        </div>
      </div>

      <Carousel
        selectedItem={active}
        autoPlay={false}
        showIndicators={false}
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        emulateTouch={true}
        onChange={(index) => setActive(index)}
        onClickItem={(index) => setActive(index)}
      >
        {reviews.map((review, index) => (
          <div className="review__body" key={index}>
            <p className="reviews__msg">{review.message}</p>
            <p className="reviews__author">{review.author}</p>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Reviews;
