import React, { useState } from "react";
import doctors from "assets/images/doctors-1.png";
import Tag from "components/Tag";
import ListItemCard from "components/ListItemCard";
import bubble from "assets/images/bubble-1.png";
import Fade from "react-reveal/Fade";

const groupHeadings = [
  <>
    Everyday <br /> stuff
  </>,
  <>
    Prolonged <br /> conditions
  </>,
  <>
    Preventive <br /> health{" "}
  </>
];
const groups = [
  {
    title: "Everyday stuff",
    items: [
      "Allergies",
      "Cold & Flu",
      "Digestive Health",
      "Fatigue & Stress Management",
      "Fevers, Chills & Headaches",
      "Common Skin Conditions",
      "General Body Pain",
      "Stomach Aches",
      "Nausea, Vomiting, Diarrhea",
      "Sexual Health"
    ]
  },
  {
    title: "Prolonged conditions",
    items: [
      "Asthma",
      "Arthritis & Bone Health",
      "Autoimmune Conditions",
      "Back Pain",
      "Diabetes & Pre-Diabetes",
      "Depression & Anxiety",
      "Heart Disease",
      "High Blood Pressure",
      "High Cholesterol",
      "Thyroid Dysfunction"
    ]
  },
  {
    title: "Preventive health",
    items: [
      "Age-Related & Inherited Risks",
      "Annual Health Risk Assessment",
      "Cancer Screening",
      "Mental Health & Wellness",
      "Nutrition & Vitality",
      "Substance Counselling",
      "Women’s & Men’s Health",
      "Stomach Aches",
      "Weight loss",
      "Vaccinations"
    ]
  }
];

const Cares = () => {
  const [activeGroup, setActiveGroup] = useState(0);

  return (
    <div className="care max-page-width">
      <h2 className="care__heading">
        How we <br /> care{" "}
      </h2>
      <Fade bottom>
        <div className="care__top">
          <div className="care__top-img-wrapper">
            <img src={doctors} alt="Doctors" className="care__top-img" />
            <img src={bubble} className="care__bubble" alt="" />
          </div>
          <div className="care__top-texts">
            <h3 className="care__top-heading">
              We are here for you through sickness and in good health
            </h3>
            <p className="care__top-desc">
              Iddera brings advanced diagnostics into primary care personalised
              to you. We know giving you more information and tools gives you
              more control of your health outcomes.
            </p>
          </div>
        </div>
      </Fade>
      <div className="care__groups care__groups--desktop">
        <div className="care__progress-cont">
          {groupHeadings.map((_, index) => (
            <span
              key={index}
              className={`care__progress-circle cursor-pointer ${
                index === activeGroup ? "care__progress-circle--active" : ""
              }`}
              onClick={() => setActiveGroup(index)}
            />
          ))}
        </div>
        <div className="care__group-headings">
          {groupHeadings.map((heading, index) => (
            <h3
              key={index}
              className={`care__heading care__group-heading cursor-pointer ${
                index === activeGroup ? "care__group-heading--active" : ""
              }`}
              onClick={() => setActiveGroup(index)}
            >
              {heading}
            </h3>
          ))}
        </div>
        {groups.map((group, index) => (
          <div
            className={`care__group-content ${
              index === activeGroup ? "care__group-content--active" : ""
            }`}
          >
            <h3
              className={`care__heading care__group-heading--mobile  care__group-heading--active`}
            >
              {group.title}
            </h3>
            <Tag className="care__group-tag"> {group.title} </Tag>
            <ul className="care__group-cards">
              {group.items.map((item, index) => (
                <ListItemCard text={item} key={index} />
              ))}
              <div className="lic lic__text lic--last"> & More</div>
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cares;
