import Icon from "components/Icon";
import React from "react";
import WrappedIcon from "components/WrappedIcon";
import MostPopular from "./MostPopular";

const freeDetails = {
  icon: "heart",
  name: "Iddera Free Plan",
  amount: "3 months",
  duration: "",
  priceLabel: "Free trial",
  highlight: " No initiation fee!",
  features: [
    "Start with a free trial plan, at no cost.",
    "Pay-as-you-go for premium services."
  ],
  buttonLabel: "Try for free"
};

const premiumDetails = {
  icon: "crown",
  name: "Iddera Premium Plan",
  amount: "₦16,500",
  duration: "per month",
  priceLabel: "BILLED ANNUALLY",
  highlight: "Pay for a year and get one month free🎉!",
  features: [
    "No initiation fee, just ₦16,500 per month.",
    "Cancel anytime before your next renewal date."
  ],
  buttonLabel: "Get Started"
};

const Plan = ({ plan, isRecommended }) => {
  const isPremium = plan === "premium";
  const planData = isPremium ? premiumDetails : freeDetails;

  return (
    <div
      className={`prp__plan-card ${
        isPremium ? "prp__plan-card--pr" : "prp__plan-card--fr"
      } `}
    >
      {isRecommended && <MostPopular />}
      <div className="prp__plan-heading">
        <WrappedIcon id={planData.icon} className="prp__plan-icon-wrapper" />
        <h2 className="prp__plan-name">{planData.name}</h2>
      </div>
      <span className="prp__plan-price">
        <span className="prp__plan-amount"> {planData.amount} </span>
        <span className="prp__plan-duration"> {planData.duration} </span>
      </span>
      <span className="prp__plan-price-lbl"> {planData.priceLabel} </span>
      <ul className="prp__plan-feats">
        {planData.features.map((feature, index) => (
          <li key={index} className="prp__plan-feat">
            <span className="prp__bullet">
              <Icon id="bullet-ring" />
            </span>
            <span className="prp__plan-feat-txt"> {feature} </span>
          </li>
        ))}
      </ul>
      <button className="prp__plan-btn"> {planData.buttonLabel}</button>
    </div>
  );
};

export default Plan;
