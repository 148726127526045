import React from "react";
import "./styles.css";

const Tag = ({ children, className, ...props }) => {
  return (
    <p className={`tag ${className || ""}`} {...props}>
      {children}
    </p>
  );
};

export default Tag;
